import React, {useContext, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {UserContext} from "../../contexts";

import {Navbar} from "../../components";
import Styles from "./Styles";
////import EditCollection from "../admin/collections/editCollection/EditCollection";
import CollectionsHome from "../admin/collections/CollectionsHome";
import {getUserById} from "../../services";
export default function Creator() {
  const userCtx = useContext(UserContext);
  const [authCol, setAuthCol] = useState([]);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    const user = await getUserById(userCtx.user._id);
    const tpCol = [];
    let co = user.collections.accessories;
    if (co.id !== "" && co.isUnlocked) tpCol.push(co.id);
    co = user.collections.menswear;
    if (co.id !== "" && co.isUnlocked) tpCol.push(co.id);
    co = user.collections.womenswear;
    if (co.id !== "" && co.isUnlocked) tpCol.push(co.id);
    setAuthCol(tpCol);
  }
  return (
    <div className={Styles.frame}>
      <Navbar />
      <div className={Styles.margin}></div>
      <div className={Styles.innerFrame}>
        <CollectionsHome creator={userCtx.user._id} />
        Creator
      </div>
    </div>
  );
}
