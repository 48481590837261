import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {UserContext} from "../../contexts";
import {bck_coll} from "../../assets/images";

import {Navbar} from "../../components";
import Styles from "./Styles";
import {getCollectionBckImage} from "../../services";
import {getPage} from "../../services/api/pages";
//import {lowerFirst} from "lodash";
export default function Home() {
  const [bckImage, setBckImage] = useState("");

  useEffect(() => {
    init();
  }, []);

  async function init() {
    const file = await getCollectionBckImage();
    const im = URL.createObjectURL(file);
    setBckImage(im);
  }
  return (
    <div
      className={Styles.frame}
      style={{backgroundImage: "url(" + bckImage + ")"}}
    >
      <Navbar />
      <div className={Styles.margin}></div>
      <div className={Styles.innerFrame}>
        <div className={Styles.headerContainer}>
          <div className={Styles.header}>COLLECTIONS</div>
          {/* <div className={Styles.header}>WINTER 2022</div> */}
        </div>
        <Link to="/Home/Seasons/menswear" className={Styles.btn}>
          MENS
        </Link>

        <Link to="/Home/Seasons/womenswear" className={Styles.btn}>
          WOMENS
        </Link>
        <Link to="/Home/Seasons/accessories" className={Styles.btn}>
          ACCESSORIES
        </Link>
      </div>
    </div>
  );
}
