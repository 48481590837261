import httpService from "../httpService";

const apiUrl = "pages";

export async function getPage() {
  try {
    const res = await httpService.get(apiUrl + "/");
    return res.data;
  } catch (error) {}
}

export async function savePage(data) {
  try {
    const res = await httpService.post(apiUrl + "/", data);
    return res.data;
  } catch (error) {}
}
