import React, {useContext} from "react";
import {Routes, Route, Navigate, Link} from "react-router-dom";
import {authAdmin, authAdminOrCreator} from "../../auth";
import {AdminHome} from ".";
import {Users} from "./users";
import {ModifyUser} from "./users/modifyUser";
import {Collections, CollectionsHome} from "./collections";
import {UserContext} from "../../contexts";
import Pages from "./pages/Pages";

export default function Admin() {
  const userCtx = useContext(UserContext);

  return (
    <Routes>
      <Route
        path="/"
        element={
          authAdminOrCreator(userCtx.user) ? (
            <AdminHome />
          ) : (
            <Navigate to="/Welcome" />
          )
        }
      ></Route>
      <Route
        path="/Users/Modify/:id"
        element={
          authAdmin(userCtx.user) ? <ModifyUser /> : <Navigate to="/Welcome" />
        }
      ></Route>
      <Route
        path="/Users"
        element={
          authAdmin(userCtx.user) ? <Users /> : <Navigate to="/Welcome" />
        }
      ></Route>

      <Route
        path="/Pages"
        element={
          authAdmin(userCtx.user) ? <Pages /> : <Navigate to="/Welcome" />
        }
      ></Route>
      <Route
        path="/Collections/*"
        element={
          authAdminOrCreator(userCtx.user) ? (
            <Collections />
          ) : (
            <Navigate to="/Welcome" />
          )
        }
      ></Route>
    </Routes>
  );
}
