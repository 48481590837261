import React, {useState, useEffect} from "react";
import Joi from "joi/dist/joi-browser.min.js";

import cloneDeep from "lodash/cloneDeep";

import Styles from "./Styles";

import {Icon, LoaderWin} from "../../../../../../components";
import {Palette} from "../../../../../../constants";

import {ImageBlockProduct} from "../../../../../../components";
import {findProductsById, getProductById} from "../../../../../../services";
import {useDispatch, useSelector} from "react-redux";
import {prodImageDeleted} from "../../../../../../store/productsImages";
import {checkIfProductExists} from "../../../../../../utils/collectionsUtils";

export default function EditProduct(props) {
  const [coverflow, setCoverflow] = useState(true);
  const dispatch = useDispatch();

  const [product, setProduct] = useState([]);
  const [modifiedProduct, setmodifiedProduct] = useState(null);
  const [images, setImages] = useState([]);
  //const [imageFiles, setImageFiles] = useState(["nc", "nc", "nc", "nc"]);
  const [imFile0, setImFile0] = useState("nc");
  const [imFile1, setImFile1] = useState("nc");
  const [imFile2, setImFile2] = useState("nc");
  const [imFile3, setImFile3] = useState("nc");

  const [prices, setPrices] = useState([]);

  const [modified, setModified] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [imagesHasChange, setImagesHasChange] = useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    init();
  }, [props.productId]);
  async function init() {
    const id = props.productId;
    const prod = await getProductById(id, false);
    setImages(prod.images);
    setProduct(prod);
    setmodifiedProduct(prod);
    setPrices({
      priceEUR: prod.prices.priceEUR ? prod.prices.priceEUR : 0,
      priceUSD: prod.prices.priceUSD ? prod.prices.priceUSD : 0,
      priceJPY: prod.prices.priceJPY ? prod.prices.priceJPY : 0,
      priceGPB: prod.prices.priceGPB ? prod.prices.priceGPB : 0,
      priceCNY: prod.prices.priceCNY ? prod.prices.priceCNY : 0,
      _id: prod.prices._id,
    });
    initValidate(prod);
  }

  useEffect(() => {
    setCoverflow(props.coverflow);
  }, [props.coverflow]);

  function handleChangeImageFiles(file, index) {
    switch (index) {
      case 0:
        setImFile0(file);
        break;
      case 1:
        setImFile1(file);
        break;
      case 2:
        setImFile2(file);
        break;
      case 3:
        setImFile3(file);
        break;

      default:
        break;
    }
  }
  async function handleInputChangeName() {
    const newName = window.prompt(
      "Change product category to:",
      modifiedProduct.name
    );
    if (newName == null || newName === "" || newName === modifiedProduct.name)
      return;
    const formerProd = cloneDeep(modifiedProduct);

    const temp = cloneDeep(modifiedProduct);
    temp.name = newName;
    //check allready exists
    const exists = await checkIfProductExists(props.collection, temp);
    if (exists) {
      window.alert("Product already exists");
      return;
    }
    const errors = validate(temp);

    setErrors(errors);
    setmodifiedProduct(temp);
    props.onAction(temp, "PRODUCT-SAVE");

    setProduct(temp);
    props.onAction(
      {
        prod: temp,
        origin: props.stageType,
        target: props.stageType,
        formerProd,
      },
      "PRODUCT-MOVE"
    );
  }
  async function handleInputChangeCategory() {
    const newCat = window.prompt(
      "Change product category to:",
      modifiedProduct.category
    );
    if (newCat == null || newCat === "" || newCat === modifiedProduct.category)
      return;

    const temp = {...modifiedProduct};
    temp.category = newCat;
    //check allready exists
    const exists = await checkIfProductExists(props.collection, temp);
    if (exists) {
      window.alert("Product already exists");
      return;
    }
    const formerProd = cloneDeep(modifiedProduct);
    const errors = validate(temp);
    setErrors(errors);
    setmodifiedProduct(temp);
    props.onAction(temp, "PRODUCT-SAVE");

    setProduct(temp);

    props.onAction(
      {
        prod: temp,
        origin: props.stageType,
        target: props.stageType,
        formerProd,
      },
      "PRODUCT-MOVE"
    );
  }
  async function handleInputChangeColor() {
    const newColor = window.prompt(
      "Change product color to:",
      modifiedProduct.color
    );
    if (
      newColor == null ||
      newColor === "" ||
      newColor === modifiedProduct.color
    )
      return;
    const temp = {...modifiedProduct};
    temp.color = newColor;

    //check allready exists
    const exists = await checkIfProductExists(props.collection, temp);
    if (exists) {
      window.alert("Product already exists");
      return;
    }
    const errors = validate(temp);
    setErrors(errors);
    setmodifiedProduct(temp);
    props.onAction(temp, "PRODUCT-SAVE");
    setProduct(temp);
  }
  async function handleSaveProduct() {
    const temp = cloneDeep(modifiedProduct);
    props.onAction(temp, "PRODUCT-SAVE");
    setProduct(temp);
    // const errors = validate(modifiedProduct);
    // setErrors(errors);
    changeStatus(false, false, true);
  }

  function handleInputChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    const temp = cloneDeep(modifiedProduct);

    temp[name] = e.target.value;
    const errors = validate(temp);
    setErrors(errors);
    setmodifiedProduct(temp);
  }
  function handleInputPriceChange(e) {
    const name = e.target.name;
    const val = Number(e.target.value);
    const temp = {...modifiedProduct};
    const tpPrice = {...prices};
    tpPrice[name] = val;
    setPrices(tpPrice);
    temp.prices = {...tpPrice};
    const errors = validate(temp);

    setErrors(errors);
    setmodifiedProduct(temp);
    // props.onAction(temp, "PRODUCT-SAVE");
  }
  function handleImageChange(imageNb, file) {
    const temp = {...modifiedProduct};

    //splie if file === null
    if (file === null) {
      temp.images.splice(imageNb, 1);
    } else {
      temp.images[imageNb] = file;
    }
    setImagesHasChange(true);
    const errors = validate(temp, true);
    setErrors(errors);
    setmodifiedProduct(temp);
  }
  function handleDeleteImageFile(index) {
    const tempProd = cloneDeep(modifiedProduct);
    tempProd.images[index] = null;
    setmodifiedProduct(tempProd);
    validate(tempProd, true);
  }
  function handleReset() {
    const imIds = [];
    for (let i = 0; i < modifiedProduct.images.length; i++) {
      if (typeof modifiedProduct.images[i] === "string") {
        imIds.push(modifiedProduct.images[i]);
      } else if (modifiedProduct.images[i]) {
        imIds.push(modifiedProduct.images[i].name);
      }
    }
    dispatch(
      prodImageDeleted({
        ids: imIds,
        imFile: null,
      })
    );
    init();
  }

  function handleSendPending(params) {
    props.onAction(
      {
        prod: modifiedProduct,
        origin: "invalidProducts",
        target: "validProducts",
        images: [imFile0, imFile1, imFile2, imFile3],
      },
      "PRODUCT-MOVE"
    );
  }
  function handleDelete() {
    const accept = window.confirm("delete product ?");
    if (accept) {
      props.onAction(
        {modifiedProduct, origin: props.stageType},
        "PRODUCT-DELETE"
      );
    }
  }

  const schema = Joi.object({
    _id: Joi.string(),
    __v: Joi.number(),

    line: Joi.string().required(),
    collect: Joi.string().required(),
    category: Joi.string().required(),
    model: Joi.string().required(),
    name: Joi.string().required(),
    color: Joi.string().required(),
    reference: Joi.string().required(),
    description: Joi.string().required(),
    prices: Joi.object().keys({
      priceEUR: Joi.number().allow(null, ""),
      priceUSD: Joi.number().allow(null, ""),
      priceJPY: Joi.number().allow(null, ""),
      priceGPB: Joi.number().allow(null, ""),
      priceCNY: Joi.number().allow(null, ""),
      _id: Joi.string(),
    }),
    images: Joi.any(),
  }).options({abortEarly: false});

  function validate(prod, imChange = false) {
    //product change ?
    const changed = JSON.stringify(prod) === JSON.stringify(product);
    //changeStatus(modif, save, send)
    if (!changed) {
      changeStatus(true, true, false);
    } else {
      changeStatus(false, false, true);
    }
    //   //imageChanged ?

    if (imagesHasChange || imChange) {
      changeStatus(true, true, false);
    }
    //   //first image null ?
    if (prod.images[0] == null) {
      setCanSave(false);
    }
    //   //Joi validation
    const res = schema.validate(prod);
    if (!res.error) return null;
    const errors = {};
    for (let item of res.error.details) errors[item.path[0]] = item.message;
    setCanSave(false);
    return errors;
  }

  function changeStatus(modif, save, send) {
    setModified(modif);
    setCanSave(save);
    setCanSend(send);
    props.onStatusChange({send, id: props.productId});
  }

  function handleCoverflow() {
    setCoverflow(!coverflow);
  }

  function initValidate(prod) {
    console.log("initValidate--------");
    changeStatus(false, false, false);
    const res = schema.validate(prod);
    console.log("res", res);
    const im = prod.images[0];
    if (!res.error && im != null) {
      console.log("initValidate", res.error, im);
      changeStatus(false, false, true);
    }
  }
  return (
    <div>
      {modifiedProduct && (
        <div
          className={
            //props.topCoverflow ? Styles.productFrame : Styles.productFrameOff
            Styles.productFrame
          }
        >
          <div className={Styles.productTitle}>
            <div className={Styles.productTitleText}>
              {product.name + " - " + product.color}
            </div>
            <div className={Styles.actionContainer}>
              {props.stageType === "invalidProducts" && (
                <div
                  className={canSend ? Styles.btnOnlineOn : Styles.btnOnlineOff}
                  onClick={handleSendPending}
                >
                  send online
                </div>
              )}
              <div
                className={canSave ? Styles.btnLineOn : Styles.btnLineOff}
                onClick={handleSaveProduct}
              >
                save
              </div>

              <div
                className={modified ? Styles.btnLineOn : Styles.btnLineOff}
                onClick={handleReset}
              >
                reset
              </div>
              <div className={Styles.btnLineOn} onClick={handleDelete}>
                delete
              </div>

              <Icon
                icon={coverflow ? "eye_alt" : "eye"}
                size="1rem"
                color={Palette.col_white}
                hcolor={Palette.col_darkGrey}
                onClick={handleCoverflow}
                ml="0.2rem"
              ></Icon>
            </div>
          </div>

          <div
            className={
              coverflow ? Styles.productContainer : Styles.productContainerOff
            }
          >
            <div className={Styles.dataContainer}>
              <div className={Styles.leftContainer}>
                <div className={Styles.inputContainer}>
                  <div className={Styles.inputTitle}>Name</div>
                  <div className={Styles.editWrapper}>
                    <div className={Styles.input}>{modifiedProduct.name}</div>
                    <Icon
                      icon="modify"
                      size="1rem"
                      color={Palette.col_darkGrey}
                      hcolor={Palette.col_orange}
                      onClick={handleInputChangeName}
                      ml="1rem"
                    ></Icon>
                  </div>
                  <div className={Styles.inputError}>
                    {errors && errors.name}
                  </div>
                </div>
                <div className={Styles.inputContainer}>
                  <div className={Styles.inputTitle}>Category</div>
                  <div className={Styles.editWrapper}>
                    <div
                      className={Styles.input}
                      onChange={handleInputChangeCategory}
                    >
                      {modifiedProduct.category}
                    </div>
                    <Icon
                      icon="modify"
                      size="1rem"
                      color={Palette.col_darkGrey}
                      hcolor={Palette.col_orange}
                      onClick={handleInputChangeCategory}
                      ml="1rem"
                    ></Icon>
                  </div>

                  <div className={Styles.inputError}>
                    {errors && errors.category}
                  </div>
                </div>
                <div className={Styles.inputContainer}>
                  <div className={Styles.inputTitle}>Color</div>
                  <div className={Styles.editWrapper}>
                    <div className={Styles.input}>{modifiedProduct.color}</div>
                    <Icon
                      icon="modify"
                      size="1rem"
                      color={Palette.col_darkGrey}
                      hcolor={Palette.col_orange}
                      onClick={handleInputChangeColor}
                      ml="1rem"
                    ></Icon>
                  </div>
                  <div className={Styles.inputError}>
                    {errors && errors.color}
                  </div>
                </div>
                <div className={Styles.inputContainer}>
                  <div className={Styles.inputTitle}>Prices</div>
                  <div className={Styles.pricesInputContainer}>
                    <input
                      className={Styles.input}
                      value={modifiedProduct.prices.priceEUR}
                      name="priceEUR"
                      type="number"
                      onChange={handleInputPriceChange}
                    />
                    <p className={Styles.currency}>EUR</p>
                  </div>
                  <div className={Styles.pricesInputContainer}>
                    <input
                      className={Styles.input}
                      value={modifiedProduct.prices.priceUSD}
                      name="priceUSD"
                      type="number"
                      onChange={handleInputPriceChange}
                    />
                    <p className={Styles.currency}>USD</p>
                  </div>
                  <div className={Styles.pricesInputContainer}>
                    <input
                      className={Styles.input}
                      value={modifiedProduct.prices.priceJPY}
                      name="priceJPY"
                      type="number"
                      onChange={handleInputPriceChange}
                    />
                    <p className={Styles.currency}>JPY</p>
                  </div>
                  <div className={Styles.pricesInputContainer}>
                    <input
                      className={Styles.input}
                      value={modifiedProduct.prices.priceGPB}
                      name="priceGPB"
                      type="number"
                      onChange={handleInputPriceChange}
                    />
                    <p className={Styles.currency}>GPB</p>
                  </div>
                  <div className={Styles.pricesInputContainer}>
                    <input
                      className={Styles.input}
                      value={modifiedProduct.prices.priceCNY}
                      name="priceCNY"
                      type="number"
                      onChange={handleInputPriceChange}
                    />
                    <p className={Styles.currency}>CNY</p>
                  </div>

                  <div className={Styles.inputError}>
                    {errors && errors.prices}
                  </div>
                </div>
                <div className={Styles.inputContainer}>
                  <div className={Styles.inputTitle}>Reference</div>
                  <input
                    className={Styles.input}
                    value={modifiedProduct.reference}
                    name="reference"
                    onChange={handleInputChange}
                  />
                  <div className={Styles.inputError}>
                    {errors && errors.reference}
                  </div>
                </div>

                <div className={Styles.inputContainer}>
                  <div className={Styles.inputTitle}>Model</div>

                  <input
                    className={Styles.input}
                    value={modifiedProduct.model}
                    name="model"
                    onChange={handleInputChange}
                  />
                  <div className={Styles.inputError}>
                    {errors && errors.model}
                  </div>
                </div>
                <div className={Styles.inputContainer}>
                  <div
                    className={Styles.inputTitle}
                    name="description"
                    onChange={handleInputChange}
                  >
                    Description
                  </div>
                  <input
                    className={Styles.inputDescription}
                    value={modifiedProduct.description}
                    name="description"
                    onChange={handleInputChange}
                  />
                  <div className={Styles.inputError}>
                    {errors && errors.description}
                  </div>
                </div>
              </div>
              <div className={Styles.rightContainer}>
                <div className={Styles.imageSecondary}>
                  <div className={Styles.imageSecondarySub}>
                    <div className={Styles.image1}>
                      <ImageBlockProduct
                        image={images}
                        size={"6rem"}
                        imageNb={0}
                        onChangeFile={handleChangeImageFiles}
                        onDeleteImageFile={handleDeleteImageFile}
                        onChange={handleImageChange}
                        onAction={props.onAction}
                        prodId={modifiedProduct && modifiedProduct._id}
                        nocache={true}
                      />
                    </div>
                    <div className={Styles.image2}>
                      <ImageBlockProduct
                        image={images}
                        size={"6rem"}
                        imageNb={2}
                        onChangeFile={handleChangeImageFiles}
                        onDeleteImageFile={handleDeleteImageFile}
                        onChange={handleImageChange}
                        onAction={props.onAction}
                        prodId={modifiedProduct && modifiedProduct._id}
                        nocache={true}
                      />
                    </div>
                  </div>
                  <div className={Styles.imageSecondarySub}>
                    <div className={Styles.image3}>
                      <ImageBlockProduct
                        image={images}
                        size={"6rem"}
                        imageNb={1}
                        onChangeFile={handleChangeImageFiles}
                        onDeleteImageFile={handleDeleteImageFile}
                        onChange={handleImageChange}
                        onAction={props.onAction}
                        prodId={modifiedProduct && modifiedProduct._id}
                        nocache={true}
                      />
                    </div>
                    <div className={Styles.image4}>
                      <ImageBlockProduct
                        image={images}
                        size={"6rem"}
                        imageNb={3}
                        onChangeFile={handleChangeImageFiles}
                        onDeleteImageFile={handleDeleteImageFile}
                        onChange={handleImageChange}
                        onAction={props.onAction}
                        prodId={modifiedProduct && modifiedProduct._id}
                        nocache={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
