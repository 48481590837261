import React, {useEffect, useState} from "react";
import filter from "lodash/filter";

import {getAllCollections} from "../../../../services";
import {CollectionsTable} from "./collectionTable";

import Styles from "./Styles";

export default function CollectionsList(props) {
  const [collections, setCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selection, setSelection] = useState("all");

  useEffect(() => {
    init();
  }, [props.collections]);

  async function init() {
    setCollections(props.collections);
    setSelectedCollections(props.collections);
    setSelection("all");
  }

  function handleSelectionChange(name) {
    if (selection === name) return;
    setSelection(name);
    if (name === "all") {
      setSelectedCollections(collections);
      setSelection("all");
      return;
    }
    const tempColl = [...collections];
    const filtered = filter(tempColl, {line: name});
    setSelectedCollections(filtered);
  }

  function handleModifyCollection(id) {
    props.onActionChange("modify", id);
  }

  return (
    <div className={Styles.tableFrame}>
      <div className={Styles.menuBar}>
        <div className={Styles.selectGroup}>
          <div
            className={
              selection === "all"
                ? Styles.btnGroupSmallSelected
                : Styles.btnGroupSmall
            }
            onClick={() => handleSelectionChange("all")}
          >
            All
          </div>
          <div
            className={
              selection === "menswear"
                ? Styles.btnGroupMediumSelected
                : Styles.btnGroupMedium
            }
            onClick={() => handleSelectionChange("menswear")}
          >
            Menswear
          </div>
          <div
            className={
              selection === "womenswear"
                ? Styles.btnGroupMediumSelected
                : Styles.btnGroupMedium
            }
            onClick={() => handleSelectionChange("womenswear")}
          >
            Womenswear
          </div>
          <div
            className={
              selection === "accessories"
                ? Styles.btnGroupMediumSelected
                : Styles.btnGroupMedium
            }
            onClick={() => handleSelectionChange("accessories")}
          >
            Accessories
          </div>
        </div>
        {!props.creator && (
          <div
            className={Styles.btnCreate}
            onClick={() => props.onActionChange("create")}
          >
            Create
          </div>
        )}
      </div>
      <CollectionsTable
        datas={selectedCollections}
        onModifyCollection={handleModifyCollection}
      />
    </div>
  );
}
