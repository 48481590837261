import {css} from "@emotion/css";
import {Palette} from "../../../../../constants";

const Styles = {
  collectionBlock: css({
    display: "flex",
    alignItems: "center",
  }),
  collectionLabel: css({
    marginLeft: "0.5rem",
    width: "7rem",
  }),
  collectionValue: css({
    color: Palette.col_lightMediumGrey,
  }),
};

export default Styles;
