import {Palette} from "../../constants";

const Styles = {
  button: {
    regular: {
      standard: {
        bkgColor: Palette.col_orange,
        color: Palette.col_white,
        coverColor: "white",
        height: "1.5rem",
        width: "4rem",
      },
      hover: {
        bkgColor: Palette.col_lightOrange,
        color: Palette.col_white,
        coverColor: "white",
        height: "1.4rem",
        width: "4rem",
        border: "solid 1px grey",
      },
      disabled: {
        bkgColor: "white",
        color: "grey",
        coverColor: "white",
        height: "1.5rem",
        width: "4rem",
        border: "solid 1px grey",
      },
    },
    white: {
      standard: {
        bkgColor: "white",
        color: "black",
        coverColor: "grey",
        height: "2rem",
        width: "5rem",
        border: "solid 1px grey",
      },
      hover: {
        bkgColor: "orangered",
        color: "white",
        coverColor: "white",
        height: "2rem",
        width: "5rem",
        border: "none",
      },
      disabled: {
        bkgColor: "white",
        color: "grey",
        coverColor: "white",
        height: "2.5rem",
        width: "5rem",
        border: "solid 1px rgb(199, 199, 199)",
      },
    },
  },
};

export default Styles;
