import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "userFilters",
  initialState: [null],
  reducers: {
    setFilters: (filters, action) => {
      filters[0] = action.payload.filters;
    },
    resetFilters: (filters, action) => {
      filters[0] = null;
    },
  },
});

export const {setFilters, resetFilters} = slice.actions;
export default slice.reducer;

//Selector
export const getUserFilters = (state) => state.userFilters;
