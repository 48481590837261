import {css, keyframes} from "@emotion/css";
import {Palette} from "../../constants";

const animate = keyframes`
from {transform: rotate(0deg)}
to {transform: rotate(360deg)}`;

export function getStyles(data) {
  const Styles = {
    frame: css({
      width: data.size,
      height: data.size,
      backgroundColor: Palette.col_lightMediumGrey,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),

    image: css({
      width: data.size,
      height: data.size,
      objectFit: "contain",
    }),
    error: css({
      position: "absolute",
      color: "red",
      width: data.size,
      textAlign: "center",
      fontSize: "1.5rem",
    }),
    number: css({
      fontSize: "3rem",
      color: "rgba(255,255,255,0.5)",
    }),
    delete: css({
      position: "absolute",
      marginTop: "-5rem",
      marginLeft: "5rem",
      backgroundColor: Palette.col_lightGrey,
      borderRadius: "100%",
      height: "1rem",
      width: "1rem",
      lineHeight: "0.9rem",
      fontSize: "1rem",
      textAlign: "center",
      cursor: "pointer",
      userSelect: "none",
      color: Palette.col_xlightGrey,
      "&:hover": {
        backgroundColor: Palette.col_xlightGrey,
        color: Palette.col_darkGrey,
      },
    }),
  };
  return Styles;
}
