import React, {useEffect, useState} from "react";
import {getProductById} from "../../services";
import {useNavigate} from "react-router-dom";
import Styles from "./Styles";

import {CardImage} from "./cardImage";

export default function Cards(props) {
  const [card, setCard] = useState(null);
  let navigate = useNavigate();
  useEffect(() => {
    init();
  }, []);
  async function init() {
    if (props.product) {
      const prod = await getProductById(props.object.id);
      setCard({
        id: props.object.id,
        image: prod.images[0],
        name: prod.name,
      });
    } else {
      setCard({
        id: props.object.id,
        image: props.object.image,
        name: props.object.name,
      });
    }
  }
  function handleClick() {
    let address = "";
    if (props.product) {
      address =
        "/Home/Seasons" +
        props.route +
        card.id +
        "/" +
        props.variant +
        "/" +
        props.collectionId;
    } else {
      address = "/Home/Seasons" + props.route + card.id;
    }
    navigate(address);
  }
  return (
    <div>
      {card && (
        <div className={Styles.frame} onClick={handleClick}>
          {props.product && <CardImage image={card.image} product={true} />}
          {!props.product && <CardImage image={card.image} />}

          <div className={Styles.text}>{card.name}</div>
        </div>
      )}
    </div>
  );
}
