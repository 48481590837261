import React, {useEffect, useRef} from "react";

import Styles from "./Styles";
import {loader} from "../../assets/images";

export default function LoaderWin(props) {
  const counter = useRef(0);
  useEffect(() => {
    counter.current.innerHTML = props.count + "%";
  }, [props.count]);

  return (
    <div className={Styles.frame}>
      <div>
        <img className={Styles.loader} src={loader} alt="" />
      </div>
      <div ref={counter} className={Styles.message}>
        loading
      </div>
    </div>
  );
}
