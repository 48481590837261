import {Product} from ".";

class Variant {
  constructor() {
    this.name = "";
    this.products = [];
  }
  init(coll) {
    return new Promise(async (resolve, rejected) => {
      this.name = coll.name;
      const productsList = [];
      for (let i = 0; i < coll.products.length; i++) {
        productsList.push(coll.products[i]);
      }
      this.products = productsList;
      resolve(productsList);
    });
  }
}

export default Variant;
