const Palette = {
  col_primary: "rgb(36,33,41)", //dark_grey
  col_secondary: "rgb(132,138,140)", //lightGrey

  col_blackLightOpacity: "rgba(0,0,0,0.4)",
  col_blackOpacity: "rgba(0,0,0,0.9)",
  col_whiteOpacity: "rgba(245,245,245,0.8)",

  col_black: "rgb(0,0,0)",
  col_darkGrey: "rgb(40,40,40)", //242129
  col_mediumGrey: "rgb(80,80,80)",
  col_lightMediumGrey: "rgb(100,100,100)",
  col_lightGrey: "rgb(140,140,140)",
  col_xlightGrey: "rgb(200,200,200)",
  col_xxlightGrey: "rgb(230,230,230)",
  col_whiteGrey: "rgb(240,240,240)",
  col_white: "rgb(247,247,247)", //Turbosquid requirement

  col_orange: "rgb(255,69,0)", //FF4500
  col_lightOrange: "rgb(220,166,127)", //DCA67F

  col_lightBlue: "rgb(51, 153, 255)",
  col_xLightBlue: "rgb(204, 230, 255)",
};

export default Palette;
