import React, {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import isEqual from "lodash/isEqual";
import Joi from "joi/dist/joi-browser.min.js";

import Styles from "./Styles";
import moment from "moment/min/moment.min.js";

import {deepCopy} from "../../../../utils/deepCopy";

import {Icon, Navbar, RegularButton} from "../../../../components";
import {Palette} from "../../../../constants";

import {deleteUser, getUserById} from "../../../../services";
import {
  getAllCollections,
  modifyUser,
  checkId,
  createUser,
} from "../../../../services";

import {CollectionsBlock} from "./collectionsBlock";
import {countriesList} from "../../../../constants/countries";

export default function ModifyUser() {
  const {id} = useParams();
  const emptyUser = {
    __v: 0,
    _id: "0",
    creationDate: Date.now(),
    lastModifiedDate: Date.now(),
    firstName: "",
    lastName: "",
    email: "",
    emailConfirmed: true,
    company: "",
    role: "custo",
    address: "",
    zipcode: "",
    city: "",
    country: "",
    phonenumber: "",
    mobilenumber: "",
    divisionmenswear: false,
    divisionwomenswear: false,
    divisionaccessories: false,
    authorizedCollectionsIDs: [],

    collections: {
      menswear: {name: "", id: "", isUnlocked: true},
      womenswear: {name: "", id: "", isUnlocked: true},
      accessories: {name: "", id: "", isUnlocked: true},
    },

    isUnlocked: true,
    isConfirmed: true,
  };
  const [create, setCreate] = useState(true);
  const [errors, setErrors] = useState({});
  const [modify, setModify] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [inputsModified, setInputsModified] = useState({});

  const [roleSelected, setRoleSelected] = useState(emptyUser["role"]);
  const [roleModified, setRoleModified] = useState("");

  const [refUser, setRefUser] = useState(emptyUser);

  const [modifiedUser, setModifiedUser] = useState(emptyUser);

  const [collections, setCollections] = useState([]);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    if (id !== "0") {
      const user = await getUserById(id);
      setCreate(false);
      setModify(false);
      setRoleSelected(user["role"]);
      setRefUser(deepCopy(user));
      setModifiedUser(user);
    }
    // getUserById;
    const res = await getAllCollections();

    setCollections(res);
  }
  function handleCancel() {
    const tempModUser = JSON.parse(JSON.stringify(refUser));

    //const tempCol = {...refUser.collections}

    setModifiedUser(tempModUser);
    !create && setModify(false);
    setSaveDisabled(true);
    setInputsModified({});
  }
  async function handleDelete() {
    if (
      refUser.collections.accessories.id !== "" ||
      refUser.collections.menswear.id !== "" ||
      refUser.collections.womenswear.id !== ""
    ) {
      window.alert(
        "This user has collections attached - Please delete them before"
      );
      return;
    }

    const text = window.prompt("To delete user enter deleteuser");
    if (text === "deleteuser") {
      await deleteUser({id: refUser._id});
    } else return;
  }

  function handleConfirm() {
    const temp = {...modifiedUser};
    temp.isConfirmed = true;
    setModifiedUser(temp);
    checkUserChange(temp);
  }

  function handleLock() {
    const temp = {...modifiedUser};
    temp.isUnlocked = !modifiedUser.isUnlocked;
    setModifiedUser(temp);
    checkUserChange(temp);
  }
  function onModifyClick() {
    setModify(true);
  }
  async function handleSaveClick(e) {
    e.preventDefault();
    if (create) {
      const res = await checkId(modifiedUser.email);
      if (res) {
        window.alert("email already exists");
        return;
      }
      const result = await createUser(modifiedUser);

      //  props.onSave(modifiedUser);
    } else {
      const res = await modifyUser(modifiedUser);
      //  props.onModify(modifiedUser);
    }

    setRefUser(modifiedUser);
    setModify(false);
    setSaveDisabled(true);
    setInputsModified({});

    //props.onSave(modifiedUser);
  }

  function handleInputChange(e) {
    const index = e.target.name;

    const val = e.target.value;
    const tempUser = {...modifiedUser};
    tempUser[index] = val;
    const tempInputState = {...inputsModified};
    refUser[index] === val
      ? (tempInputState[index] = false)
      : (tempInputState[index] = true);

    setInputsModified(tempInputState);
    setModifiedUser(tempUser);
    checkUserChange(tempUser);
  }
  function handleCheckChange(e) {
    const index = e.target.name;

    const val = Boolean(e.target.value);
    const tempUser = {...modifiedUser};
    tempUser[index] = val;
    const tempInputState = {...inputsModified};
    refUser[index] === val
      ? (tempInputState[index] = false)
      : (tempInputState[index] = true);

    setInputsModified(tempInputState);
    setModifiedUser(tempUser);
    checkUserChange(tempUser);
  }
  function checkUserChange(user) {
    const res = isEqual(refUser, user);
    if (res) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
    validateUser(user);
  }

  const userCollectionSchema = Joi.object().keys({
    _id: Joi.string(),
    name: Joi.string().allow(""),
    id: Joi.string().allow(""),
    isUnlocked: Joi.boolean(),
  });
  const schemaUser = Joi.object({
    _id: Joi.string().min(0).allow("").allow(null),
    __v: Joi.number(),
    password: Joi.string(),
    firstName: Joi.string().min(3).max(20).required(),
    lastName: Joi.string().min(3).max(20).allow(""),
    creationDate: Joi.date(),
    lastModifiedDate: Joi.date(),
    email: Joi.string()
      .min(5)
      .max(255)
      .email({tlds: {allow: false}})
      .required(),
    address: Joi.string().allow(""),
    zipcode: Joi.string().allow(""),
    city: Joi.string().allow(""),
    country: Joi.string().allow(""),
    phonenumber: Joi.string().allow(""),
    mobilenumber: Joi.string().allow(""),
    isConfirmed: Joi.boolean(),
    emailConfirmed: Joi.boolean(),
    company: Joi.string(),

    divisionmenswear: Joi.bool(),
    divisionwomenswear: Joi.bool(),
    divisionaccessories: Joi.bool(),
    authorizedCollectionsIDs: Joi.array().items({
      _id: Joi.string(),
      name: Joi.string(),
      id: Joi.string(),
      lastModifiedDate: Joi.string(),
      isUnlocked: Joi.bool(),
      __v: Joi.number(),
    }),
    collections: Joi.object({
      _id: Joi.string(),
      menswear: userCollectionSchema,
      womenswear: userCollectionSchema,
      accessories: userCollectionSchema,
    }),
    role: Joi.string(),
    isUnlocked: Joi.boolean(),
  }).options({abortEarly: false});

  function validateUser(usr) {
    const result = schemaUser.validate(usr);
    let err = {};
    if (result.error) {
      for (const el of result.error.details) {
        err[el.path] = el.message;
        setSaveDisabled(true);
      }
    }
    setErrors({...err});
  }

  function handleRoleChange(name) {
    const val = name;

    setRoleSelected(val);
    const tempUser = {...modifiedUser};
    tempUser["role"] = {...modifiedUser["role"]};
    tempUser["role"] = val;

    let tempRoleState = roleModified;

    refUser["role"] === val ? (tempRoleState = false) : (tempRoleState = true);

    setRoleModified(tempRoleState);
    setModifiedUser(tempUser);

    checkUserChange(tempUser);
  }

  function getRoleText(text) {
    switch (text) {
      case "admin":
        return "Administrator";
      case "creat":
        return "Creator";
      case "custo":
        return "Customer";
      default:
        return "";
    }
  }

  function findCollectionInObject(type, inname) {
    const test = [...modifiedUser[type]];
    const res = test.find(({name}) => name === inname);

    return res ? true : false;
  }
  function handleCollectionsChange(type, e) {
    const targetName = e.target.name;
    const res = findCollectionInObject(type, targetName);
    const usr = {...modifiedUser};
    const sub = [...modifiedUser[type]];
    if (res) {
      const index = sub.findIndex((obj) => obj.name === targetName);
      sub.splice(index, 1);
      usr[type] = sub;
      setModifiedUser(usr);
    } else {
      const col = collections.find(({name}) => name === targetName);
      if (col) {
        const newCol = {name: col.name, _id: col.id};
        sub.push(newCol);
        usr[type] = sub;

        setModifiedUser(usr);
      }
    }
    checkUserChange(usr);
  }

  function handleCheckAll(type) {
    const usr = {...modifiedUser};
    const sub = [...collections];
    usr[type] = sub;
    setModifiedUser(usr);
    checkUserChange(usr);
  }

  function handleUncheckAll(type) {
    const usr = {...modifiedUser};

    usr[type] = [];
    setModifiedUser(usr);
    checkUserChange(usr);
  }

  function handleCollectionsLock(type) {
    const tempUser = deepCopy(modifiedUser);
    const coll = {...modifiedUser.collections[type]};

    tempUser.collections[type].isUnlocked = !coll.isUnlocked;
    setModifiedUser({...tempUser});
    checkUserChange(tempUser);
  }

  return (
    <div className={Styles.frame}>
      <Navbar />
      <div className={Styles.margin}></div>
      <div className={Styles.navigation}>
        <Link className={Styles.navItem} to="/Home">
          Home
        </Link>
        <Icon
          icon="arrowheadright"
          color={Palette.col_mediumGrey}
          hcolor={Palette.col_mediumGrey}
          size="1.3rem"
        />
        <Link className={Styles.navItem} to="/Admin">
          Admin
        </Link>
        <Icon
          icon="arrowheadright"
          color={Palette.col_mediumGrey}
          hcolor={Palette.col_mediumGrey}
          size="1.3rem"
        />
        <Link className={Styles.navItem} to="/Admin/Users">
          Users
        </Link>
        <Icon
          icon="arrowheadright"
          color={Palette.col_mediumGrey}
          hcolor={Palette.col_mediumGrey}
          size="1.3rem"
        />
        <div className={Styles.navItem}>Modify</div>
      </div>
      {/* <div className={Styles.title}>{"user #" + refUser._id}</div> */}

      <div className={Styles.testChange}>
        {!modifiedUser.isUnlocked && "User is locked"}
      </div>

      <div>
        <form onSubmit={handleSaveClick}>
          <div className={Styles.menu}>
            {!modify && (
              <RegularButton
                white
                name="Modify"
                mt="1rem"
                className={Styles.btnOn}
                onClick={onModifyClick}
              />
            )}

            {modify && (
              <input
                type="submit"
                name="submit"
                className={saveDisabled ? Styles.btnOff : Styles.btnOn}
                disabled={saveDisabled}
              />
            )}
            {modify && (
              <RegularButton
                className={Styles.btnOn}
                onClick={handleCancel}
                name="delete"
                white
                mt="1rem"
              />
            )}
            {!modify && (
              <RegularButton
                name="delete"
                ml="auto"
                white
                mt="1rem"
                className={Styles.btnDeleteOn}
                onClick={handleDelete}
              />
            )}
            {modify && !modifiedUser.isConfirmed && (
              <div className={Styles.btnConfirmOn} onClick={handleConfirm}>
                confirm
              </div>
            )}
            {modify && (
              <div
                className={
                  !modifiedUser.isUnlocked && !modifiedUser.isConfirmed
                    ? Styles.btnLockOn
                    : modifiedUser.isUnlocked
                    ? Styles.btnConfirmOn
                    : Styles.btnDeleteOn
                }
                onClick={handleLock}
              >
                {!modifiedUser.isUnlocked ? "unlock" : "lock"}
              </div>
            )}
          </div>
          {!create && (
            <div>
              <div className={Styles.lineTitle}>Infos</div>
              <div className={Styles.inputLine}>
                <div className={Styles.inputContainer}>
                  <div className={Styles.label}>Creation date</div>
                  <input
                    className={Styles.inputIdle}
                    disabled
                    value={moment(modifiedUser["creationDate"]).format(
                      "YYYY-MM-DD HH:MM"
                    )}
                  />
                </div>
                <div className={Styles.inputContainer}>
                  <div className={Styles.label}>id</div>
                  <input
                    className={Styles.inputIdle}
                    disabled
                    value={refUser._id}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={Styles.inputLine}></div>
          <div className={Styles.lineTitle}>Details</div>
          <div className={Styles.inputLine}>
            <div className={Styles.inputContainer}>
              <div className={Styles.label}>First Name</div>
              <input
                className={
                  inputsModified["firstName"]
                    ? Styles.inputModified
                    : Styles.inputIdle
                }
                id="firstName"
                disabled={!modify}
                name="firstName"
                value={modifiedUser["firstName"]}
                onChange={handleInputChange}
              />
              <div className={Styles.error}>{errors.firstName}</div>
            </div>
            <div className={Styles.inputContainer}>
              <div className={Styles.label}>Last Name</div>
              <input
                className={
                  inputsModified["lastName"]
                    ? Styles.inputModified
                    : Styles.inputIdle
                }
                disabled={!modify}
                name="lastName"
                value={modifiedUser["lastName"]}
                onChange={handleInputChange}
              />
              <div className={Styles.error}>{errors.lastName}</div>
            </div>
          </div>
          <div className={Styles.inputLine}>
            <div className={Styles.inputContainer}>
              <div className={Styles.label}>Brand / Company</div>
              <input
                className={
                  inputsModified["company"]
                    ? Styles.inputModified
                    : Styles.inputIdle
                }
                disabled={!modify}
                name="company"
                value={modifiedUser["company"]}
                onChange={handleInputChange}
              />
              <div className={Styles.error}>{errors.company}</div>
            </div>
            <div className={Styles.inputContainer}>
              <div className={Styles.label}>email</div>
              <input
                className={
                  inputsModified["email"]
                    ? Styles.inputModified
                    : Styles.inputIdle
                }
                disabled={!modify}
                name="email"
                value={modifiedUser["email"]}
                onChange={handleInputChange}
              />
              <div className={Styles.error}>{errors.email}</div>
            </div>
          </div>
          <div className={Styles.inputLine}>
            <div className={Styles.inputContainer}>
              <div className={Styles.label}>address</div>
              <input
                className={
                  inputsModified["address"]
                    ? Styles.inputModified
                    : Styles.inputIdle
                }
                disabled={!modify}
                name="address"
                value={modifiedUser["address"]}
                onChange={handleInputChange}
              />
              <div className={Styles.error}>{errors.address}</div>
            </div>
            <div className={Styles.inputContainer}>
              <div className={Styles.label}>zip code</div>
              <input
                className={
                  inputsModified["zipcode"]
                    ? Styles.inputModified
                    : Styles.inputIdle
                }
                disabled={!modify}
                name="zipcode"
                value={modifiedUser["zipcode"]}
                onChange={handleInputChange}
              />
              <div className={Styles.error}>{errors.zipcode}</div>
            </div>
          </div>
          <div className={Styles.inputLine}>
            <div className={Styles.inputContainer}>
              <div className={Styles.label}>country</div>
              <select
                //defaultValue="none"
                className={
                  inputsModified["country"]
                    ? Styles.inputModified
                    : Styles.inputIdle
                }
                disabled={!modify}
                name="country"
                value={modifiedUser["country"]}
                onChange={handleInputChange}
              >
                {countriesList.map((country, index) => (
                  <option value={country.name}>{country.name}</option>
                ))}
              </select>
              <div className={Styles.error}>{errors.country}</div>
            </div>
            <div className={Styles.inputContainer}>
              <div className={Styles.label}>city</div>
              <input
                className={
                  inputsModified["city"]
                    ? Styles.inputModified
                    : Styles.inputIdle
                }
                disabled={!modify}
                name="city"
                value={modifiedUser["city"]}
                onChange={handleInputChange}
              />
              <div className={Styles.error}>{errors.city}</div>
            </div>
          </div>
          <div className={Styles.inputLine}>
            <div className={Styles.inputContainer}>
              <div className={Styles.label}>phone number</div>
              <input
                className={
                  inputsModified["phonenumber"]
                    ? Styles.inputModified
                    : Styles.inputIdle
                }
                disabled={!modify}
                name="phonenumber"
                value={modifiedUser["phonenumber"]}
                onChange={handleInputChange}
              />
              <div className={Styles.error}>{errors.phonenumber}</div>
            </div>
            <div className={Styles.inputContainer}>
              <div className={Styles.label}>mobile number</div>
              <input
                className={
                  inputsModified["mobilenumber"]
                    ? Styles.inputModified
                    : Styles.inputIdle
                }
                disabled={!modify}
                name="mobilenumber"
                value={modifiedUser["mobilenumber"]}
                onChange={handleInputChange}
              />
              <div className={Styles.error}>{errors.mobilenumber}</div>
            </div>
          </div>
          <div className={Styles.lineTitle}>Division</div>

          <div className={Styles.checkContainer}>
            <div className={Styles.checkSub}>
              <input
                type="checkbox"
                id="menswear"
                name="divisionmenswear"
                checked={modifiedUser["divisionmenswear"]}
                value={Boolean(modifiedUser["divisionmenswear"])}
                onChange={handleCheckChange}
              />
              <label for="menswear">menswear</label>
            </div>
            <div className={Styles.checkSub}>
              <input
                type="checkbox"
                id="womenswear"
                name="divisionwomenswear"
                checked={modifiedUser["divisionwomenswear"]}
                value={Boolean(modifiedUser["divisionwomenswear"])}
                onChange={handleCheckChange}
              />
              <label for="womenswear">womenswear</label>
            </div>
            <div className={Styles.checkSub}>
              <input
                type="checkbox"
                id="accessories"
                name="divisionaccessories"
                checked={modifiedUser["divisionaccessories"]}
                value={Boolean(modifiedUser["divisionaccessories"])}
                onChange={handleCheckChange}
              />
              <label for="accessories">accessories</label>
            </div>
          </div>
          <div className={Styles.lineTitle}>Role</div>

          <div>
            {modify && (
              <div className={Styles.roleContainer}>
                <div
                  className={
                    roleSelected === "custo"
                      ? roleModified === "custo"
                        ? Styles.roleBtnChanged
                        : Styles.roleBtnOn
                      : Styles.roleBtnOff
                  }
                  onClick={() => handleRoleChange("custo")}
                >
                  Visitor
                </div>
                <div
                  className={
                    roleSelected === "creat"
                      ? roleModified === "creat"
                        ? Styles.roleBtnChanged
                        : Styles.roleBtnOn
                      : Styles.roleBtnOff
                  }
                  onClick={() => handleRoleChange("creat")}
                >
                  Brands
                </div>
                <div
                  className={
                    roleSelected === "admin"
                      ? roleModified === "admin"
                        ? Styles.roleBtnChanged
                        : Styles.roleBtnOn
                      : Styles.roleBtnOff
                  }
                  onClick={() => handleRoleChange("admin")}
                >
                  Administrator
                </div>
              </div>
            )}
            {!modify && (
              <div
                className={
                  roleModified === roleSelected
                    ? Styles.roleDisChanged
                    : Styles.roleDis
                }
              >
                {getRoleText(modifiedUser["role"])}
              </div>
            )}
          </div>
          {modifiedUser["role"] !== "custo" &&
            modifiedUser["role"] !== "admin" &&
            !create && (
              <div>
                <div className={Styles.lineTitle}>Collections controlled</div>
                <CollectionsBlock
                  collections={modifiedUser}
                  type="menswear"
                  modify={modify}
                  onChange={handleCollectionsLock}
                />
                <CollectionsBlock
                  collections={modifiedUser}
                  type="womenswear"
                  modify={modify}
                  onChange={handleCollectionsLock}
                />
                <CollectionsBlock
                  collections={modifiedUser}
                  type="accessories"
                  modify={modify}
                  onChange={handleCollectionsLock}
                />
                <div></div>
              </div>
            )}
          {modifiedUser["role"] !== "admin" && (
            <div>
              <div className={Styles.lineTitle}>
                Collections accessible on navigation
                {modify && (
                  <div
                    className={Styles.btnCheckAll}
                    onClick={() => handleCheckAll("authorizedCollectionsIDs")}
                  >
                    check all
                  </div>
                )}
                {modify && (
                  <div
                    className={Styles.btnUncheckAll}
                    onClick={() => handleUncheckAll("authorizedCollectionsIDs")}
                  >
                    uncheck all
                  </div>
                )}
              </div>
              {!modify && (
                <div>
                  <div>
                    {modifiedUser["authorizedCollectionsIDs"].map((col) => (
                      <div>{col.name}</div>
                    ))}
                  </div>
                  <div>
                    {modifiedUser["authorizedCollectionsIDs"].length === 0 && (
                      <div>No collection</div>
                    )}
                  </div>
                </div>
              )}
              {modify && (
                <div>
                  {collections.map((col, index) => (
                    <div key={"authCol-" + index}>
                      <input
                        type="checkbox"
                        id={col.name}
                        name={col.name}
                        value={col.name}
                        checked={findCollectionInObject(
                          "authorizedCollectionsIDs",
                          col.name
                        )}
                        onChange={(e) =>
                          handleCollectionsChange("authorizedCollectionsIDs", e)
                        }
                      />
                      <label htmlFor={col.name}>{col.name}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
