import {css} from "@emotion/css";
import {Palette} from "../../constants";

const Styles = {
  frame: css({
    margin: "0.5rem 0.5rem",

    //backgroundColor: Palette.col_lightMediumGrey,
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media (min-width: 321px)": {
      height: "12rem",
      width: "10rem",
    },
    "@media (min-width: 1025px)": {
      height: "21rem",
      width: "20rem",
    },
    //  backgroundColor: "red",
  }),
  image: css({
    height: "8rem",
    width: "8rem",
    objectFit: "contain",
    "@media (min-width: 321px)": {
      height: "10rem",
      width: "10rem",
    },
    "@media (min-width: 1025px)": {
      height: "20rem",
      width: "20rem",
    },
  }),
  text: css({
    height: "2rem",
    fontSize: "1rem",
    bottom: 0,
  }),
};

export default Styles;
