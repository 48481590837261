import {css} from "@emotion/css";
import {Palette} from "../../../../constants";
const btnGroup = css({
  height: "1.5rem",
  lineHeight: "1.5rem",
  color: Palette.col_white,
  textAlign: "center",
  userSelect: "none",
  cursor: "pointer",
  borderLeft: "1px solid " + Palette.col_xxlightGrey,
  "&:hover": {
    backgroundColor: Palette.col_lightMediumGrey,
  },
});
const Styles = {
  tableFrame: css({
    display: "flex",
    flexDirection: "column",
  }),
  menuBar: css({
    width: "95vw",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid " + Palette.col_lightGrey,
    marginBottom: "1rem",
    paddingBottom: "1rem",
  }),
  btnCreate: css({
    marginLeft: "auto",
    width: "5rem",
    height: "1.5rem",
    lineHeight: "1.5rem",
    backgroundColor: Palette.col_white,
    color: Palette.col_mediumGrey,
    border: "1px solid " + Palette.col_mediumGrey,
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Palette.col_mediumGrey,
      color: Palette.col_white,
      border: "1px solid " + Palette.col_mediumGrey,
    },
  }),
  selectGroup: css({
    display: "flex",
  }),
  btnGroupMedium: css(btnGroup, {
    width: "7rem",
    backgroundColor: Palette.col_lightGrey,
  }),
  btnGroupMediumSelected: css(btnGroup, {
    width: "7rem",
    backgroundColor: Palette.col_darkGrey,
    "&:hover": {
      backgroundColor: Palette.col_darkGrey,
    },
  }),
  btnGroupSmall: css(btnGroup, {
    width: "4rem",
    backgroundColor: Palette.col_lightGrey,
  }),
  btnGroupSmallSelected: css(btnGroup, {
    width: "4rem",
    backgroundColor: Palette.col_darkGrey,
    "&:hover": {
      backgroundColor: Palette.col_darkGrey,
    },
  }),
};

export default Styles;
