import {css} from "@emotion/css";
import {Palette} from "../../../constants";

const btn = css({
  width: "9rem",
  textAlign: "center",
  fontSize: "0.9rem",
  marginBottom: "auto",
  height: "1.5rem",
  lineHeight: "1.5rem",
  userSelect: "none",
  cursor: "pointer",
});

const Styles = {
  frame: css({
    top: "0",
    left: "0",
    position: "fixed",
    height: "100vh",
    width: "100vw",
    backgroundColor: Palette.col_whiteOpacity,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  inner: css({
    height: "13rem",
    width: "18rem",
    backgroundColor: Palette.col_white,
  }),
  title: css({
    display: "flex",
    margin: "0.5rem",
    paddingBottom: "0.5rem",
    marginBottom: "1rem",
    borderBottom: "1px solid " + Palette.col_darkGrey,
    userSelect: "none",
  }),
  cross: css({
    marginLeft: "auto",
    color: "black",
    cursor: "pointer",
    userSelect: "none",

    "&:hover": {
      color: "red",
    },
  }),
  container: css({
    margin: "0 0.5rem",
    // height: "11.9rem",
  }),
  text: css({
    fontSize: "0.8rem",
    color: Palette.col_darkGrey,
    userSelect: "none",
  }),
  label: css({
    color: "black",
    marginTop: "0.7rem",
    userSelect: "none",
  }),
  input: css({
    outline: "none",
    border: "none",
    width: "95%",
    borderBottom: "1px solid black",
  }),
  error: css({
    color: "red",
    fontSize: "0.8rem",
    height: "0.8rem",
  }),
  btnContainer: css({
    display: "flex",
    marginTop: "2rem",
  }),
  btnCancel: css(btn, {
    backgroundColor: Palette.col_whiteGrey,
  }),
  btnSubmit: css(btn, {
    color: Palette.col_white,
    backgroundColor: "black",
    "&:hover": {
      backgroundColor: Palette.col_darkGrey,
    },
  }),
};

export default Styles;
