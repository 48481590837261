import React from "react";

import {css} from "@emotion/css/macro";

import Styles from "./Styles";

export default function RegularButton(props) {
  //PROPS TYPES : ""=normal , "white"=whiteMenu
  function getType() {
    if (props.white) return Styles.button["white"];
    else return Styles.button["regular"];
  }

  const btnType = getType();

  function setBackgroundBaseColor(state) {
    return props.backColor ? props.backColor : btnType[state].bkgColor;
  }
  function setBackgroundHoverColor(state) {
    return props.backHColor ? props.backHColor : btnType[state].bkgColor;
  }
  function setBaseColor(state) {
    return props.color ? props.color : btnType[state].color;
  }
  function setWidth(state) {
    return props.width ? props.width : btnType[state].width;
  }
  function setHeight(state) {
    return props.height ? props.height : btnType[state].height;
  }
  function setBorder(state) {
    return props.border ? props.border : btnType[state].border;
  }
  function setMarginLeft(state) {
    return props.ml ? props.ml : 0;
  }
  function setMarginTop(state) {
    return props.mt ? props.mt : 0;
  }
  function setMarginRight(state) {
    return props.mr ? props.mr : 0;
  }

  const base = css({
    boxSizing: "border-box",
    width: setWidth("standard"),
    borderRadius: "3px",
    height: setHeight("standard"),
    lineHeight: setHeight("standard"),
    textAlign: "center",
    fontSize: "1rem",
    backgroundColor: setBackgroundBaseColor("standard"),
    border: setBorder("standard"),
    color: setBaseColor("standard"),
    cursor: "pointer",
    userSelect: "none",
    marginLeft: setMarginLeft("standard"),
    marginTop: setMarginTop("standard"),
    marginRight: setMarginRight("standard"),
  });

  const hover = css({
    border: setBorder("hover"),
    backgroundColor: setBackgroundHoverColor("hover"),
    lineHeight: setHeight("hover"),
    color: setBaseColor("hover"),
  });

  const styleBase = css(
    {
      "&:hover": hover,
    },
    base
  );

  const styleDisabled = css(base, {
    backgroundColor: setBackgroundBaseColor("disabled"),
    color: setBaseColor("disabled"),
    border: setBorder("disabled"),
    cursor: "initial",
    userSelect: "none",
  });

  return (
    <div
      className={props.isdisabled ? styleDisabled : styleBase}
      onClick={!props.isdisabled ? props.onClick : null}
    >
      {props.name}
    </div>
  );
}
