import {createSelector, createSlice} from "@reduxjs/toolkit";
import findIndex from "lodash/findIndex";

const slice = createSlice({
  name: "catDisplay",
  initialState: [],
  reducers: {
    initDisplay: (catDisplayed, action) => {
      catDisplayed = action.payload.catDisplayed;
    },
    setDisplay: (catDisplayed, action) => {
      const index = findIndex(catDisplayed, {
        name: action.payload.name,
        colId: action.payload.colId,
      });
      if (index > -1) {
        catDisplayed[index] = {
          colId: action.payload.colId,
          name: action.payload.name,
          status: action.payload.status,
        };
      } else {
        catDisplayed.push({
          name: action.payload.name,
          colId: action.payload.colId,

          status: action.payload.status,
        });
      }
    },
  },
});

export const {setDisplay, initDisplay} = slice.actions;
export default slice.reducer;

//selector

export const getCatDisplayed = (state) => state.catDisplay;

export const getCatDisplayedByName = (name, colId) => (state) => {
  if (name == null) return state.catDisplay;
  const index = findIndex(state.catDisplay, {name, colId});
  if (index > -1) return state.catDisplay[index].catDisplayed;
  else return null;
};
