import httpService from "../httpService";

const apiUrl = "user";

export async function getUserById(id) {
  try {
    const res = await httpService.get(apiUrl + "/userById/" + id);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function logUser({email, password}) {
  try {
    const res = await httpService.post(apiUrl + "/login", {email, password});
    return res;
  } catch (error) {
    return null;
  }
}
export async function getCreators() {
  try {
    const res = await httpService.get(apiUrl + "/users/creators");
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function getAllUsers() {
  try {
    const res = await httpService.get(apiUrl + "/users/all");
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function modifyUser(data) {
  try {
    const res = await httpService.post(apiUrl + "/modify", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function registerUser(data) {
  try {
    const res = await httpService.post(apiUrl + "/register", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function confirmEmail(data) {
  try {
    const res = await httpService.post(apiUrl + "/confirm", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function resetPassword(data) {
  try {
    const res = await httpService.post(apiUrl + "/resetPassword", data);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function updatePassword(data) {
  try {
    const res = await httpService.post(apiUrl + "/updatePassword", data);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function changePassword(data) {
  try {
    const res = await httpService.post(apiUrl + "/changePassword", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function deleteUser(data) {
  try {
    const res = await httpService.post(apiUrl + "/deleteuser", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function validateUser(data) {
  try {
    const res = await httpService.post(apiUrl + "/validateUser", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function createUser(data) {
  try {
    const res = await httpService.post(apiUrl + "/createUser", data);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function createUsers(data) {
  try {
    const res = await httpService.post(apiUrl + "/createUsers", data);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function checkId(email) {
  try {
    const res = await httpService.get(apiUrl + "/checkid/" + email);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function checkUserCollectionByLine(data) {
  try {
    const res = await httpService.post(apiUrl + "/checkCollection", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function postCollectionsToUsers(data) {
  try {
    const res = await httpService.post(
      apiUrl + "/assignCollectionsToUsers",
      data
    );
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function postAllCollectionsToUsers(data) {
  try {
    const res = await httpService.post(
      apiUrl + "/assignAllCollectionsToUsers",
      data
    );
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function postRemoveCollectionsToUsers(data) {
  try {
    const res = await httpService.post(
      apiUrl + "/unassignAllCollectionsToUsers",
      data
    );
    return res.data;
  } catch (error) {
    return null;
  }
}
