import findIndex from "lodash/findIndex";
import find from "lodash/find";
import orderBy from "lodash/orderBy";

import Joi from "joi/dist/joi-browser.min.js";

import {
  saveProductImages,
  saveCollectionProducts,
  createProduct,
  postCompressImage,
  getStaticImage,
} from "../services";
import {cloneDeep} from "lodash";
import {getAllproducts} from "./collectionsUtils";

const schema = Joi.object({
  _id: Joi.string(),
  __v: Joi.number(),
  line: Joi.string(),
  collect: Joi.string().required(),
  category: Joi.string().required(),
  model: Joi.string().required(),
  name: Joi.string().required(),
  color: Joi.string().required(),
  reference: Joi.string().required(),
  description: Joi.string().required(),
  prices: Joi.object().keys({
    _id: Joi.string(),
    priceEUR: Joi.number(),
    priceUSD: Joi.number(),
    priceJPY: Joi.number(),
    priceGPB: Joi.number(),
    priceCNY: Joi.number(),
  }),
  images: Joi.array().items(Joi.string()),
});

async function validateImageType(im) {
  const result = null;
  if (!im) return null;
  var fileType = im.split(".").pop().toLowerCase();
  if (
    fileType === "webp" ||
    fileType === "png" ||
    fileType === "jpg" ||
    fileType === "jpeg"
  ) {
    return {type: "other", file: im};
  }
  if (fileType === "nsaws") {
    const awsIm = await getStaticImage(im);
    return {type: "aws", file: awsIm};
  }
  return null;
}

function isProductValid(product) {
  const errors = schema.validate(product);

  if (errors.error) return false;

  if (product.images[0] == null) return false;

  //Has at least 1 price
  if (
    !product.prices.priceEUR &&
    !product.prices.priceUSD &&
    !product.prices.priceJPY &&
    !product.prices.priceGPB &&
    !product.prices.priceCNY
  ) {
    return false;
  }

  return true;
}

async function sortImages(images, id) {
  const imToSave = [];

  for (let i = 0; i < images.length; i++) {
    console.log("images", images);
    const el = images[i];
    if (el) {
      if (el.type === "aws") {
        console.log("el.file", el.file);
        const fl = await getStaticImage(el.file.name);
        const newFile = new File([fl], id + "-" + i);
        imToSave.push(newFile);
      } else {
        const compressedImages = await postCompressImage({
          images: [el.file],
          id,
        });
        const newFile = new File([compressedImages[0]], id + "-" + i);

        imToSave.push(newFile);
      }
    }
  }
  return imToSave;
}

async function saveProductToDB(product, image) {
  //save product to db to get id
  const id = await createProduct(product);

  //download images
  let images = [
    await validateImageType(image["Image1 URL"]),
    await validateImageType(image["Image2 URL"]),
    await validateImageType(image["Image3 URL"]),
    await validateImageType(image["Image4 URL"]),
  ];

  //is nsaws

  //const compressedImages = await postCompressImage({images, id});

  let imagesToSave = [];
  imagesToSave = await sortImages(images, id);
  console.log("imagesToSave", imagesToSave);
  //update product with images
  product = await saveProductImages(id, imagesToSave);
  console.log("product", product);
  return product;
}
function sortProducts(products, db = []) {
  const database = [...db];

  products.forEach((prod) => {
    //set variables
    const prodCategory = prod.category;
    const prodName = prod.name;
    const prodId = prod._id;
    //category
    const indexCategory = findIndex(database, {name: prodCategory});
    //if no category => insert all
    if (indexCategory === -1) {
      database.push({
        name: prodCategory,
        variants: [
          {
            name: prodName,
            products: [prodId],
          },
        ],
      });
      return;
    }
    const indexVariant = findIndex(database[indexCategory].variants, {
      name: prodName,
    });
    if (indexVariant === -1) {
      database[indexCategory].variants.push({
        name: prodName,
        products: [prodId],
      });
      return;
    }
    database[indexCategory].variants[indexVariant].products.push(prodId);
  });

  return database;
}
export async function readCollectionFile(
  lines,
  collection,
  counterCallback,
  collDb
) {
  const allProducts = await getAllproducts(collection);
  const validProducts = [];
  const invalidProducts = [];

  const validDb = cloneDeep(collDb.validProducts);
  const invalidDb = cloneDeep(collDb.invalidProducts);

  let count = 0;
  const countIncrement =
    lines.length === 0 ? 0 : Math.trunc(100 / lines.length);

  //get products from file
  for (let i = 0; i < lines.length; i++) {
    let product = {
      _id: "",
      line: lines[i].Line,
      collect: collection.name,
      category: lines[i].Category,
      model: lines[i].Model,
      name: lines[i].Name,
      color: lines[i].Color,
      reference: lines[i].Reference,
      description: lines[i].Description,
      prices: {
        priceEUR: lines[i]["Price EUR"],
        priceUSD: lines[i]["Price USD"],
        priceJPY: lines[i]["Price JPY"],
        priceGPB: lines[i]["Price GPB"],
        priceCNY: lines[i]["Price CNY"],
      },
      images: [],
    };
    console.log("product==>", product);
    //doublons
    const prodExists = find(allProducts, {
      name: product.name,
      color: product.color,
      category: product.category,
    });
    if (prodExists) continue;
    allProducts.push(product);

    //save prod
    const savedProd = await saveProductToDB(product, lines[i]);

    //sort product validity
    const isValid = isProductValid(savedProd);
    if (isValid) validProducts.push(savedProd);
    else invalidProducts.push(savedProd);

    count += countIncrement;
    counterCallback(count);
  }
  const validProductsSorted = sortProducts(
    orderBy(validProducts, ["name"], ["asc"]),
    collDb.validProducts
  );
  const invalidProductsSorted = sortProducts(
    orderBy(invalidProducts, ["name"], ["asc"]),
    collDb.invalidProducts
  );
  const validProductsDb = orderBy(validProductsSorted, ["name"], ["asc"]);
  const invalidProductsDb = orderBy(invalidProductsSorted, ["name"], ["asc"]);

  const res = await saveCollectionProducts({
    validProducts: validProductsDb,
    invalidProducts: invalidProductsDb,
    colId: collection._id,
    allProducts,
  });

  collection.database.validProducts = validProductsDb;
  collection.database.invalidProducts = invalidProductsDb;

  return {
    collection,
    allProducts,
  };
}
