import React, {useState, useEffect} from "react";
import {Navigate} from "react-router";

import Styles from "./Styles";
//import logo from "../../assets/images/logo.png";

import {Login} from "./login";
import {getHomeBckImage, getLogoImage} from "../../services";

export default function Welcome(props) {
  const [redirectToMain, setRedirectToMain] = useState(false);
  const [redirectToRegister, setRedirectToRegister] = useState(false);
  const [bckImage, setBckImage] = useState("");
  const [logo, setLogo] = useState("");

  useEffect(() => {
    init();
  }, []);

  async function init() {
    const file = await getHomeBckImage();
    const im = URL.createObjectURL(file);
    setBckImage(im);
    const res = await getLogoImage();
    const log = URL.createObjectURL(res);
    setLogo(log);
  }

  function handleRegister() {
    setRedirectToRegister(true);
  }
  function handleLogin() {
    setRedirectToMain(true);
  }
  if (redirectToRegister) {
    //setRedirect(false);
    return <Navigate push to={"/Register"} />;
  }
  if (redirectToMain) {
    //setRedirect(false);
    return <Navigate push to={"/Home"} />;
  }

  return (
    <div className={Styles.frame}>
      <div
        className={Styles.banner}
        style={{backgroundImage: "url(" + bckImage + ")"}}
      >
        <div className={Styles.logoContainer}>
          <img className={Styles.logo} src={logo} alt="" />
          <Login onlogin={handleLogin} onRegister={handleRegister} />
        </div>
      </div>
    </div>
  );
}
