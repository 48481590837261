import {combineReducers} from "redux";

import productsImagesReducer from "./productsImages";
import userFiltersReducer from "./userFilters";
import userTableColumnReducer from "./userTableColumn";
import catDisplayReducer from "./catDisplay";

export default combineReducers({
  prodImages: productsImagesReducer,
  userFilters: userFiltersReducer,
  userTableColumn: userTableColumnReducer,
  catDisplay: catDisplayReducer,
});
