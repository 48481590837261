import React, {useEffect, useState, Fragment} from "react";
import {css, keyframes} from "@emotion/css";
import {Palette} from "../../../constants";
import {getCollectionImageById, getProductImage} from "../../../services";
import loader from "../../../assets/images/loader.png";

export default function CardImage(props) {
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const animate = keyframes`
  from {transform: rotate(0deg)}
  to {transform: rotate(360deg)}`;

  const Image = css({
    height: "8rem",
    width: "8rem",
    objectFit: "contain",
    "@media (min-width: 321px)": {
      height: "10rem",
      width: "10rem",
    },
    "@media (min-width: 1025px)": {
      height: "20rem",
      width: "20rem",
    },
  });

  const Styles = {
    imageHidden: css(Image, {
      visibility: "hidden",
    }),
    imageDisplay: css(Image, {}),
    loaderContainer: css(Image, {
      // backgroundColor: "red",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    loader: css({
      width: "3rem",
      height: "3rem",
      filter: "saturate(0)",
      animation: animate + " 1s linear infinite ",
      opacity: "0.5",
    }),
  };

  useEffect(() => {
    init();
  }, []);
  async function init() {
    let im = null;
    if (props.product) {
      im = await getProductImage(props.image);
    } else {
      im = await getCollectionImageById(props.image);
    }

    if (im == null) {
      setIsLoading(false);
      setImage("");
    } else {
      const final = URL.createObjectURL(im);
      setIsLoading(false);
      setImage(final);
    }
  }
  return (
    <>
      {isLoading && (
        <div className={Styles.loaderContainer}>
          <img className={Styles.loader} src={loader} alt="" />
        </div>
      )}
      <img
        className={image == null ? Styles.imageHidden : Styles.imageDisplay}
        src={image}
        alt=""
      />
    </>
  );
}
