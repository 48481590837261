import {Variant} from ".";
import concat from "lodash/concat";

class Category {
  constructor() {
    this.name = "";
    this.variants = [];
  }
  init(coll, variants) {
    return new Promise(async (resolve, rejected) => {
      this.name = coll.name;
      let variantsList = [];
      let productsList = [];
      for (let i = 0; i < coll.variants.length; i++) {
        const variant = new Variant();
        const prodList = await variant.init(coll.variants[i]);
        variantsList.push(variant);
        productsList = concat(productsList, prodList);
      }

      this.variants = variantsList;
      resolve(productsList);
    });
  }
}

export default Category;
