import React, {Fragment} from "react";

import getStyles from "./Styles";

export default function Icon(props) {
  const Styles = getStyles(props);

  return (
    <>
      <div
        itemkey={props.itemkey}
        className={Styles.icon}
        onClick={() =>
          props.onClick ? props.onClick(props.itemkey) : () => {}
        }
        onMouseOver={props.onHover}
      >
        <div className={Styles.back}></div>
      </div>
    </>
  );
}
