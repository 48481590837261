import React, {useEffect, useState, lazy, Suspense, useContext} from "react";
import filter from "lodash/filter";

import {CollectionsList} from "./collectionsList";

import {CreateCollection} from "./createCollection";

import {Link} from "react-router-dom";
import {Icon, LoaderWin} from "../../../components";
import {Navbar} from "../../../components";
import Styles from "./Styles";
import {Palette} from "../../../constants";

import {getAllCollections, getUserById} from "../../../services";

import {deepCopy} from "../../../utils/deepCopy";

import {UserContext} from "../../../contexts";
import findIndex from "lodash/findIndex";

const EditCollection = lazy(() => import("./editCollection/EditCollection"));

export default function CollectionsHome(props) {
  const [action, setAction] = useState("");
  const [selectedCollectionId, setSelectedCollectionId] = useState("");
  const [title, setTitle] = useState("COLLECTIONS");
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const userCtx = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    init();
    setLoading(false);
  }, []);

  async function init() {
    const coll = await getAllCollections();
    setUser(userCtx.user.role);
    if (userCtx.user.role === "creat") {
      const temp = [];
      const user = await getUserById(userCtx.user._id);
      //look for collection in user
      //const index = findIndex(user.authorizedCollection)
      coll.forEach((col) => {
        if (col.creator._id === userCtx.user._id) {
          const line = col.line;
          if (user.collections[line].isUnlocked) temp.push(col);
        }
      });

      setCollections(temp);
      return;
    }
    setCollections(coll);
  }

  function handleActionChange(type, id) {
    if (type === "modify") setSelectedCollectionId(id);
    setAction(type);
    switch (type) {
      case "create":
        setTitle("CREATE COLLECTION");
        break;
      case "modify":
        setTitle("MODIFY COLLECTION");
        break;
      default:
        setTitle("COLLECTION");
    }
  }
  function handleCreateClose() {
    setAction("");
    init();
  }
  function handleCollectionChange(col) {
    const newColl = deepCopy(collections);
    newColl.push(col);
    setCollections(newColl);
  }
  function handleOnDelete(id) {
    init();
    setAction("");
  }
  return (
    <div className={Styles.frame}>
      {loading && <LoaderWin />}
      <Navbar />
      <div className={Styles.margin}></div>
      <div className={Styles.innerFrame}>
        <div className={Styles.navigation}>
          <Link className={Styles.navItem} to="/Home">
            Home
          </Link>
          <Icon
            icon="arrowheadright"
            color={Palette.col_mediumGrey}
            hcolor={Palette.col_mediumGrey}
            size="1.3rem"
          />
          {user !== "creat" && (
            <Link className={Styles.navItem} to="/Admin">
              Administration
            </Link>
          )}

          {user !== "creat" && (
            <Icon
              icon="arrowheadright"
              color={Palette.col_mediumGrey}
              hcolor={Palette.col_mediumGrey}
              size="1.3rem"
            />
          )}
          {action !== "" && (
            <div
              className={Styles.navItem}
              onClick={() => handleActionChange("")}
            >
              Collections
            </div>
          )}
          {action === "" && <div className={Styles.navItem}>Collections</div>}
          {action !== "" && (
            <Icon
              icon="arrowheadright"
              color={Palette.col_mediumGrey}
              hcolor={Palette.col_mediumGrey}
              size="1.3rem"
            />
          )}
          {action === "create" && <div className={Styles.navItem}>Create</div>}
          {action === "modify" && <div className={Styles.navItem}>Edit</div>}
        </div>
        <div className={Styles.title}>{title}</div>
        <div className={Styles.container}>
          {action === "" && (
            <CollectionsList
              onActionChange={handleActionChange}
              collections={collections}
              onChange={handleCollectionChange}
              creator={props.creator}
            />
          )}
          {action === "create" && !props.creator && (
            <CreateCollection
              onClose={handleCreateClose}
              onChange={handleCollectionChange}
            />
          )}
          {action === "modify" && (
            <Suspense fallback={<div>Loading...</div>}>
              <EditCollection
                collectionId={selectedCollectionId}
                onDelete={handleOnDelete}
                onChange={handleOnDelete}
                onClose={handleCreateClose}
              />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
}
