import httpService from "../httpService";

const apiUrl = "products";
const b64toBlob = require("b64-to-blob");
export async function saveProductImages(id, imagesToSave) {
  console.log("id, imagesToSave", id, imagesToSave);
  const data = new FormData();
  for (let i = 0; i < imagesToSave.length; i++) {
    data.append("file", imagesToSave[i]);
    console.log("index", i);
  }
  data.append("id", id);
  console.log("formdata", data);
  try {
    const res = await httpService.post(apiUrl + "/saveImages", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function createProduct(data) {
  try {
    const res = await httpService.post(apiUrl + "/createProduct", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function findProductsById(data) {
  try {
    const res = await httpService.post(apiUrl + "/findProductsById", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function findProductsByCollectionName(name) {
  console.log("name", name);
  try {
    const res = await httpService.get(
      apiUrl + "/findProductsByCollectionName/" + name
    );
    return res.data;
  } catch (error) {
    console.log("errors", error);
    return null;
  }
}

//__________________________________________________________
export async function saveProduct(data) {
  try {
    const res = await httpService.post(apiUrl + "/saveProduct", data);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function updateProduct(data) {
  const datas = new FormData();
  for (let i = 0; i < data.images.length; i++) {
    if (typeof data.images[i] === "object") {
      datas.append("file", data.images[i]);
    }
  }
  datas.append("product", JSON.stringify(data));
  try {
    const res = await httpService.post(apiUrl + "/updateProduct", datas);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function findProductsByCollectionId(id) {
  try {
    const res = await httpService.get(
      apiUrl + "/findProductsByCollectionId/" + id
    );
    console.log("res.data", res.data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function getProductById(id, cache = true) {
  const cacheOption = cache ? "default" : "no-store";

  try {
    const url = process.env.REACT_APP_API_URL + apiUrl + "/" + id;
    const res = await fetch(url, {cache: cacheOption});
    let data = await res.json();
    return data;
  } catch (error) {
    return null;
  }
}

export async function getProductImage(id, cache = true) {
  const cacheOption = cache ? "default" : "no-store";

  if (id === null) return null;
  try {
    const url = process.env.REACT_APP_API_URL + apiUrl + "/image/" + id;

    let response = await fetch(url, {cache: cacheOption});
    let data = await response.blob();
    let metadata = {
      type: "image/" + id.split("-")[0],
    };
    let file = new File([data], id, metadata);

    return file;
  } catch (error) {
    return null;
  }
}

export async function deleteProduct(data) {
  try {
    const res = await httpService.post(apiUrl + "/deleteProduct", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

//doublon
export async function getProductsByIds(data) {
  try {
    const res = await httpService.post(apiUrl + "/findProductsById", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function postCompressImage(data) {
  try {
    const res = await httpService.post(apiUrl + "/imageCompress", data);
    const buff = res.data;
    const files = [];
    for (let i = 0; i < buff.length; i++) {
      if (buff[i].file == null) {
        files.push(null);
      } else {
        const tt = b64toBlob([buff[i].file], "image/jpeg");
        let file = new File([tt], data.id + "-" + buff[i].index, {
          type: "image/jpeg",
        });
        files.push(file);
      }
    }
    return files;
  } catch (error) {
    return null;
  }
}
export async function postCompressSingleImage(data) {
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  let formdata = new FormData();
  formdata.append("file", data.image);

  const dat = await toBase64(data.image);
  const dats = {test: JSON.stringify(dat)};

  try {
    const res = await httpService.post(
      apiUrl + "/singleImageCompress",
      formdata
    );
    const buff = res.data;

    if (res.data === "error") return null;
    let result = null;

    if (buff != null) {
      const tt = b64toBlob([buff], "image/jpeg");
      let file = new File([tt], data.filename, {
        type: "image/jpeg",
      });
      result = file;
    }

    return result;
  } catch (error) {
    window.alert("erroor------");
    return null;
  }
}
export async function postCompressSingleImagePng(data) {
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  let formdata = new FormData();
  formdata.append("file", data.image);

  const dat = await toBase64(data.image);
  const dats = {test: JSON.stringify(dat)};
  try {
    const res = await httpService.post(
      apiUrl + "/singleImageCompressPng",
      formdata
    );
    const buff = res.data;
    let result = null;

    if (buff != null) {
      const tt = b64toBlob([buff], "image/png");
      let file = new File([tt], data.filename, {
        type: "image/png",
      });
      result = file;
    }

    return result;
  } catch (error) {
    return null;
  }
}
