import {css} from "@emotion/css";

const Styles = {
  frame: css({
    minHeight: "100vh",
    width: "100vw",
  }),
  margin: css({
    height: "50px",
  }),
  // innerFrame: css({
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   height: "calc(100vh - 50px)",
  //   fontSize: "5rem",
  // }),
};

export default Styles;
