import {css} from "@emotion/css";

import {Palette} from "../../../../constants";
const btnCheck = css({
  cursor: "pointer",
  padding: "0 0.2rem",
  fontSize: "0.7rem",
  height: "1rem",
  width: "4rem",
  textAlign: "center",
  lineHeight: "1rem",
  border: "1px solid " + Palette.col_mediumGrey,
  "&:hover": {
    backgroundColor: Palette.col_orange,
    color: Palette.col_white,
    border: "1px solid " + Palette.col_orange,
  },
});

const btn = css({
  cursor: "pointer",
  height: "2rem",
  width: "5rem",
  lineHeight: "2rem",
  textAlign: "center",
  fontSize: "1rem",
  userSelect: "none",
  boxSizing: "border-box",
  backgroundColor: Palette.col_white,
  color: Palette.col_darkGrey,
  border: "1px solid " + Palette.col_darkGrey,
  marginRight: ".5rem",
  outline: "none",
});
const roleBtn = css(btn, {
  border: "1px solid " + Palette.col_lightGrey,
  width: "7rem",
  "&:disabled": {
    backgroundColor: Palette.col_white,
  },
});
const input = css({
  border: "1px solid " + Palette.col_lightBlue,
  borderRadius: "0.3rem",
  fontSize: "1rem",
  "&:disabled": {
    border: "none",
    outline: "none",
  },
});

const Styles = {
  frame: css({
    margin: "0 2rem 2rem 2rem",
  }),
  margin: css({
    height: "50px",
  }),
  navigation: css({
    marginTop: "1rem",
    display: "flex",
    alignItems: "center",
  }),
  navItem: css({
    textDecoration: "none",
    color: Palette.col_darkGrey,
    cursor: "pointer",
    "&:hover": {
      color: Palette.col_orange,
    },
  }),
  separator: css({
    color: "blue",
  }),

  menu: css({
    display: "flex",
    //justifyContent: "space-between",
    //paddingBottom: "0.2rem",
    //borderBottom: "1px solid " + Palette.col_mediumGrey,
  }),
  title: css({
    fontSize: "1.2rem",
    margin: "1rem 0",
  }),
  lineTitle: css({
    display: "flex",
    alignItems: "center",
    width: "100%",
    lineHeight: "2rem",
    fontSize: "1.2rem",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    borderBottom: "1px solid " + Palette.col_mediumGrey,
  }),
  btnOn: css(btn, {
    marginTop: "1rem",
    backgroundColor: Palette.col_black,
    color: Palette.col_white,
    "&:hover": {
      backgroundColor: Palette.col_orange,

      border: "none",
    },
  }),
  btnDeleteOn: css(btn, {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "red",
      color: Palette.col_white,
      border: "none",
    },
  }),
  btnConfirmOn: css(btn, {
    marginLeft: "auto",
    backgroundColor: "red",
    color: Palette.col_white,

    "&:hover": {
      backgroundColor: "red",
      color: Palette.col_black,
      border: "none",
    },
  }),
  btnLockOff: css(btn, {
    "&:hover": {
      backgroundColor: "red",
      color: Palette.col_white,
      border: "none",
    },
  }),
  btnLockOn: css(btn, {
    backgroundColor: "red",
    color: Palette.col_white,

    "&:hover": {
      backgroundColor: "red",
      color: Palette.col_black,
      border: "none",
    },
  }),
  btnOff: css(btn, {
    color: Palette.col_lightGrey,
    borderColor: Palette.col_lightGrey,
    marginTop: "1rem",
  }),
  inputLine: css({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  }),
  inputContainer: css({
    width: "15rem",
  }),
  inputIdle: css(input, {}),
  inputModified: css(input, {
    border: "1px solid red",
    "&:disabled": {
      border: "1px solid red",
    },
  }),
  testChange: css({
    color: "red",
    textAlign: "center",
  }),

  submitBtn: css(btn, {
    border: "none",
    outline: "none",
    "&:hover": {
      backgroundColor: Palette.col_orange,
    },
    "&:disabled": {
      backgroundColor: Palette.col_white,
    },
  }),
  roleContainer: css({
    display: "flex",
    justifyContent: "space-around",
  }),
  roleBtnOn: css(roleBtn, {
    backgroundColor: Palette.col_black,
    color: Palette.col_white,
  }),
  roleBtnOff: css(roleBtn, {
    backgroundColor: Palette.col_white,
    "&:hover": {
      backgroundColor: Palette.col_xlightGrey,
    },
  }),
  roleBtnChanged: css(roleBtn, {
    backgroundColor: "red",
    color: Palette.col_white,
  }),
  roleDis: css({}),
  roleDisChanged: css({
    color: "red",
  }),
  btnCheckAll: css(btnCheck, {
    marginLeft: "auto",
  }),
  btnUncheckAll: css(btnCheck, {
    marginLeft: "0.5rem",
  }),
  error: css({
    color: "red",
    fontSize: ".7rem",
    height: ".7rem",

    marginBottom: "0.5rem",
  }),
};

export default Styles;
