import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import concat from "lodash/concat";

import {Category} from "./";
import {getCollectionById, getCollectionImageById} from "../services";
class Collection {
  constructor() {
    this.id = "";
    this.reference = {
      name: "",
      line: "",
      launchDate: Date.now(),
      lookbook: "",
      linesheet: "",
      title: "",
      creator: {lastName: "", firstName: "", _id: "", company: ""},
      image: "",
      incoterm: "",
      logo: "",
      isOnline: false,
    };
    this.modified = {
      name: "",
      line: "",
      launchDate: Date.now(),
      lookbook: "",
      linesheet: "",
      title: "",
      creator: {lastName: "", firstName: "", _id: "", company: ""},
      image: "",
      incoterm: "",
      logo: "",
      isOnline: false,
    };
    this.status = {
      isModified: false,
      canSave: false,
      canSend: false,
      coverflow: false,
      display: false,
      errors: {},
    };
    this.imageFile = null;
    this.lookbookFile = null;

    this.database = {
      onlineProducts: [],
      validProducts: [],
      invalidProducts: [],
    };
  }
  init(id) {
    return new Promise(async (resolve, reject) => {
      const coll = await getCollectionById(id, false);
      const imFile = await getCollectionImageById(
        coll.image,
        process.env.REACT_APP_IMAGE_CACHE
      );
      this.id = id;
      const colldatas = {
        name: coll.name,
        line: coll.line,
        launchDate: coll.launchDate,
        lookbook: coll.lookbook,
        linesheet: coll.linesheet,
        title: coll.title,
        creator: {
          lastName: coll.creator.lastName,
          firstName: coll.creator.firstName,
          _id: coll.creator._id,
          company: coll.creator.company,
        },
        image: coll.image,
        incoterm: coll.incoterm,
        logo: coll.logo == null ? "" : coll.logo,
        isOnline: coll.isOnline,
      };

      this.reference = cloneDeep(colldatas);
      this.modified = cloneDeep(colldatas);
      this.imageFile = imFile;

      //createCategories
      //onlineproducts
      let productsList = [];

      const onlineProducts = [];

      for (let i = 0; i < coll.database.onlineProducts.length; i++) {
        const category = new Category();
        const prodList = await category.init(coll.database.onlineProducts[i]);
        productsList = concat(productsList, prodList);

        onlineProducts.push(category);
      }
      //validproducts
      const validProducts = [];
      for (let i = 0; i < coll.database.validProducts.length; i++) {
        const category = new Category();
        const prodList = await category.init(coll.database.validProducts[i]);
        productsList = concat(productsList, prodList);

        validProducts.push(category);
      }

      //invalidproducts
      const invalidProducts = [];
      for (let i = 0; i < coll.database.invalidProducts.length; i++) {
        const category = new Category();
        const prodList = await category.init(coll.database.invalidProducts[i]);
        productsList = concat(productsList, prodList);

        invalidProducts.push(category);
      }
      //Save categories
      this.database.onlineProducts = onlineProducts;
      this.database.validProducts = validProducts;
      this.database.invalidProducts = invalidProducts;

      resolve(productsList);
    });
  }

  inputChange(name, val) {
    this.modified[name] = val;
  }

  checkChanged() {
    const res = isEqual(this.reference, this.modified);
    return res;
  }
  rollBack() {
    this.modified = this.reference;
  }
  imageChange(im) {
    this.modified.image = im;
  }
  startDateChange(date) {
    this.modified.launchDate = date;
  }
}

export default Collection;
