import {createSelector, createSlice} from "@reduxjs/toolkit";
import findIndex from "lodash/findIndex";

const slice = createSlice({
  name: "prodImages",
  initialState: [],
  reducers: {
    prodImageAdded: (imFiles, action) => {
      const index = findIndex(imFiles, {id: action.payload.id});
      if (index > -1) {
        imFiles[index] = {
          id: action.payload.id,
          imFile: action.payload.imFile,
        };
      } else {
        imFiles.push({
          id: action.payload.id,
          imFile: action.payload.imFile,
        });
      }
    },
    prodImageDeleted: (imFiles, action) => {
      const ids = action.payload.ids;
      for (let i = 0; i < ids.length; i++) {
        const el = ids[i];
        const index = findIndex(imFiles, {id: el});
        if (index > -1) {
          imFiles.splice(index, 1);
        }
      }
    },
  },
});

export const {prodImageAdded, prodImageDeleted} = slice.actions;
export default slice.reducer;

//Selector
export const getProdImage = (state) => state;

export const getProdImageById = (id) => (state) => {
  const index = findIndex(state.prodImages, {id});
  if (index > -1) return state.prodImages[index].imFile;
  else return null;
};
