import React, {useState, useEffect, useContext} from "react";
import {getStyles} from "./Styles";

import {getProductImage, postCompressSingleImage} from "../../services";

import {Loader} from "../Loader";

import addFile from "../../assetsIcons/icons/add_file.png";
import {useDispatch, useSelector} from "react-redux";
import {
  getProdImage,
  getProdImageById,
  prodImageAdded,
  prodImageDeleted,
} from "../../store/productsImages";

export default function ImageBlockProduct(props) {
  const Styles = getStyles(props);

  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const dispatch = useDispatch();
  let prodIm = useSelector(
    getProdImageById(props.prodId + "-" + props.imageNb)
  );

  useEffect(() => {
    init();
  }, [props.prodId, props.image]);

  async function init() {
    setIsLoading(true);

    const imSource = props.image[props.imageNb];
    if (prodIm) {
      setErrors(null);
      setImage(prodIm);
    } else if (typeof imSource === "string") {
      let cache = true;
      if (props.nocache === true) cache = false;
      const im = await getProductImage(imSource, cache);
      const final = URL.createObjectURL(im);
      setErrors(null);
      setImage(final);
      dispatch(
        prodImageAdded({
          id: props.prodId + "-" + props.imageNb,
          imFile: final,
        })
      );
    } else if (imSource == null) {
      setImage(null);
      if (props.imageNb === 0) setErrors("Need a main image");
    }

    setIsLoading(false);
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  async function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    //if (props.locked) return;
    let file = [...e.dataTransfer.files];
    if (file[0].size > 2700000) {
      window.alert("File size exeeding the 2.5Mo limit");
      return;
    }
    if (file[0]) {
      setIsLoading(true);
      props.onChangeFile(file[0], props.imageNb);

      const res = await readUrlFromFile(file[0]);
      dispatch(
        prodImageAdded({
          id: props.prodId + "-" + props.imageNb,
          imFile: res,
        })
      );
    }
  }
  async function readUrlFromFile(file) {
    //change file name
    setImage(null);
    setErrors(null);
    const name = file.name.split(".");
    const ext = name[1];
    const filename = props.prodId + "-" + props.imageNb;
    const type = "image/" + ext;
    const blob = file.slice(0, file.size, type);
    let newFile = new File([blob], filename, {type: type});

    //check file type
    const fileType = newFile.type.split("/")[1];

    if (
      !(
        fileType === "webp" ||
        fileType === "png" ||
        fileType === "jpg" ||
        fileType === "jpeg"
      )
    ) {
      setIsLoading(false);
      setImage(image);
      window.alert("wrong file format");

      return;
    }
    newFile = await postCompressSingleImage({
      image: file,
      filename: filename,
    });
    // if (newFile.size > 200000) {
    //   newFile = await compressImage(newFile, filename);
    // }
    const im = URL.createObjectURL(newFile);
    setIsLoading(false);
    setImage(im);
    props.onChange(props.imageNb, newFile);
    return im;
  }

  function handleDelete() {
    setImage(addFile);
    dispatch(
      prodImageDeleted({
        ids: [props.prodId + "-" + props.imageNb],
        imFile: null,
      })
    );
    props.onDeleteImageFile(props.imageNb);

    if (props.imageNb === 0) {
      setErrors("Need a main image");
    }
  }

  return (
    <div
      className={Styles.frame}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {errors && <div className={Styles.error}>{errors}</div>}
      {!image && <div className={Styles.number}>{props.imageNb + 1}</div>}
      {!image && isLoading && <Loader />}
      {image && <img src={image} alt="" className={Styles.image} />}
      {image && image !== addFile && props.imageNb !== 0 && (
        <div className={Styles.delete} onClick={handleDelete}>
          x
        </div>
      )}
    </div>
  );
}
