import cloneDeep from "lodash/cloneDeep";
import findIndex from "lodash/findIndex";
import {getProductsByIds} from "../services";

export function moveProductFromCollection(origin, prod, collection) {
  //Origin
  const newColl = cloneDeep(collection);
  const prodCat = prod.category;
  const prodModel = prod.name;
  const prodId = prod._id;

  let collOrigin = newColl.database[origin];

  // //check if alone

  const catIndex_origin = findIndex(collOrigin, {name: prodCat});
  const varIndex_origin = findIndex(collOrigin[catIndex_origin].variants, {
    name: prodModel,
  });
  const prodIndex_origin =
    collOrigin[catIndex_origin].variants[varIndex_origin].products.indexOf(
      prodId
    );

  //remove product
  collOrigin[catIndex_origin].variants[varIndex_origin].products.splice(
    prodIndex_origin,
    1
  );

  //remove variant if void
  const var_length =
    collOrigin[catIndex_origin].variants[varIndex_origin].products.length;

  if (var_length === 0) {
    collOrigin[catIndex_origin].variants.splice(varIndex_origin, 1);
  }

  //remove category if void
  const cat_length = collOrigin[catIndex_origin].variants.length;
  if (cat_length === 0) {
    collOrigin.splice(catIndex_origin, 1);
  }
  //reset if coll void
  const coll_Length = collOrigin.length;
  if (coll_Length === 0) {
    collOrigin = [];
  }
  // // //return
  return collOrigin;
}

export function moveProductToCollection(target, prod, collection) {
  const newColl = cloneDeep(collection);

  const collTarget = newColl.database[target];
  const prodCat = prod.category;
  const prodModel = prod.name;
  const prodId = prod._id;

  //Target
  //check if has category
  const catIndex_target = findIndex(collTarget, {name: prodCat});
  if (catIndex_target === -1) {
    collTarget.push({
      name: prodCat,
      variants: [
        {
          name: prodModel,
          products: [prodId],
        },
      ],
    });
    return collTarget;
  } // else {
  //check if has variant
  const varIndex_target = findIndex(collTarget[catIndex_target].variants, {
    name: prodModel,
  });
  if (varIndex_target === -1) {
    collTarget[catIndex_target].variants.push({
      name: prodModel,
      products: [prodId],
    });
    return collTarget;
  }

  collTarget[catIndex_target].variants[varIndex_target].products.push(prodId);
  return collTarget;
}

export async function getAllproducts(collection) {
  const allprods = getAllproductsIds(collection);

  const allProdsComplete = await getProductsByIds(allprods);
  return allProdsComplete;
}
export function getAllproductsIds(collection) {
  const allprods = [];
  //valid
  if (collection.database.validProducts.length > 0)
    for (const cat of collection.database.validProducts) {
      for (const va of cat.variants) {
        for (const obj of va.products) {
          allprods.push(obj);
        }
      }
    }

  //invalid
  if (collection.database.invalidProducts.length > 0)
    for (const cat of collection.database.invalidProducts) {
      for (const va of cat.variants) {
        for (const obj of va.products) {
          allprods.push(obj);
        }
      }
    }

  return allprods;
}

export async function checkIfProductExists(collection, prod) {
  const allProds = await getAllproducts(collection);
  const index = findIndex(allProds, {
    category: prod.category,
    name: prod.name,
    color: prod.color,
  });
  if (index > -1) return true;
  return false;
}
