import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

import Select from "react-select";
import Async, {useAsync} from "react-select/async";
import AsyncSelect from "react-select/async";

import {read, utils} from "xlsx/dist/xlsx.mini.min.js";

import Styles from "./Styles";
import find from "lodash/find";
import findIndex from "lodash/findIndex";

import {readUserFile} from "../../../utils/importUsers";
import {
  createUsers,
  deleteUser,
  getAllCollections,
  getAllUsers,
  validateUser,
} from "../../../services";

import {Link, Navigate} from "react-router-dom";
import {Icon, RegularButton} from "../../../components";
import {Palette} from "../../../constants";

import {Navbar} from "../../../components";
import {UsersTable} from "./usersTable";
import {
  assignAllCollectionToUsers,
  assignCollectionToUsers,
  filterUsers,
  getFilterByCountries,
  getFiltersCollections,
  getFiltersDatas,
  removeAllCollectionToUsers,
} from "../../../utils/userUtils";
import {concat, filter} from "lodash";
import {exportUsersToExcel} from "../../../utils/excelExports";
import {
  getUserFilters,
  setFilters,
  resetFilters,
} from "../../../store/userFilters";
import {
  getUserTableColumn,
  setUserTableColumn,
} from "../../../store/userTableColumn";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterLock, setFilterLock] = useState("all");
  const [filterConfirmed, setFilterConfirmed] = useState("all");
  const [filterCountries, setFilterCountries] = useState([]);
  const [filterRole, setFilterRole] = useState("all");
  const [filterDivision, setFilterDivision] = useState("all");

  const [isCreateActive, setIsCreateActive] = useState(false);
  const [isModifyActive, setIsModifyActive] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);
  const selectColumnStart = [
    {value: "firstname", label: "First Name"},
    {value: "email", label: "Email"},
    {value: "role", label: "Role"},
    {value: "emailconfirmed", label: "Email confirmed"},
    {value: "confirmed", label: "Confirmed"},
    {value: "locked", label: "Locked"},
  ];
  const [selectedColumns, setSelectedColumns] = useState([]);
  const columnStart = {
    firstname: true,
    lastname: false,
    company: false,
    email: true,
    role: true,
    address: false,
    zipcode: false,
    country: false,
    phonenumber: false,
    emailconfirmed: true,
    confirmed: true,
    locked: true,
  };
  const [drawColumn, setDrawColumn] = useState([]);

  const [selectedCollections, setSelectedCollections] = useState([]);

  const [assignDisplay, setAssignDisplay] = useState(false);
  const [selectColumns, setSelectColumns] = useState(false);
  const [filtering, setFiltering] = useState(false);

  const inputFileUsers = useRef();

  const dispatch = useDispatch();
  const columnDefault = {
    firstname: false,
    lastname: false,
    email: false,
    role: false,
    address: false,
    zipcode: false,
    country: false,
    phonenumber: false,
    emailconfirmed: false,
    confirmed: false,
    locked: false,
    division: false,
  };
  const columnOptions = [
    {value: "firstname", label: "First Name"},
    {value: "lastname", label: "Last Name"},
    {value: "company", label: "Brand"},

    {value: "email", label: "Email"},
    {value: "role", label: "Role"},
    {value: "address", label: "Address"},
    {value: "zipcode", label: "Zip Code"},
    {value: "country", label: "Country"},
    {value: "phonenumber", label: "Phone number"},
    {value: "emailconfirmed", label: "Email confirmed"},
    {value: "confirmed", label: "Confirmed"},
    {value: "locked", label: "Locked"},
    {value: "division", label: "Division"},
  ];
  function handleColumnsChanged(selectedOption) {
    setSelectedColumns(selectedOption);
    const tempCol = {...columnDefault};
    for (const col of selectedOption) {
      tempCol[col.value] = true;
    }
    dispatch(
      setUserTableColumn({
        options: selectedOption,
        draw: tempCol,
      })
    );
    setDrawColumn(tempCol);
  }

  function handleDefaultColumns() {
    setSelectedColumns(selectColumnStart);
    setDrawColumn(columnStart);
    dispatch(
      setUserTableColumn({
        options: selectColumnStart,
        draw: columnStart,
      })
    );
  }
  let userFilters = useSelector(getUserFilters);
  let userTableColumns = useSelector(getUserTableColumn);

  async function loadOptions() {
    const res = await getAllUsers();
    const op = getFilterByCountries(res);
    return op;
  }
  async function loadCollections() {
    const coll = await getAllCollections();
    const op = getFiltersCollections(coll);
    return op;
  }
  function handleClearFilters() {
    dispatch(resetFilters());
    setFilterLock("all");
    setFilterConfirmed("all");
    setFilterCountries([]);
    setFilteredUsers(users);
    setFilterRole("all");
    setFilterDivision("all");
  }
  function handleCountryChanged(selectedOption) {
    setFilterCountries(selectedOption);
    const tempSelUsers = filterUsers(
      users,
      selectedOption,
      filterLock,
      filterConfirmed,
      filterRole,
      filterDivision
    );
    setFilterDispatch(
      selectedOption,
      filterLock,
      filterConfirmed,
      filterRole,
      filterDivision
    );

    setFilteredUsers(tempSelUsers);
  }
  function handleCollectionChanged(selectedOption) {
    setSelectedCollections(selectedOption);
  }
  function handleLockClick(dat) {
    let tempSelUsers = [];
    setFilterLock(dat);
    tempSelUsers = filterUsers(
      users,
      filterCountries,
      dat,
      filterConfirmed,
      filterRole,
      filterDivision
    );
    setFilterDispatch(
      filterCountries,
      dat,
      filterConfirmed,
      filterRole,
      filterDivision
    );

    setFilteredUsers(tempSelUsers);
  }
  function handleRoleChanged(dat) {
    let tempSelUsers = [];
    setFilterRole(dat);
    tempSelUsers = filterUsers(
      users,
      filterCountries,
      filterLock,
      filterConfirmed,
      dat,
      filterDivision
    );
    setFilterDispatch(
      filterCountries,
      filterLock,
      filterConfirmed,
      dat,
      filterDivision
    );

    setFilteredUsers(tempSelUsers);
  }
  function handleDivisionChanged(dat) {
    let tempSelUsers = [];
    setFilterDivision(dat);
    tempSelUsers = filterUsers(
      users,
      filterCountries,
      filterLock,
      filterConfirmed,
      filterRole,
      dat
    );
    setFilterDispatch(
      filterCountries,
      filterLock,
      filterConfirmed,
      filterRole,
      dat
    );

    setFilteredUsers(tempSelUsers);
  }

  function handleConfirmedChanged(dat) {
    let tempSelUsers = [];
    setFilterConfirmed(dat);
    tempSelUsers = filterUsers(
      users,
      filterCountries,
      filterLock,
      dat,
      filterRole,
      filterDivision
    );
    setFilterDispatch(
      filterCountries,
      filterLock,
      dat,
      filterRole,
      filterDivision
    );

    setFilteredUsers(tempSelUsers);
  }

  function setFilterDispatch(count, lock, conf, role, division) {
    dispatch(
      setFilters({
        filters: {
          countries: count,
          locked: lock,
          confirmed: conf,
          role: role,
          division: division,
        },
      })
    );
  }
  useEffect(() => {
    init();
  }, []);

  async function init() {
    console.log("inti");
    const res = await getAllUsers();
    setUsers(res);
    let tempSelUsers = [];

    if (userTableColumns[0] !== null) {
      setDrawColumn(userTableColumns[0].draw);
      setSelectedColumns(userTableColumns[0].options);
    } else {
      //handleDefaultColumns();
      setDrawColumn(columnStart);
      setSelectedColumns(selectColumnStart);
      setUserTableColumn({options: selectColumnStart, draw: columnStart});
    }

    if (userFilters[0] !== null) {
      setFilterLock(userFilters[0].locked);
      setFilterConfirmed(userFilters[0].confirmed);
      setFilterCountries(userFilters[0].countries);
      setFilterRole(userFilters[0].role);
      setFilterDivision(userFilters[0].division);
      tempSelUsers = filterUsers(
        res,
        userFilters[0].countries,
        userFilters[0].locked,
        userFilters[0].isConfirmed,
        userFilters[0].role,
        userFilters[0].division
      );

      setFilteredUsers(tempSelUsers);
    } else setFilteredUsers(res);
  }

  function handleCreate() {
    setIsCreateActive(true);
  }

  function handleModifyUser(_id) {
    const selUser = find(users, {_id});
    setSelectedUser(selUser);
    setIsModifyActive(true);
  }

  async function handleConfirm(id) {
    const tempUsers = [...users];
    const index = findIndex(tempUsers, {_id: id});
    tempUsers[index].isConfirmed = true;

    await validateUser(tempUsers[index]);
    setUsers(tempUsers);
  }
  function hanleExcelExport() {
    exportUsersToExcel(filteredUsers);
  }
  async function handleExcelImport(e) {
    var reader = new FileReader();

    reader.onload = function (e) {
      var data = e.target.result;
      var workbook = read(data, {
        type: "binary",
      });
      // setLoading(true);
      workbook.SheetNames.forEach(async function (sheetName) {
        // Here is your object
        var XL_row_object = utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName]
        );
        console.log("XL_row_object", XL_row_object);
        const test = await readUserFile(XL_row_object);

        console.log("test", test);

        const res = await createUsers(test);
        console.log("res", res);
        const temp_users = users.concat(res);
        console.log("temp_users", temp_users);
        setUsers(temp_users);
        setFilteredUsers(temp_users);
      });
    };

    reader.onerror = function (ex) {};

    reader.readAsBinaryString(e.target.files[0]);
  }
  function handleLoadUsers() {
    inputFileUsers.current.click();
  }
  function handleAssign(assign) {
    if (selectedCollections.length === 0) {
      window.alert("No collection selected");
      return;
      //end
    }
    assignCollectionToUsers(selectedCollections, filteredUsers, assign);
    setSelectedCollections([]);
  }
  function handleAssignAll(assign) {
    if (assign) assignAllCollectionToUsers(null, filteredUsers, assign);
    else removeAllCollectionToUsers(selectedCollections, filteredUsers, assign);
    setSelectedCollections([]);
  }
  function handleAssignDisplay() {
    setAssignDisplay(!assignDisplay);
  }
  function handleSelectColumns() {
    setSelectColumns(!selectColumns);
  }
  function handleFiltering() {
    setFiltering(!filtering);
  }
  return (
    <div className={Styles.frame}>
      <Navbar />
      <div className={Styles.subFrame}>
        <div className={Styles.margin}></div>
        <div className={Styles.navigation}>
          <Link className={Styles.navItem} to="/Home">
            Home
          </Link>
          <Icon
            icon="arrowheadright"
            color={Palette.col_mediumGrey}
            hcolor={Palette.col_mediumGrey}
            size="1.3rem"
          />
          <Link className={Styles.navItem} to="/Admin">
            Admin
          </Link>
          <Icon
            icon="arrowheadright"
            color={Palette.col_mediumGrey}
            hcolor={Palette.col_mediumGrey}
            size="1.3rem"
          />
          <div className={Styles.navItem} to="/Users">
            Users
          </div>
        </div>
        <div className={Styles.menuBar}>
          <div className={Styles.title}>Users</div>

          <RegularButton
            white
            name="export"
            ml="auto"
            onClick={hanleExcelExport}
          />
          <RegularButton
            white
            name="import"
            ml="0.5rem"
            onClick={handleLoadUsers}
          />
          <input
            ref={inputFileUsers}
            type="file"
            name="file"
            id="file-upload-users"
            onChange={handleExcelImport}
            value=""
            style={{display: "none"}}
          />
          <RegularButton
            white
            name="create"
            ml="0.5rem"
            onClick={handleCreate}
          />
        </div>
        <div className={Styles.headerContainer}>
          <div className={Styles.subTitle}>
            <div onClick={handleFiltering} className={Styles.subTitleBtn}>
              Filtering
            </div>
            <RegularButton
              white
              name="clear"
              ml="auto"
              onClick={handleClearFilters}
            />
          </div>
          {filtering && (
            <div className={Styles.filtersContainer}>
              <div>Countries:</div>
              <AsyncSelect
                isMulti={true}
                defaultOptions={true}
                loadOptions={loadOptions}
                touchUi={false}
                onChange={handleCountryChanged}
                value={filterCountries}
              />
              <div className={Styles.filterBtnContainer}>
                <div
                  className={
                    filterLock === "all"
                      ? Styles.filterBtnLeftOn
                      : Styles.filterBtnLeft
                  }
                  onClick={() => handleLockClick("all")}
                >
                  all
                </div>
                <div
                  className={
                    filterLock === "locked"
                      ? Styles.filterBtnMiddleOn
                      : Styles.filterBtnMiddle
                  }
                  onClick={() => handleLockClick("locked")}
                >
                  Locked
                </div>
                <div
                  className={
                    filterLock === "unlocked"
                      ? Styles.filterBtnRightOn
                      : Styles.filterBtnRight
                  }
                  onClick={() => handleLockClick("unlocked")}
                >
                  Unclocked
                </div>
              </div>
              <div className={Styles.filterBtnContainer}>
                <div
                  className={
                    filterConfirmed === "all"
                      ? Styles.filterBtnLeftOn
                      : Styles.filterBtnLeft
                  }
                  onClick={() => handleConfirmedChanged("all")}
                >
                  all
                </div>
                <div
                  className={
                    filterConfirmed === "confirmed"
                      ? Styles.filterBtnMiddleOn
                      : Styles.filterBtnMiddle
                  }
                  onClick={() => handleConfirmedChanged("confirmed")}
                >
                  confirmed
                </div>
                <div
                  className={
                    filterConfirmed === "not confirmed"
                      ? Styles.filterBtnRightOn
                      : Styles.filterBtnRight
                  }
                  onClick={() => handleConfirmedChanged("notconfirmed")}
                >
                  not confirmed
                </div>
              </div>
              <div className={Styles.filterBtnContainer}>
                <div
                  className={
                    filterRole === "all"
                      ? Styles.filterBtnLeftOn
                      : Styles.filterBtnLeft
                  }
                  onClick={() => handleRoleChanged("all")}
                >
                  all
                </div>
                <div
                  className={
                    filterRole === "admin"
                      ? Styles.filterBtnMiddleOn
                      : Styles.filterBtnMiddle
                  }
                  onClick={() => handleRoleChanged("admin")}
                >
                  admin
                </div>
                <div
                  className={
                    filterRole === "custo"
                      ? Styles.filterBtnMiddleOn
                      : Styles.filterBtnMiddle
                  }
                  onClick={() => handleRoleChanged("custo")}
                >
                  visitor
                </div>
                <div
                  className={
                    filterRole === "creat"
                      ? Styles.filterBtnRightOn
                      : Styles.filterBtnRight
                  }
                  onClick={() => handleRoleChanged("creat")}
                >
                  brand
                </div>
              </div>
              <div className={Styles.filterBtnContainer}>
                <div
                  className={
                    filterDivision === "all"
                      ? Styles.filterBtnLeftOn
                      : Styles.filterBtnLeft
                  }
                  onClick={() => handleDivisionChanged("all")}
                >
                  all
                </div>
                <div
                  className={
                    filterDivision === "menswear"
                      ? Styles.filterBtnMiddleOn
                      : Styles.filterBtnMiddle
                  }
                  onClick={() => handleDivisionChanged("menswear")}
                >
                  menswear
                </div>
                <div
                  className={
                    filterDivision === "womenswear"
                      ? Styles.filterBtnMiddleOn
                      : Styles.filterBtnMiddle
                  }
                  onClick={() => handleDivisionChanged("womenswear")}
                >
                  womenswear
                </div>
                <div
                  className={
                    filterDivision === "accessories"
                      ? Styles.filterBtnRightOn
                      : Styles.filterBtnRight
                  }
                  onClick={() => handleDivisionChanged("accessories")}
                >
                  accessories
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={Styles.subTitle}>
          <div onClick={handleSelectColumns} className={Styles.subTitleBtn}>
            Select Columns
          </div>
          <RegularButton
            white
            name="default"
            ml="auto"
            onClick={handleDefaultColumns}
          />
        </div>
        {selectColumns && (
          <div className={Styles.filtersContainer}>
            <Select
              isClearable={false}
              isMulti={true}
              defaultOptions={true}
              options={columnOptions}
              touchUi={false}
              onChange={handleColumnsChanged}
              value={selectedColumns}
            />
          </div>
        )}
        <div className={Styles.subTitle}>
          <div onClick={handleAssignDisplay} className={Styles.subTitleBtn}>
            Assign collections to users
          </div>
        </div>
        {assignDisplay && (
          <div className={Styles.filtersContainer}>
            <AsyncSelect
              isMulti={true}
              defaultOptions={true}
              loadOptions={loadCollections}
              touchUi={false}
              onChange={handleCollectionChanged}
              value={selectedCollections}
            />
            <RegularButton
              white
              name="assign"
              ml="auto"
              onClick={() => handleAssign(true)}
              isdisabled={selectedCollections.length === 0}
            />
            <RegularButton
              white
              name="unassign"
              ml="0.5rem"
              onClick={() => handleAssign(false)}
              isdisabled={selectedCollections.length === 0}
            />
            <RegularButton
              white
              name="unassign all"
              width="8rem"
              ml="0.5rem"
              backHColor="red"
              onClick={() => handleAssignAll(false)}
            />
            <RegularButton
              white
              name="assign all"
              width="8rem"
              ml="0.5rem"
              backHColor="red"
              onClick={() => handleAssignAll(true)}
            />
          </div>
        )}
        <div className={Styles.hMargin}></div>
        <UsersTable
          datas={filteredUsers}
          onModifyUser={handleModifyUser}
          onConfirm={handleConfirm}
          columns={drawColumn}
        />
        {isCreateActive && <Navigate push to={"/Admin/Users/Modify/0"} />}
        {isModifyActive && (
          <Navigate push to={"/Admin/Users/Modify/" + selectedUser._id} />
        )}
      </div>
    </div>
  );
}
