import React from "react";

import {Palette} from "../../constants";
import Styles from "./Styles";

export default function Loader() {
  return (
    <div className={Styles.frame}>
      <div>
        <div className={Styles.loader}>
          <div className={Styles.inner}></div>
        </div>
      </div>
      <div className={Styles.message}>loading</div>
    </div>
  );
}
