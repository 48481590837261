import {Navigate} from "react-router";

import React, {useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import Joi from "joi";
import validate from "react-joi-validation";

import {countriesList} from "../../../constants/countries";

import Styles from "./Styles";
import logo from "../../../assets/images/logo.png";

import {registerUser} from "../../../services";

export default function Register() {
  const [submitEnable, setSubmitEnable] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [errors, setErrors] = useState({
    company: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    email: "",
  });
  const [user, setUser] = useState({
    company: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    email: "",
    divisionmenswear: false,
    divisionwomenswear: false,
    divisionaccessories: false,
    address: "",
    zipcode: "",
    city: "",
    country: "",
    phonenumber: "",
    mobilenumber: "",
  });

  useEffect(() => {
    let usr = {
      company: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      email: "",
      divisionmenswear: false,
      divisionwomenswear: false,
      divisionaccessories: false,
      address: "",
      zipcode: "",
      city: "",
      country: "",
      phonenumber: "",
      mobilenumber: "",
    };
    setUser(usr);
  }, []);

  const schemaUser = Joi.object({
    company: Joi.string().required(),
    firstName: Joi.string().min(3).max(20).required(),
    lastName: Joi.string().min(3).max(20).allow(""),
    password: Joi.string().min(5),
    confirmPassword: Joi.any()
      .valid(Joi.ref("password"))
      .required()
      .label("Confirm password")
      .messages({"any.only": "{{#label}} does not match"}),
    email: Joi.string()
      .min(5)
      .max(255)
      .email({tlds: {allow: false}})
      .required(),
    divisionmenswear: Joi.bool(),
    divisionwomenswear: Joi.bool(),
    divisionaccessories: Joi.bool(),
    address: Joi.string().allow(""),
    zipcode: Joi.string().allow(""),
    city: Joi.string().allow(""),
    country: Joi.string().allow(""),
    phonenumber: Joi.string().allow(""),
    mobilenumber: Joi.string().allow(""),
  }).options({abortEarly: false});

  function validate(datas) {
    setErrors(null);
    const result = schemaUser.validate(datas);
    let err = {};
    //division
    if (
      !datas.divisionmenswear &&
      !datas.divisionwomenswear &&
      !datas.divisionaccessories
    ) {
      err["division"] = "need to select at least one division";
    }

    if (result.error || err.division) {
      // err = {...errors};
      if (result.error)
        for (const el of result.error.details) {
          err[el.path] = el.message;
        }
      setSubmitEnable(false);
    } else setSubmitEnable(true);
    setErrors({...err});
  }
  if (redirectToHome) {
    return <Navigate push to={"/Home"} />;
  }
  function handleRedirectLogin() {
    setRedirectToHome(true);
  }
  function handleLogChange(e) {
    const name = e.target.name;
    const val = e.target.value;

    const tempUser = {...user};
    tempUser[name] = val;
    setUser({...tempUser});
    validate(tempUser);
  }
  function handleCheckChange(e) {
    const name = e.target.name;
    const val = e.target.checked;
    const tempUser = {...user};
    tempUser[name] = val;
    setUser({...tempUser});
    validate(tempUser);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (submitEnable) {
      const data = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        company: user.company,
        password: user.password,
        divisionmenswear: user.divisionmenswear,
        divisionwomenswear: user.divisionwomenswear,
        divisionaccessories: user.divisionaccessories,
        address: user.address,
        zipcode: user.zipcode,
        city: user.city,
        country: user.country,
        phonenumber: user.phonenumber,
        mobilenumber: user.mobilenumber,
      };

      const res = await registerUser(data);
      setRegistered(res);
    }
  }

  return (
    <div>
      {!registered && (
        <form className={Styles.frame} onSubmit={handleSubmit}>
          <div className={Styles.banner}>
            <img className={Styles.logo} src={logo} alt="" />
          </div>
          <div className={Styles.title}>REGISTER</div>
          <div className={Styles.inner}>
            <div className={Styles.inputsBlock}>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputLabel}>first name</div>
                <input
                  className={Styles.input}
                  onChange={handleLogChange}
                  type="text"
                  name="firstName"
                  placeholder="firstName - REQUIRED"
                  value={user.firstName}
                />
                <div className={Styles.error}>{errors.firstName}</div>
              </div>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputLabel}>last name</div>
                <input
                  className={Styles.input}
                  onChange={handleLogChange}
                  type="text"
                  name="lastName"
                  placeholder="lastName - optional"
                  value={user.lastName}
                />
                <div className={Styles.error}>{errors.lastName}</div>
              </div>
            </div>
            <div className={Styles.inputsBlock}>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputLabel}>email</div>
                <input
                  className={Styles.input}
                  onChange={handleLogChange}
                  placeholder="example@example.com  - REQUIRED"
                  type="text"
                  name="email"
                  value={user.email}
                />
                <div className={Styles.error}>{errors.email}</div>
              </div>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputLabel}>company</div>
                <input
                  className={Styles.input}
                  onChange={handleLogChange}
                  type="text"
                  name="company"
                  placeholder="company - REQUIRED"
                  value={user.company}
                />
                <div className={Styles.error}>{errors.company}</div>
              </div>
            </div>
            <div className={Styles.inputsBlock}>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputLabel}>password</div>
                <input
                  className={Styles.input}
                  onChange={handleLogChange}
                  type="password"
                  name="password"
                  placeholder="password - REQUIRED"
                  value={user.password}
                />
                <div className={Styles.error}>{errors.password}</div>
              </div>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputLabel}>confirm password</div>
                <input
                  className={Styles.input}
                  onChange={handleLogChange}
                  type="password"
                  name="confirmPassword"
                  placeholder="confirm password - REQUIRED"
                  value={user.confirmPassword}
                />
                <div className={Styles.error}>{errors.confirmPassword}</div>
              </div>
            </div>
            <div className={Styles.inputsBlock}>
              <div className={Styles.inputContainerLarge}>
                <div className={Styles.inputLabel}>division</div>
                <div className={Styles.checkContainer}>
                  <div className={Styles.checkSub}>
                    <input
                      type="checkbox"
                      id="menswear"
                      name="divisionmenswear"
                      onChange={handleCheckChange}
                    />
                    <label for="menswear">menswear</label>
                  </div>
                  <div className={Styles.checkSub}>
                    <input
                      type="checkbox"
                      id="womenswear"
                      name="divisionwomenswear"
                      onChange={handleCheckChange}
                    />
                    <label for="womenswear">womenswear</label>
                  </div>
                  <div className={Styles.checkSub}>
                    <input
                      type="checkbox"
                      id="accessories"
                      name="divisionaccessories"
                      onChange={handleCheckChange}
                    />
                    <label for="accessories">accessories</label>
                  </div>
                </div>
                <div className={Styles.error}>{errors.division}</div>
              </div>
            </div>
            <div className={Styles.inputsBlock}>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputLabel}>street</div>
                <input
                  className={Styles.input}
                  onChange={handleLogChange}
                  placeholder=" 23 Acacia Avenue - optional"
                  type="text"
                  name="address"
                  value={user.address}
                />
                <div className={Styles.error}></div>
              </div>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputLabel}>city</div>
                <input
                  className={Styles.input}
                  onChange={handleLogChange}
                  type="text"
                  name="city"
                  placeholder="city - optional"
                  value={user.city}
                />
                <div className={Styles.error}></div>
              </div>
            </div>
            <div className={Styles.inputsBlock}>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputLabel}>zip code</div>
                <input
                  className={Styles.input}
                  onChange={handleLogChange}
                  placeholder="TW6 2GA - optional"
                  type="text"
                  name="zipcode"
                  value={user.zipcode}
                />
                <div className={Styles.error}></div>
              </div>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputLabel}>country</div>
                <select
                  className={Styles.countrySelect}
                  //defaultValue="none"
                  name="country"
                  //value={modifiedUser["country"]}
                  onChange={handleLogChange}
                >
                  {countriesList.map((country, index) => (
                    <option value={country.name}>{country.name}</option>
                  ))}
                </select>
                <div className={Styles.error}></div>
              </div>
            </div>
            <div className={Styles.inputsBlock}>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputLabel}>phone number</div>
                <input
                  className={Styles.input}
                  onChange={handleLogChange}
                  placeholder="+34256362 - optional"
                  type="text"
                  name="phonenumber"
                  value={user.phonenumber}
                />
                <div className={Styles.error}></div>
              </div>
              <div className={Styles.inputContainer}>
                <div className={Styles.inputLabel}>mobile number</div>
                <input
                  className={Styles.input}
                  onChange={handleLogChange}
                  type="text"
                  name="mobilenumber"
                  placeholder="+3456532566 - optional"
                  value={user.mobilenumber}
                />
                <div className={Styles.error}></div>
              </div>
            </div>
            <div>
              <input className={Styles.btn} type="submit" value="register" />
              <div className={Styles.linkContainer}>
                <Link
                  to="/Home"
                  className={Styles.link}
                  onClick={handleRedirectLogin}
                >
                  back to login
                </Link>
              </div>
            </div>
          </div>
        </form>
      )}

      {registered && (
        <div className={Styles.frame} onSubmit={handleSubmit}>
          <div className={Styles.banner}>
            <img className={Styles.logo} src={logo} alt="" />
          </div>
          <div className={Styles.title}>{"Hello " + user.firstName}</div>
          <div className={Styles.textContainer}>
            <div className={Styles.line}>
              You have been succefully registered
            </div>
            <div className={Styles.line}>
              {"An email verification message has been sent to " + user.email}
            </div>

            <a
              className={Styles.lineLink}
              rel="noreferrer"
              href="https://www.noseason-showroom.com"
            >
              go to noseason website
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
