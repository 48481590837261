import {css} from "@emotion/css";
import {Palette} from "../../constants";
import {bck_coll} from "../../assets/images";

const Styles = {
  frame: css({
    minHeight: "100vh",
    width: "100vw",
    backgroundRepeat: "no-repeat",
    backgroundSize: "  100% auto",
    //backgroundColor: "red",
    backgroundPosition: "center center",
    // backgroundImage: "url(" + bck_coll + ")",
    filter: "grayscale(70%) brightness(0.9) saturate(150%)",

    // TODO: ASPECT RATIO
    "@media(max-width: 570px)": {
      backgroundSize: "auto 100%  ",
    },
  }),
  margin: css({
    height: "50px",
  }),
  innerFrame: css({
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 50px)",
    fontSize: "5rem",
    marginTop: "5%",
  }),
  headerContainer: css({
    marginTop: "2rem",
    marginBottom: "3rem",
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    alignItems: "center",
  }),
  header: css({
    width: "20rem",
    textAlign: "center",
    fontSize: "2.5rem",

    backgroundColor: "rgb(0,0,0,0.5)",
    border: "1px solid white",
    padding: "1rem 0",

    color: Palette.col_white,
  }),
  btn: css({
    //textStroke: "5px red",
    // textShadow:
    //   "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "2.5rem",
    width: "20rem",
    textAlign: "center",
    height: "5rem",
    lineHeight: "5rem",
    backgroundColor: "rgb(0,0,0,0.2)",
    color: Palette.col_white,
    marginBottom: "1rem",
    textDecoration: "none",
    cursor: "pointer",
    userSelect: "false",
    "&:hover": {
      //backgroundColor: Palette.col_white,
      color: Palette.col_xlightGrey,
      // textDecoration: "underline",
    },
  }),
};

export default Styles;
