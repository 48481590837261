import React, {useState, useEffect} from "react";
import jwt_decode from "jwt-decode";
//import {Helmet} from "react-helmet";

import {Main} from "./pages";

import {UserContext} from "./contexts";
import {CollectionsContext} from "./contexts";
import {Provider} from "react-redux";
import configureAppStore from "./store/configureStore";

export default function App() {
  const [user, setUser] = useState(
    localStorage.getItem("token") === null
      ? null
      : jwt_decode(localStorage.getItem("token"))
  );
  const [collections, setCollections] = useState({
    onlineProducts: [],
    validProducts: [],
    invalidProducts: [],
  });
  const store = configureAppStore();
  function updateUser(obj) {
    setUser(obj);
  }
  function updateCollections(obj) {
    setCollections(obj);
  }

  useEffect(() => {
    const usr =
      localStorage.getItem("token") === null
        ? null
        : jwt_decode(localStorage.getItem("token"));
    console.log("user", user);
    setUser(usr);
  }, []);

  return (
    <UserContext.Provider value={{user, updateUser}}>
      <CollectionsContext.Provider value={{collections, updateCollections}}>
        <Provider store={store}>
          <Main />
        </Provider>
      </CollectionsContext.Provider>
    </UserContext.Provider>
  );
}
