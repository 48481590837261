import {css} from "@emotion/css";
import {Palette} from "../../../../constants";

const btnLine = css({
  fontSize: "0.8rem",
  textAlign: "center",
  width: "4rem",
  height: "1rem",
  lineHeight: "1rem",
  border: "1px solid " + Palette.col_mediumGrey,
  userSelect: "none",
  cursor: "pointer",
});

const Styles = {
  btnView: css({
    marginLeft: "auto",
    paddingRight: "0.5rem",
    display: "flex",
    justifyContent: "flex-end",
    gap: 5,
    //width: "2.5rem",
  }),

  barTitle: css({
    backgroundColor: Palette.col_darkGrey,
    color: Palette.col_white,
    display: "flex",
    alignItems: "center",
    height: "2rem",
  }),
  barTitleText: css({
    paddingLeft: "0.5rem",
  }),
  frame: css({
    marginTop: "-1rem",
    width: "90vw",
    //height: "80vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }),
  btnContainer: css({
    display: "flex",
    alignItems: "center",
    //borderBottom: "1px solid " + Palette.col_lightMediumGrey,
    marginBottom: "0.5rem",
  }),
  topSubContainer: css({
    display: "flex",
  }),
  subContainer: css({
    width: "90vw",
    //display: "grid",
    display: "flex",
    flexWrap: "wrap",
    border: "1px solid " + Palette.col_mediumGrey,
  }),

  leftSubContainer: css({
    minWidth: "15rem",
    flexBasis: "50%",
    flexGrow: 1,
    flexShrink: 1,
    display: "flex",
    width: "50vw",
    flexWrap: "wrap",
    //flexDirection: "column",
    //justifyContent: "center",
  }),
  rightSubContainer: css({
    minWidth: "15rem",
    flexBasis: "50%",
    flexGrow: 1,
    flexShrink: 1,
    display: "flex",
    flexDirection: "column",
    width: "50vw",

    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Palette.col_xxlightGrey,
  }),
  inputBlock: css({
    //width: "95%",
    minWidth: "10rem",
    flexBasis: "100%",
    flexGrow: 1,
    flexShrink: 1,
    margin: "0 0.5rem",
  }),
  inputTitle: css({
    color: Palette.col_mediumGrey,
    width: "100%",
    paddingBottom: "0.1rem",
    margin: "0.5rem 0 0.2rem 0",
    borderBottom: "1px solid " + Palette.col_xlightGrey,
  }),
  inputTitleCell: css({
    width: "89%",
  }),
  text: css({
    color: Palette.col_darkGrey,
    height: "1rem",
    margin: "0 0 0.3rem 0",
  }),
  select: css({
    width: "90%",
  }),
  input: css({
    width: "90%",
    color: Palette.col_darkGrey,
    margin: "0 0 0.3rem 0",
  }),
  inputContainer: css({
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  }),
  btnLineOff: css(btnLine, {
    color: Palette.col_mediumGrey,
    "&:hover": {
      backgroundColor: Palette.col_darkGrey,
      color: Palette.col_white,
    },
  }),
  btnLineOn: css(btnLine, {
    backgroundColor: Palette.col_darkGrey,
    color: Palette.col_white,
    "&:hover": {
      backgroundColor: Palette.col_mediumGrey,
      color: Palette.col_white,
    },
  }),
  errors: css({
    color: "red",
    fontSize: "0.8rem",
    lineHeight: "0.8rem",
    height: "0.8rem",
  }),

  mainImage: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
    height: "30vh",
    width: "30vh",
    backgroundColor: Palette.col_mediumGrey,
  }),
  rightInputs: css({
    // width: "20rem",
    width: "100%",
    marginLeft: "2rem",
    display: "flex",
    flexDirection: "column",
  }),
  actionContainer: css({
    width: "50vw",
    display: "flex",
    backgroundColor: "blue",
    height: "2rem",
  }),
};

export default Styles;
