export function authUser(user) {
  if (!user) return false;

  if (user.emailConfirmed && !user.isLocked) return true;

  return false;
}

export function authAdmin(user) {
  if (!user) return false;
  if (
    user.emailConfirmed &&
    user.isConfirmed &&
    !user.isLocked &&
    user.role === "admin"
  )
    return true;

  return false;
}
export function authCreator(user) {
  if (!user) return false;
  if (
    user.emailConfirmed &&
    user.isConfirmed &&
    !user.isLocked &&
    (user.role === "admin" || user.role === "creat")
  )
    return true;

  return false;
}

export function authAdminOrCreator(user) {
  return authCreator(user) || authAdmin(user);
}
