import React, {useState, useEffect} from "react";
import {getStyles} from "./Styles";

import {getCollectionImageById, postCompressSingleImage} from "../../services";

import {Loader} from "../Loader";

//import {CollectionsContext, CollectionsImagesContext} from "../../contexts";

import addFile from "../../assetsIcons/icons/add_file.png";
import {ProdStatusContext} from "../../contexts";
export default function ImageBlock(props) {
  const Styles = getStyles(props);

  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    init();
  }, [props.image]);

  async function init() {
    let imSource = null;

    if (props.image) {
      imSource = props.image;
    }
    if (imSource == null) {
      setImage(addFile);

      setErrors("Need a main image");

      return;
    }

    if (typeof imSource === "string") {
      let cache = true;
      if (props.nocache === true) cache = false;
      const im = await getCollectionImageById(imSource, cache);
      const final = URL.createObjectURL(im);
      setErrors(null);
      setImage(final);
      return;
    }
    if (typeof imSource === "object") {
      const final = URL.createObjectURL(imSource);
      setErrors(null);
      setImage(final);
      return;
    }
    setImage(addFile);
    if (props.imageNb === 0) {
      setErrors("Need a main image");
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    //if (props.locked) return;
    let file = [...e.dataTransfer.files];

    if (file[0]) {
      setIsLoading(true);
      readUrlFromFile(file[0]);
    }
  }
  async function readUrlFromFile(file) {
    //change file name
    setImage(null);
    setErrors(null);
    const name = file.name.split(".");
    const ext = name[1];
    const filename = props.collId + "-IMAGE";
    const type = "image/" + ext;
    const blob = file.slice(0, file.size, type);
    let newFile = new File([blob], filename, {type: type});

    //check file type
    const fileType = newFile.type.split("/")[1];

    if (
      !(
        fileType === "webp" ||
        fileType === "png" ||
        fileType === "jpg" ||
        fileType === "jpeg"
      )
    ) {
      setIsLoading(false);
      setImage(image);
      window.alert("wrong file format");

      return;
    }
    try {
      newFile = await postCompressSingleImage({
        image: file,
        filename: filename,
      });
    } catch (error) {
      window.alert("error");
    }

    if (newFile != null) {
      const im = URL.createObjectURL(newFile);
      setIsLoading(false);
      setImage(im);
      props.onChange(props.imageNb, newFile);
    } else {
      setIsLoading(false);
      setImage(image);
    }
  }

  return (
    <div
      className={Styles.frame}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {errors && <div className={Styles.error}>{errors}</div>}
      {!image && isLoading && <Loader />}
      {image && <img src={image} alt="" className={Styles.image} />}
    </div>
  );
}
