import React from "react";
import {Link} from "react-router-dom";
import {Icon} from "../../components";
import {Navbar} from "../../components";
import Styles from "./Styles";
import {Palette} from "../../constants";
export default function AdminHome() {
  return (
    <div className={Styles.frame}>
      <Navbar />
      <div className={Styles.margin}></div>
      <div className={Styles.innerFrame}>
        <div className={Styles.navigation}>
          <Link className={Styles.navItem} to="/Home">
            Home
          </Link>
          <Icon
            icon="arrowheadright"
            color={Palette.col_darkGrey}
            hcolor={Palette.col_darkGrey}
            size="1.3rem"
          />
          <div className={Styles.navItem}>Administration</div>
        </div>
        <div className={Styles.title}>ADMINISTRATION</div>
        <div className={Styles.btnContainer}>
          <div>
            <Link className={Styles.btn} to="/Admin/Users">
              <Icon
                icon="manageUsers"
                color={Palette.col_darkGrey}
                hcolor={Palette.col_mediumGrey}
                size="80px"
              />
              <div className={Styles.btnText}>Users</div>
            </Link>
          </div>
          <div>
            <Link className={Styles.btn} to="/Admin/Collections">
              <Icon
                icon="manageProducts"
                color={Palette.col_darkGrey}
                hcolor={Palette.col_mediumGrey}
                size="80px"
              />
              <div className={Styles.btnText}>Collections</div>
            </Link>
          </div>
          <div>
            <Link className={Styles.btn} to="/Admin/Pages">
              <Icon
                icon="managePages"
                color={Palette.col_darkGrey}
                hcolor={Palette.col_mediumGrey}
                size="80px"
              />
              <div className={Styles.btnText}>Pages</div>
            </Link>
          </div>
          <div>
            <Link className={Styles.btn} to="/Admin/Images">
              <Icon
                icon="addfile"
                color={Palette.col_darkGrey}
                hcolor={Palette.col_mediumGrey}
                size="80px"
              />
              <div className={Styles.btnText}>Images</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
