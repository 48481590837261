import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Link} from "react-router-dom";
import Joi from "joi/dist/joi-browser.min.js";

import {changePassword} from "../../../services";
import Styles from "../register/Styles";
import logo from "../../../assets/images/logo.png";
export default function ChangePassword() {
  const {token} = useParams();

  const [redirect, setRedirect] = useState(false);
  const [errors, setErrors] = useState({});
  const [datas, setDatas] = useState({password: "", confirmPassword: ""});
  const [submitEnable, setSubmitEnable] = useState(false);

  const schemaUser = Joi.object({
    password: Joi.string()
      .min(5)

      .required(),
    confirmPassword: Joi.string()
      .min(5)
      .valid(Joi.ref("password"))
      .required()
      .label("Confirm password")
      .messages({"any.only": "{{#label}} does not match"}),
  }).options({abortEarly: false});

  function validate(datas) {
    const result = schemaUser.validate(datas);
    let err = {};
    if (result.error) {
      // err = {...errors};
      for (const el of result.error.details) {
        err[el.path] = el.message;
      }
      setSubmitEnable(false);
    } else {
      setSubmitEnable(true);
    }
    setErrors({...err});
  }
  async function handleSubmit(e) {
    e.preventDefault();
    if (submitEnable) {
      const res = await changePassword({token, password: datas.password});
    }
  }
  function handleLogChange(e) {
    const name = e.target.name;
    const val = e.target.value;

    const tempDatas = {...datas};
    tempDatas[name] = val;

    setDatas({...tempDatas});
    validate(tempDatas);
  }
  function handleRedirectLogin(params) {}
  return (
    <div className={Styles.frame} onSubmit={handleSubmit}>
      <div className={Styles.banner}>
        <img className={Styles.logo} src={logo} alt="" />
      </div>
      <div className={Styles.title}>{"Hello "}</div>
      <form className={Styles.textContainer}>
        <div className={Styles.inputContainer}>
          <div className={Styles.inputLabel}>New password</div>
          <input
            className={Styles.input}
            onChange={handleLogChange}
            type="password"
            name="password"
            placeholder="password"
            value={datas.password}
          />
          <div className={Styles.error}>{errors.password}</div>
        </div>
        <div className={Styles.inputContainer}>
          <div className={Styles.inputLabel}>Confirm password</div>
          <input
            className={Styles.input}
            onChange={handleLogChange}
            type="password"
            name="confirmPassword"
            placeholder="confirmPassword"
            value={datas.confirmPassword}
          />
          <div className={Styles.error}>{errors.confirmPassword}</div>
        </div>
        <input className={Styles.btn} type="submit" value="register" />
      </form>
      <div className={Styles.linkContainer}>
        <Link to="/Home" className={Styles.link} onClick={handleRedirectLogin}>
          back to login
        </Link>
      </div>
    </div>
  );
}
