import React, {useState, useEffect} from "react";

import {Icon} from "../../../../../components";
import {Palette} from "../../../../../constants";
import Styles from "./Styles";

import {capitalizeFirstLetter} from "../../../../../utils/capitalizeFirstLetter";

export default function CollectionsBlock(props) {
  const [colType, setColType] = useState(
    props.collections.collections[props.type]
  );
  const [modify, setModify] = useState(props.modify);
  const [isUnlocked, setIsUnlocked] = useState(
    props.collections.collections[props.type].isUnlocked
  );

  useEffect(() => {
    setIsUnlocked(props.collections.collections[props.type].isUnlocked);
    setModify(props.modify);
  }, [props.modify, props.collections]);

  function handleLock(type) {
    if (!props.modify) return;
    let res = false;
    if (isUnlocked) {
      res = window.confirm(`Are you sure you want to lock this collection?
       The brand will cannot access it anymore.`);
    } else {
      res = window.confirm(`Are you sure you want to unlock this collection?
        The brand will now have access to it.`);
    }
    if (res) {
      props.onChange(type);
    }
  }

  return (
    <div className={Styles.collectionBlock}>
      {!colType.name && (
        <Icon icon="lock" size="1rem" color={Palette.col_xlightGrey} />
      )}
      {colType.name && (
        <Icon
          icon={isUnlocked ? "unlock" : "lock"}
          size="1rem"
          color={
            // modify ? (isUnlocked ? "green" : "red") : Palette.col_lightGrey
            isUnlocked ? "green" : "red"
          }
          hcolor={modify ? Palette.col_orange : Palette.col_xlightGrey}
          onClick={() => handleLock(props.type)}
        />
      )}
      <div className={Styles.collectionLabel}>
        {capitalizeFirstLetter(props.type) + ":"}
      </div>
      <div className={Styles.collectionValue}>
        {colType.name ? (
          colType.name
        ) : (
          <div>
            <i>No collection</i>
          </div>
        )}
      </div>
    </div>
  );
}
