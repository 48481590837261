import {css, keyframes} from "@emotion/css";
import {Palette} from "../../constants";

const animate = keyframes`
from {transform: rotate(0deg)}
to {transform: rotate(360deg)}`;

export function getStyles(data) {
  const Styles = {
    frame: css({
      width: data.size,
      height: data.size,
      backgroundColor: Palette.col_lightMediumGrey,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    // imagePortrait: css({
    //   height: data.size,
    //   width: "auto",
    // }),
    // imageLandscape: css({
    //   height: "auto",
    //   width: data.size,
    // }),
    error: css({
      position: "absolute",
      color: "red",
    }),
    image: css({
      width: data.size,
      height: data.size,
      objectFit: "contain",
    }),
  };
  return Styles;
}
