import React, {useState, useEffect, useContext} from "react";
import {Icon, LoaderWin, Navbar, RegularButton} from "../../../components";
import {Link, useParams} from "react-router-dom";

import Styles from "./Styles";
import {Palette} from "../../../constants";

import {UserContext} from "../../../contexts";

import im1 from "../../../aaTempPix/coll1.jpg";
import im2 from "../../../aaTempPix/coll2.jpg";
import im3 from "../../../aaTempPix/coll3.jpg";
import {
  deleteImage,
  findProductsByCollectionName,
  getAllCollections,
  getCollectionById,
  getStaticImage,
  getStaticImages,
  postCompressSingleImage,
  saveProductImages,
  saveStaticImages,
} from "../../../services";

import sortBy from "lodash/sortBy";
import orderBy from "lodash/orderBy";
import find from "lodash/find";
import {findProductsByCollectionId} from "../../../services/api/products";
const ims = [im1, im2, im3];
const imListTest = [
  {link: "im1.jpg", file: im2, name: "im2"},
  {link: "im0.jpg", file: im1, name: "im1"},
  {link: "im2.jpg", file: im3, name: "im3"},
];
export default function Images() {
  const [user, setUser] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [imStyle, setImStyle] = useState({});
  const [isCardHover, setIsCardHover] = useState([]);
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState({
    id: "",
    creator: "",
    name: "",
  });
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const userCtx = useContext(UserContext);

  useEffect(() => {
    setUser(userCtx.user.role);
    init();
    console.log("user", userCtx.user.role);
    const tt = orderBy(imListTest, ["name"], ["asc"]);
    // console.log("t", tt);
    // setImageList(tt);
    // setImageCardStyle(tt);
    // initCardHover();
  }, []);

  async function init(params) {
    const role = userCtx.user.role;
    if (role === "admin") {
      const res = await getAllCollections();
      const coll = [];
      res.forEach((el) => {
        coll.push({name: el.name, creator: el.creator.company, id: el._id});
      });
      setCollections(coll);
      console.log("res", coll);
    }
    if (role === "creat") {
      const res = await getAllCollections();
      const coll = [];
      res.forEach((el) => {
        if (userCtx.user._id === el.creator._id)
          coll.push({name: el.name, creator: el.creator.company, id: el._id});
      });
      setCollections(coll);
      console.log("res", coll);
    }
  }

  const imageMenuStyle = {};

  function setImageCardStyle(list) {
    const styleArray = [];
    list.forEach((el) => {
      //const bckImage = URL.createObjectURL(el.file);
      styleArray.push({
        height: "10rem",
        width: "10rem",
        position: "relative",
        backgroundColor: "grey",

        backgroundImage: "url(" + el.file + ")",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
        margin: "0.5rem",
      });
    });
    setImStyle(styleArray);
  }
  function setCardsHover(index = -1) {
    const arr = [...isCardHover];
    imageList.forEach((e, ind) => {
      index === ind ? (arr[ind] = true) : (arr[ind] = false);
    });
    setIsCardHover(arr);
  }
  function initCardHover(params) {
    const arr = [];
    imageList.forEach((e, ind) => {
      arr.push(false);
    });
    setIsCardHover(arr);
  }

  function handleLinkClick(index) {
    const name = imageList[index].link;
    navigator.clipboard.writeText(name);
    console.log("link click", index);
  }
  async function handleDeleteClick(index) {
    const res = [...imageList];

    console.log("res", res);
    const del = await deleteImage({
      colId: selectedCollection.id,
      link: res[index].link,
    });
    res.splice(index, 1);
    setImageList(res);
    setImageCardStyle(res);
    initCardHover();
    console.log("delete click", index);
  }
  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  async function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const arr = [...imageList];
    let files = [...e.dataTransfer.files];
    console.log("files", files);
    const filesToSave = [];
    setCounter(0);
    for (let i = 0; i < files.length; i++) {
      const res = await readUrlFromFile(files[i]);
      if (res != null) {
        filesToSave.push(res.toSave);
        arr.push(res.state);
        setCounter(counter + 100 / files.length);
      }
    }
    if (filesToSave.length > 0) {
      const resp = await saveStaticImages(filesToSave);
      const tt = orderBy(arr, ["name"], ["asc"]);
      console.log("t", tt);
      setImageList(tt);
      setImageCardStyle(tt);
      initCardHover();
    }
    setLoading(false);
  }
  async function readUrlFromFile(file) {
    //change file name

    const name = file.name.split(".");
    const ext = name[1];
    const filename =
      // selectedCollection.creator +
      // "-" +
      selectedCollection.id + "-" + name[0] + "." + ext + ".nsaws";

    const type = "image/" + ext;
    const blob = file.slice(0, file.size, type);
    let newFile = new File([blob], filename, {type: type});

    //check file type
    const fileType = newFile.type.split("/")[1];

    if (
      !(
        fileType === "webp" ||
        fileType === "png" ||
        fileType === "jpg" ||
        fileType === "jpeg"
      )
    ) {
      //   setIsLoading(false);
      //   setImage(image);
      //   window.alert("wrong file format");

      return;
    }
    newFile = await postCompressSingleImage({
      image: file,
      filename: filename,
    });
    // if (newFile.size > 200000) {
    //   newFile = await compressImage(newFile, filename);
    // }
    let im = null;
    try {
      im = URL.createObjectURL(newFile);
    } catch (error) {
      return null;
    }

    // setIsLoading(false);
    // setImage(im);
    // props.onChange(props.imageNb, newFile);
    return {
      state: {link: filename, file: im, name: file.name},
      toSave: newFile,
    };
  }
  async function handleSelectChange(e) {
    console.log("select");
    setLoading(true);

    console.log(e.target.value);
    const sel = e.target.value;
    console.log("sel", sel, collections);
    if (sel === "") {
      setSelectedCollection({name: "", creator: "", id: ""});
      setLoading(false);

      return;
    }
    const col = find(collections, {name: sel});
    console.log("col", col);
    setSelectedCollection({name: col.name, creator: col.creator, id: col.id});

    const statImages = await getStaticImages(col.id);
    console.log("statImages", statImages);
    if (statImages == null) {
      setImageList([]);
      setImageCardStyle([]);
      initCardHover();
      setLoading(false);

      return;
    }
    const imList = [];
    for (let i = 0; i < statImages.length; i++) {
      const el = statImages[i];
      const tempFile = await getStaticImage(el.link);
      const fl = URL.createObjectURL(tempFile);
      imList.push({link: el.link, file: fl, name: el.name});
      console.log("statImages.length", statImages.length);
      console.log("i", i);
      const ct = Math.floor((i * 100) / statImages.length);
      setCounter(ct);
    }
    console.log("imList", imList);
    setImageList(imList);
    setImageCardStyle(imList);
    initCardHover();
    setLoading(false);
    setCounter(0);
    //console.log("test", test);
  }

  function handleImportImages() {
    console.log("import", imageList);
    addImagesToCollection();
  }

  async function addImagesToCollection() {
    console.log("addImagesToCollection");
    setLoading(true);
    setCounter(0);

    const products = await findProductsByCollectionId(selectedCollection.id);
    console.log("products", products);
    console.log("imlist", imageList);
    for (let i = 0; i < imageList.length; i++) {
      const im = imageList[i];
      const imRef = im.name.split(/-|_/)[0];
      const imIndex = parseInt(im.name.split(/-|_/)[1]) - 1;
      console.log("imRef", imRef, imIndex);

      const prod = find(products, {reference: imRef});
      console.log("prod----", prod);
      if (prod !== undefined) {
        const fl = await getStaticImage(im.link);
        const newFile = new File([fl], prod._id + "-" + imIndex, {
          type: "image/*",
        });
        console.log("newFile", newFile);
        const res = await saveProductImages(prod._id, [newFile]);
      }
      setCounter(Math.floor((i * 100) / imageList.length));
    }
    setLoading(false);
  }

  return (
    <>
      <Navbar />
      {loading && <LoaderWin count={counter} />}
      <div className={Styles.innerFrame}>
        <div className={Styles.margin}></div>

        <div className={Styles.navigation}>
          <Link className={Styles.navItem} to="/Home">
            Home
          </Link>
          <Icon
            icon="arrowheadright"
            color={Palette.col_mediumGrey}
            hcolor={Palette.col_mediumGrey}
            size="1.3rem"
          />

          {user === "admin" && (
            <Link className={Styles.navItem} to="/Admin">
              Administration
            </Link>
          )}
          {user === "admin" && (
            <Icon
              icon="arrowheadright"
              color={Palette.col_mediumGrey}
              hcolor={Palette.col_mediumGrey}
              size="1.3rem"
            />
          )}
          <div className={Styles.navItem}>Images</div>
        </div>

        <div className={Styles.title}>Images buckets</div>
        <div className={Styles.subTitle}>{selectedCollection.name}</div>

        <div className={Styles.menuBar}>
          {selectedCollection.name !== "" && (
            <RegularButton
              name="import all to collection"
              width="15rem"
              white
              onClick={handleImportImages}
            />
          )}
          <select
            className={Styles.select}
            //value={selectedCollection}
            onChange={handleSelectChange}
          >
            <option value={""}>select a collection</option>
            {collections.map((col, index) => (
              <option key={index} value={col.name}>
                {col.name}
              </option>
            ))}
          </select>
          <RegularButton
            name="delete all"
            white
            backHColor={Palette.col_black}
            //isdisabled={!modified}
            //onClick={handleSave}
          />
        </div>
        {selectedCollection.name === "" && (
          <div className={Styles.message}>Please select collection</div>
        )}
        {selectedCollection.name !== "" && (
          <div className={Styles.imagesList}>
            <div
              className={Styles.dropCard}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              drop images here
            </div>
            {imageList.map((im, index) => (
              <div
                style={imStyle[index]}
                key={index}
                onMouseEnter={() => setCardsHover(index)}
                onMouseLeave={() => setCardsHover()}
              >
                <div
                  className={isCardHover[index] ? Styles.imageLink : Styles.off}
                >
                  {im.name}
                </div>
                <div
                  className={isCardHover[index] ? Styles.imageMenu : Styles.off}
                >
                  <div
                    className={Styles.loadLink}
                    onClick={() => handleLinkClick(index)}
                  >
                    copy link
                  </div>
                  <Icon
                    icon="trash"
                    color={Palette.col_darkGrey}
                    hcolor={Palette.col_lightOrange}
                    size="1.7rem"
                    onClick={() => handleDeleteClick(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
