import {css, keyframes} from "@emotion/css";

import {Palette} from "../../constants";

const animate = keyframes`
from {transform: rotate(0deg)}
to {transform: rotate(360deg)}`;

const animateColor = keyframes`
from {color: black}
to {color: darkgrey}`;

const Styles = {
  frame: css({
    position: "fixed",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100vw",
    backgroundColor: Palette.col_whiteOpacity,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "10000",
  }),
  loader: css({
    height: "50px",
    width: "50px",

    borderImageSlice: 1,
    // background: "conic-gradient( orange, white)",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    animation: animate + " 1s linear infinite ",
  }),
  inner: css({
    height: "40px",
    width: "40px",
    borderRadius: "100%",
    backgroundColor: "white",
  }),
  message: css({
    marginTop: "5px",
    color: Palette.col_darkGrey,
    //animation: animateColor + " 2s ease infinite alternate-reverse",
  }),
};
export default Styles;
