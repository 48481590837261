import {css} from "@emotion/css";
import {Palette} from "../../../../constants";

const Cell = css({
  height: "1.5rem",
  lineHeight: "1.5rem",
  padding: "0 0.5rem",
  textOverflow: "",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textAlign: "center",
});
const headerCell = css(Cell, {
  backgroundColor: Palette.col_mediumGrey,
  color: Palette.col_white,
  borderRight: "1px solid " + Palette.col_lightGrey,
  cursor: "pointer",
  userSelect: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {backgroundColor: Palette.col_darkGrey},
});
const InnerCell = css(Cell, {
  borderBottom: "1px solid " + Palette.col_xlightGrey,
  borderRight: "1px solid " + Palette.col_lightGrey,
});

const Styles = {
  frame: css({
    width: "100%",
    overflowX: "scroll",
  }),
  table: css({}),
  headerContainer: css({
    display: "flex",
  }),
  headerCellLarge: css(headerCell, {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: "4rem",
    flexBasis: "10rem",
  }),

  btnFilter: css({
    width: "1rem",
    height: "1rem",
    marginLeft: "auto",
    textAlign: "center",
    backgroundColor: Palette.col_darkGrey,
    color: Palette.col_white,
    "&:hover": {
      backgroundColor: Palette.col_xlightGrey,
      color: Palette.col_darkGrey,
    },
  }),
  headerCellMedium: css(headerCell, {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: "2rem",
    flexBasis: "7rem",
  }),
  headerCellSmall: css(headerCell, {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: "1rem",
    flexBasis: "3rem",
  }),
  cellContainer: css({
    display: "flex",
  }),
  cellLarge: css(InnerCell, {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: "4rem",
    flexBasis: "10rem",
  }),
  cellMedium: css(InnerCell, {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: "2rem",
    flexBasis: "7rem",
  }),
  cellMediumAlert: css(InnerCell, {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: "2rem",
    flexBasis: "7rem",
    color: "red",
  }),
  cellSmall: css(InnerCell, {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: "1rem",
    flexBasis: "3rem",
  }),
  cellSmallIcon: css(InnerCell, {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: "2rem",
    flexBasis: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  btnPending: css({
    boxSizing: "border-box",
    backgroundColor: "red",
    color: Palette.col_white,
    //border: "1px solid white",
    borderRadius: "1rem",
    height: "1.3rem",
    lineHeight: "1.3rem",
    textAlign: "center",
    fontSize: "1rem",
    marginTop: "0.12rem",
    cursor: "pointer",
    userSelect: "none",
    "&:hover": {
      backgroundColor: "darkred",
    },
  }),
};

export default Styles;
