import {css} from "@emotion/css";
import {Palette} from "../../constants";

const Styles = {
  frame: css({
    width: "5rem",
    height: "5rem",
    marginRight: "0.5rem",
  }),
  image: css({
    height: "5rem",
    width: "5rem",
    objectFit: "contain",
    cursor: "pointer",
  }),
};

export default Styles;
