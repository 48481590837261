import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {confirmEmail} from "../../../services";
import Styles from "../register/Styles";
import logo from "../../../assets/images/logo.png";
export default function EmailConfirmed() {
  const {token} = useParams();

  const [exists, setExists] = useState(false);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    const res = await confirmEmail({token});
    if (res) setExists(true);
    else setExists(false);
  }

  return (
    <div className={Styles.frame}>
      <div className={Styles.banner}>
        <img className={Styles.logo} src={logo} alt="" />
      </div>
      <div className={Styles.title}>"Hello " </div>
      <div className={Styles.textContainer}>
        {exists && (
          <div className={Styles.line}>Your email is now confirmed</div>
        )}
        {!exists && (
          <div className={Styles.line}>
            Your email has already been confirmed
          </div>
        )}

        <a
          className={Styles.lineLink}
          rel="noreferrer"
          href="https://www.noseason-showroom.com"
        >
          go to noseason website
        </a>
      </div>
    </div>
  );
}
