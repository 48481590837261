import React, {useContext, Suspense, lazy} from "react";
import {Routes, Route, Navigate} from "react-router";

import {UserContext} from "../contexts";

import {authUser, authAdmin, authCreator, authAdminOrCreator} from "../auth";
import EditCollection from "./admin/collections/editCollection/EditCollection";
import Images from "./admin/images/Images";
//import ChangePassword from "./logPages/changePassword/ChangePassword";
const ChangePassword = lazy(() =>
  import("../pages/logPages/changePassword/ChangePassword")
);

const Home = lazy(() => import("../pages/home/Home"));
const Admin = lazy(() => import("../pages/admin/Admin"));
const Creator = lazy(() => import("../pages/creator/Creator"));
const Welcome = lazy(() => import("../pages/logPages/Welcome"));
const Seasons = lazy(() => import("../pages/home/seasons/Seasons"));
const Categories = lazy(() => import("../pages/home/categories/Categories"));
const ProdThin = lazy(() => import("../pages/home/product/mainMedia/ProdThin"));
const UserAccount = lazy(() => import("../pages/home/user/UserAccount"));
const Register = lazy(() => import("../pages/logPages/register/Register"));
const ProductList = lazy(() =>
  import("./admin/collections/editCollection/productList/ProductList")
);

const EmailConfirmed = lazy(() =>
  import("../pages/logPages/emailConfirmed/EmailConfirmed")
);
export default function Main(props) {
  const userContext = useContext(UserContext);
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              authUser(userContext.user) ? (
                <Home />
              ) : (
                <Navigate to="/Welcome" redirect={false} />
              )
            }
          ></Route>

          <Route path="/Welcome" element={<Welcome redirect={false} />}></Route>
          <Route
            path="/Home"
            element={
              authUser(userContext.user) ? <Home /> : <Navigate to="/Welcome" />
            }
          ></Route>
          <Route path="/Account" element={<UserAccount />}></Route>
          <Route path="/Home/Seasons/:line" element={<Seasons />}></Route>
          <Route
            path="/Home/Seasons/Product/:id/:variant/:col"
            element={<ProdThin />}
          ></Route>
          {/* <Route path="/Home/Products" element={<Products />}></Route> */}
          <Route
            path="/Home/Seasons/Categories/:id"
            element={<Categories />}
          ></Route>

          <Route
            path="/Creator"
            element={
              authCreator(userContext.user) ? (
                <Creator />
              ) : (
                <Navigate to="/Welcome" />
              )
            }
          ></Route>
          <Route
            path="/Images"
            element={
              authCreator(userContext.user) ? (
                <Images />
              ) : (
                <Navigate to="/Welcome" />
              )
            }
          ></Route>
          <Route path="/Register" element={<Register />}></Route>
          <Route
            path="/ConfirmEmail/:token"
            element={<EmailConfirmed />}
          ></Route>

          <Route
            path="/ChangePassword/:token"
            element={<ChangePassword />}
          ></Route>

          <Route
            path="/Admin/Collection/:id"
            element={
              authAdminOrCreator(userContext.user) ? (
                <EditCollection />
              ) : (
                <Navigate to="/Welcome" />
              )
            }
          ></Route>
          <Route
            path="/Admin/Products/:id"
            element={
              authAdminOrCreator(userContext.user) ? (
                <ProductList />
              ) : (
                <Navigate to="/Welcome" />
              )
            }
          ></Route>
          <Route
            path="/Admin/Images"
            element={
              authAdminOrCreator(userContext.user) ? (
                <Images />
              ) : (
                <Navigate to="/Welcome" />
              )
            }
          ></Route>

          <Route
            path="/Admin/*"
            element={
              authAdminOrCreator(userContext.user) ? (
                <Admin />
              ) : (
                <Navigate to="/Welcome" />
              )
            }
          ></Route>
          <Route path="/*" element={<Welcome redirect={false} />}></Route>
        </Routes>
      </Suspense>
    </div>
  );
}
