import {css} from "@emotion/css";
import {Palette} from "../../../constants";

const Styles = {
  input: css({
    border: "none",
    borderBottom: "1px solid " + Palette.col_darkGrey,
    outline: "none",
    width: "15rem",
    height: "2rem",
    borderRadius: "0",
  }),
  banner: css({
    height: "5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Palette.col_darkGrey,
  }),
  logo: css({
    height: "2rem",
  }),
  title: css({
    width: "50%",
    textAlign: "center",
    fontSize: "2rem",
    lineHeight: "2rem",
    margin: "2rem auto",
    paddingBottom: "1rem",
    borderBottom: "1px solid " + Palette.col_darkGrey,
  }),
  inner: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  inputsBlock: css({
    width: "32rem",
    display: "flex",
    //justifyContent: "space-between",
    // border: "1px solid blue",
    "@media(max-width: 500px)": {
      width: "16rem",
      display: "flex",

      flexDirection: "column",
    },
  }),
  inputContainer: css({
    marginBottom: "0.4rem",
    width: "16rem",
  }),
  inputContainerLarge: css({
    marginBottom: "0.4rem",
    width: "100%",
  }),
  checkContainer: css({
    display: "flex",
    marginTop: "0.5rem",
    border: "1px solid black",
    padding: "0.5rem 0",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    "@media(max-width: 500px)": {
      width: "16rem",
      display: "flex",
      flexDirection: "column",
    },
  }),
  checkSub: css({
    display: "flex",
    alignItems: "center",
    "@media(max-width: 500px)": {
      marginLeft: "1rem",
    },
  }),
  countrySelect: css({
    marginTop: "1rem",
    width: "16rem",
  }),
  inputLabel: css({
    userSelect: "none",
    fontFamily: "Helvetica Neue",
    color: Palette.col_darkGrey,
  }),
  error: css({
    color: "red",
    margin: "0.2rem 0 1rem 0",
    fontSize: "0.8rem",
    height: "0.8rem",
    lineHeight: "1rem",
    fontFamily: "Helvetica Neue",
  }),
  btn: css({
    width: "20rem",
    height: "2rem",
    fontSize: "1rem",
    color: Palette.col_white,
    backgroundColor: Palette.col_darkGrey,
    textAlign: "center",
    cursor: "pointer",
    userSelect: "none",
    marginTop: "1rem",
    lineHeight: "2rem",
    outline: "none",
    border: "none",
    "&:hover": {
      backgroundColor: Palette.col_mediumGrey,
    },
  }),
  linkContainer: css({
    display: "flex",
    marginTop: "2rem",
    justifyContent: "center",
    alignItems: "center",
  }),
  link: css({
    color: Palette.col_darkGrey,
    "@media(max-width: 500px)": {
      marginBottom: "1rem",
    },
  }),
  textContainer: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  line: css({
    width: "100%",
    textAlign: "center",
    fontSize: "1.2rem",
    marginBottom: "1rem",
  }),
  lineLink: css({
    width: "20rem",
    height: "3rem",
    lineHeight: "3rem",
    textAlign: "center",
    fontSize: "1.2rem",
    margin: "3rem 0",
    color: "black",
    textDecoration: "none",
    border: "1px solid " + Palette.col_darkGrey,
    "&:hover": {
      color: Palette.col_mediumGrey,
    },
  }),
};

export default Styles;
