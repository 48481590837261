import React, {useState, useEffect} from "react";

import orderBy from "lodash/orderBy";

import Styles from "./Styles";
import {Palette} from "../../../../../constants";
import {Icon} from "../../../../../components";
import {Navigate, useNavigate} from "react-router-dom";
// import {Palette} from "../../../constants";
// import {Icon} from "../../../components";

export default function CollectionsTable(props) {
  let navigate = useNavigate();
  const defaultSortOrder = {
    brand: false,
    name: false,
    line: false,
  };
  const [sortOrder, setSortOrder] = useState({});
  const [collections, setCollections] = useState([]);
  useEffect(() => {
    setCollections(props.datas);
  }, [props.datas]);

  function handleHeaderClick(name) {
    const so = sortOrder[name];
    const tempSo = {...defaultSortOrder};
    tempSo[name] = !so;
    setSortOrder(tempSo);

    let tempColl = [];
    if (name === "brand") {
      tempColl = orderBy(collections, "creator.firstName", so ? "desc" : "asc");
    } else {
      tempColl = orderBy(collections, name, so ? "desc" : "asc");
    }
    setCollections(tempColl);
  }

  function handleSelect(id) {
    navigate("/Admin/Collection/" + id);
  }
  return (
    <div className={Styles.frame}>
      {collections.length === 0 && (
        <div className={Styles.noCollection}>
          <i>No collection</i>
        </div>
      )}
      {collections.length > 0 && (
        <div className={Styles.table}>
          <div className={Styles.headerContainer}>
            <div
              className={Styles.headerCellMedium}
              onClick={() => handleHeaderClick("line")}
            >
              Line
            </div>
            <div
              className={Styles.headerCellLarge}
              onClick={() => handleHeaderClick("name")}
            >
              Name
            </div>
            <div
              className={Styles.headerCellLarge}
              onClick={() => handleHeaderClick("brand")}
            >
              Brand
            </div>
            <div
              className={Styles.headerCellMedium}
              onClick={() => handleHeaderClick("isOnline")}
            >
              Online
            </div>

            <div className={Styles.headerCellXSmall}></div>
          </div>

          {collections.map((col, index) => (
            <div className={Styles.cellContainer} key={index}>
              <div className={Styles.cellMedium}>{col.line}</div>
              <div className={Styles.cellLarge}>{col.name}</div>
              <div className={Styles.cellLarge}>{col.creator.company}</div>
              <div className={Styles.cellMedium}>{col.isOnline ? "X" : ""}</div>

              <div
                className={Styles.cellSmallIcon}
                onClick={() => handleSelect(col._id)}
              >
                <Icon
                  icon="eye"
                  size="1rem"
                  color={Palette.col_mediumGrey}
                  hcolor={Palette.col_darkGrey}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
