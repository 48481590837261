import React, {useState, useEffect, useContext} from "react";

import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import findIndex from "lodash/findIndex";

import {Collection} from "../../../../models";

import DatePicker from "react-datepicker/dist/react-datepicker.min.js";
import "react-datepicker/dist/react-datepicker.min.css";
import moment from "moment/min/moment.min.js";
import {read, utils} from "xlsx/dist/xlsx.mini.min.js";

import {
  Icon,
  ImageBlockLogo,
  LoaderWin,
  Navbar,
  RegularButton,
} from "../../../../components";
import {Palette} from "../../../../constants";

import {ImageBlock} from "../../../../components";
import {EditProducts} from "./editProducts";

import {
  deleteCollection,
  deleteProduct,
  findProductsById,
  getCollectionById,
  getUserById,
  updateCollectionDatabase,
  updateProduct,
} from "../../../../services";
import {readCollectionFile} from "../../../../utils/importVariant";

import Styles from "./Styles";

import {
  CollectionsContext,
  ImagesContext,
  UserContext,
} from "../../../../contexts";

import {updateCollection} from "../../../../services";

import {
  getAllproducts,
  moveProductFromCollection,
  moveProductToCollection,
} from "../../../../utils/collectionsUtils";
import {Link, Navigate, useParams, useNavigate} from "react-router-dom";
import {setCollectionOnline} from "../../../../services/api/collection";

export default function EditCollection(props) {
  const {id} = useParams();
  let navigate = useNavigate();
  const collectionsContext = useContext(CollectionsContext);
  const imagesCtx = useContext(ImagesContext);

  const [imageFile, setImageFile] = useState(null);
  const [imageFileLogo, setImageFileLogo] = useState(null);
  const [refImage, setRefImage] = useState(null);

  const [modify, setModify] = useState(false);
  const [coverflow, setCoverflow] = useState(true);
  const [modified, setModified] = useState(false);
  const [canSave, setCanSave] = useState(true);

  const [startDate, setStartDate] = useState(new Date());

  const [errors, setErrors] = useState({});
  const [isOnline, setIsOnline] = useState(false);

  const [imageHasChanged, setImageHasChanged] = useState(false);
  const [lookbookHasChanged, setLookbookHasChanged] = useState(false);

  const [linesheetHasChanged, setLinesheetHasChanged] = useState(false);

  const [incoterms, setIncoterms] = useState([
    "EXW",
    "FCA",
    "CPT",
    "CIP",
    "DAP",
    "DPU",
    "DDP",
    "FAS",
    "FOB",
    "CFR",
    "CIF",
  ]);
  const [collectionID, setCollectionID] = useState("");

  const [loaded, setLoaded] = useState(false);

  const [brand, setBrand] = useState("");

  //____________________

  const [collection, setCollection] = useState(null);
  const [refCollection, setRefCollection] = useState({});
  const [modifiedCollection, setModifiedCollection] = useState({});

  const [products, setProducts] = useState(null);

  const [loading, setLoading] = useState(false);

  const [productsStatus, setProductsStatus] = useState([]);

  const [counter, setCounter] = useState(0);

  const [display, setDisplay] = useState(true);
  const [goToProducts, setGoToProducts] = useState(false);

  const userCtx = useContext(UserContext);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    setLoading(true);
    const collection = new Collection();
    const ids = await collection.init(id);

    //---------------end set products
    setCollection(collection);
    const coll = {
      name: collection.modified.name,
      line: collection.modified.line,
      launchDate: collection.modified.launchDate,
      lookbook: collection.modified.lookbook,
      linesheet: collection.modified.linesheet,
      title: collection.modified.title,
      creator: {
        lastName: collection.modified.creator.lastName,
        firstName: collection.modified.creator.firstName,
        _id: collection.modified.creator._id,
      },
      image: collection.modified.image,
      incoterm: collection.modified.incoterm,
      logo: collection.modified.logo,
      isOnline: collection.modified.isOnline,
    };
    //set brand---------
    const user = await getUserById(collection.modified.creator._id);
    setBrand(user.company);
    setRefImage(collection.modified.image);

    setCollectionID(id);
    setRefCollection(coll);
    setModifiedCollection(coll);
    setIsOnline(collection.modified.isOnline);
    setLoading(false);
  }

  function setLoadingCounter(count) {
    setCounter(count);
  }

  function handleProdStatusChange({send, id}) {
    const tempStat = [...productsStatus];
    const index = findIndex(productsStatus, {id: id});
    if (id === undefined) return;
    if (index === -1) {
      tempStat.push({
        id,
        send,
      });
    } else {
      tempStat[index] = {send, id};
    }
    setProductsStatus(tempStat);
  }
  //-----------------------------------------------------
  //------------------------------------------------------

  function handleModify() {
    setModify(!modify);
  }
  async function handleLookbook(e) {
    const file = e.target.files[0];
    const ext = file.name.split(".").pop();
    if (ext !== "pdf") {
      window.alert("Incorrect file format");
      const tempCol = {...modifiedCollection};
      validate(tempCol);
      return;
    }
    const filename = collection.id + "-LOOKBOOK";
    const type = "application/pdf";

    let newFile = new File([file], filename, {type: type});
    // setLookbook(newFile);
    // setLookbookHasChanged(true);
    const tempCol = {...modifiedCollection};
    tempCol.lookbook = newFile;
    validate(tempCol);
    setModifiedCollection(tempCol);
  }
  async function handleLinesheet(e) {
    const file = e.target.files[0];
    const ext = file.name.split(".").pop();
    if (ext !== "xlsx") {
      window.alert("Incorrect file format");
      const tempCol = {...modifiedCollection};
      validate(tempCol);
      return;
    }
    const filename = collection.id + "-LINESHEET";
    const type = "application/xlsx";

    let newFile = new File([file], filename, {type: type});
    // setLinesheet(newFile);
    // setLinesheetHasChanged(true);
    const tempCol = {...modifiedCollection};
    tempCol.linesheet = newFile;
    validate(tempCol);
    setModifiedCollection(tempCol);
  }
  // collection--------------------
  async function handleSave() {
    setLoading(true);
    const res = await updateCollection(modifiedCollection, collection.id);
    //props.onClose();
    init();
    setLoading(false);
  }

  function handleCancel() {
    const newColl = cloneDeep(refCollection);
    validate(newColl);
    setModifiedCollection(newColl);
    setModified(false);
  }
  function handleTitleChange(e) {
    const tempCol = {...modifiedCollection};
    tempCol.title = e.target.value;
    setModifiedCollection(tempCol);
    //checkChanged(tempCol);
    validate(tempCol);
  }
  function handleChangeIncoterm(e) {
    const tempCol = {...modifiedCollection};
    tempCol.incoterm = e.target.value;

    validate(tempCol);
    setModifiedCollection(tempCol);
  }
  function handleStartDate(date) {
    setStartDate(date);
    const tempCol = {...modifiedCollection};
    tempCol.launchDate = date;
    validate(tempCol);
    setModifiedCollection(tempCol);
  }
  function handleImageChange(newFile, filename) {
    setImageFile(newFile);
    const tempCol = {...modifiedCollection};
    tempCol.image = filename;
    setModifiedCollection(tempCol);
    validate(tempCol);
    setImageHasChanged(true);
  }
  function handleImageChangeLogo(newFile, filename) {
    setImageFileLogo(newFile);
    const tempCol = {...modifiedCollection};
    tempCol.logo = newFile;
    setModifiedCollection(tempCol);
    validate(tempCol);
    setImageHasChanged(true);
  }

  async function handleDeleteCollection() {
    const conf = window.prompt(
      "To confirm deletion, type permanentlydelete in the text input field"
    );

    if (conf === "permanentlydelete") {
      setLoading(true);

      await deleteCollection(collection.id);
      // props.onDelete(modifiedCollection._id);
      //prods
      const prods = await getAllproducts(collection);
      for (let i = 0; i < prods.length; i++) {
        const prod = prods[i];
        await deleteProduct(prod);
      }
      setLoading(false);
      navigate("/Admin/Collections");
    } else return;
  }
  function validate(tempCol) {
    checkChanged(tempCol);

    const tempErrors = {};
    if (tempCol.logo === "") tempErrors.logo = "Logo is nedeed";
    if (tempCol.image === "") tempErrors.image = "image is nedeed";
    if (tempCol.lookbook === "") tempErrors.lookbook = "Lookbook is nedeed";
    if (tempCol.linesheet === "") tempErrors.linesheet = "Linesheet is nedeed";
    if (tempCol.title === "") tempErrors.title = "Title is nedeed";

    if (tempErrors !== {}) setCanSave(false);
    else setCanSave(true);

    //products

    //linesheet

    setErrors(tempErrors);
  }
  function checkChanged(tempCol) {
    let res = false;

    if (refCollection.incoterm !== tempCol.incoterm) res = true;
    if (refCollection.launchDate !== tempCol.launchDate) res = true;
    if (refCollection.title !== tempCol.title) res = true;
    if (refCollection.logo !== tempCol.logo) res = true;
    if (refCollection.image !== tempCol.image) res = true;
    if (refCollection.lookbook !== tempCol.lookbook) res = true;
    if (refCollection.linesheet !== tempCol.linesheet) res = true;
    if (res) {
      setModified(true);
    } else {
      setModified(false);
    }
    if (lookbookHasChanged) setModified(true);
    if (linesheetHasChanged) setModified(true);
  }

  //______________________________________________________

  function handleGoToProducts() {
    setGoToProducts(true);
  }
  async function handleOnline() {
    setIsOnline(!isOnline);

    await setCollectionOnline({collId: id, isOnline: !isOnline});
  }
  return (
    <div>
      <Navbar />
      {loading && <LoaderWin count={counter} />}
      <div className={Styles.margin}></div>
      <div className={Styles.innerFrame}>
        <div className={Styles.navigation}>
          <Link className={Styles.navItem} to="/Home">
            Home
          </Link>
          <Icon
            icon="arrowheadright"
            color={Palette.col_mediumGrey}
            hcolor={Palette.col_mediumGrey}
            size="1.3rem"
          />

          {userCtx.user.role === "admin" && (
            <Link className={Styles.navItem} to="/Admin">
              Administration
            </Link>
          )}

          {userCtx.user.role === "admin" && (
            <Icon
              icon="arrowheadright"
              color={Palette.col_mediumGrey}
              hcolor={Palette.col_mediumGrey}
              size="1.3rem"
            />
          )}

          <Link className={Styles.navItem} to={"/Admin/Collections"}>
            Collections
          </Link>
          <Icon
            icon="arrowheadright"
            color={Palette.col_mediumGrey}
            hcolor={Palette.col_mediumGrey}
            size="1.3rem"
          />

          <div className={Styles.navItem}>Collection</div>
        </div>
        {goToProducts && (
          <Navigate to={"/Admin/Products/" + id} redirect={false} />
        )}

        {collection && (
          <div className={Styles.frame}>
            <div className={Styles.title}>
              {" "}
              {collection.modified.name + "/ id: " + collection.id}
            </div>
            <div className={Styles.onlineContainer}>
              <div className={isOnline ? Styles.online : Styles.offline}>
                {isOnline ? "Collection is online" : "Collection is offline"}
              </div>
              <div className={Styles.sendBtn} onClick={handleOnline}>
                {isOnline
                  ? "click here to send offline"
                  : "click here to send online"}
              </div>
            </div>
            <div className={Styles.bntHeadContainer}>
              {/* <label htmlFor="file-upload" className={Styles.btnVariant}>
          Import variant
        </label> */}
              <RegularButton
                onClick={handleGoToProducts}
                name="go to products"
                white
                width="10rem"
                ml="0"
              />
              {modify && (
                <RegularButton
                  onClick={handleSave}
                  name="save"
                  white
                  ml="auto"
                  mr="0.2rem"
                />
              )}
              {modify && (
                <RegularButton
                  onClick={handleCancel}
                  name="cancel"
                  white
                  mr="0.2rem"
                />
              )}
              {!modify && (
                <RegularButton
                  onClick={handleModify}
                  name="modify"
                  white
                  ml="auto"
                  mr="0.2rem"
                />
              )}
              <RegularButton
                onClick={handleDeleteCollection}
                name="delete"
                white
                //ml="auto"
              />
            </div>

            <div className={Styles.subContainer}>
              <div className={Styles.leftSubContainer}>
                <div className={Styles.inputBlock}>
                  <div className={Styles.inputTitle}>Brand</div>

                  <div>{brand}</div>

                  <div className={Styles.errors}>{errors.creator}</div>
                </div>
                <div className={Styles.inputBlock}>
                  <div className={Styles.inputTitle}>Name</div>
                  <div className={Styles.input}>{collection.modified.name}</div>
                </div>
                <div className={Styles.inputBlock}>
                  <div className={Styles.inputTitle}>Line</div>

                  <div className={Styles.text}>{collection.modified.line}</div>
                  <div className={Styles.errors}>{errors.line}</div>
                </div>
                <div className={Styles.inputBlock}>
                  <div className={Styles.inputTitle}>Launch date</div>
                  {modify && (
                    <div className={Styles.text}>
                      {moment(modifiedCollection.launchDate).format(
                        "MMM Do YYYY"
                      )}
                    </div>
                  )}
                  {modify && (
                    <DatePicker
                      //selected={new Date(modifiedCollection.launchDate)}
                      onChange={(date) => handleStartDate(date)}
                    />
                  )}
                  {!modify && (
                    <div className={Styles.text}>
                      {moment(modifiedCollection.launchDate).format(
                        "MMM Do YYYY"
                      )}
                    </div>
                  )}
                  <div className={Styles.errors}></div>
                </div>

                <div className={Styles.inputBlock}>
                  <div className={Styles.inputTitle}>Lookbook</div>
                  {modify && (
                    <input
                      className={Styles.input}
                      type="file"
                      onChange={handleLookbook}
                      disabled={!modify}
                    />
                  )}
                  {!modify && !collection.modified.lookbook && (
                    <div className={Styles.text}>
                      <i>No lookbook</i>
                    </div>
                  )}
                  {!modify && collection.modified.lookbook && (
                    <div className={Styles.text}>
                      {typeof collection.modified.lookbook === "string"
                        ? collection.modified.lookbook
                        : collection.modified.lookbook.name}
                    </div>
                  )}
                  <div className={Styles.errors}>{errors.lookbook}</div>
                </div>
                <div className={Styles.inputBlock}>
                  <div className={Styles.inputTitle}>Linesheet</div>
                  {modify && (
                    <input
                      className={Styles.input}
                      type="file"
                      onChange={handleLinesheet}
                      disabled={!modify}
                    />
                  )}

                  {!modify && !collection.modified.linesheet && (
                    <div className={Styles.text}>
                      <i>No linesheet</i>
                    </div>
                  )}
                  {!modify && collection.modified.linesheet && (
                    <div className={Styles.text}>
                      {typeof collection.modified.linesheet === "string"
                        ? collection.modified.linesheet
                        : collection.modified.linesheet.name}
                    </div>
                  )}
                  <div className={Styles.errors}>{errors.linesheet}</div>
                </div>
                <div className={Styles.inputBlock}>
                  <div className={Styles.inputTitle}>Incoterm</div>
                  {!modify && (
                    <div className={Styles.text}>
                      {modifiedCollection.incoterm}
                    </div>
                  )}
                  {modify && (
                    <select
                      className={Styles.select}
                      id="listIncoterm"
                      onChange={handleChangeIncoterm}
                      disabled={!modify}
                    >
                      <option value="" selected disabled hidden>
                        "Select incoterm"
                      </option>
                      {incoterms.map((inc, index) => (
                        <option key={index} value={inc} label={inc} />
                      ))}
                    </select>
                  )}

                  <div className={Styles.errors}>{errors.creator}</div>
                </div>
                <div className={Styles.inputBlockText}>
                  <div className={Styles.inputTitle}>Title</div>
                  {modify && (
                    <input
                      className={Styles.input}
                      value={modifiedCollection.title}
                      name="title"
                      onChange={handleTitleChange}
                    />
                  )}
                  {!modify && (
                    <div className={Styles.text}>
                      {!modifiedCollection.title ? (
                        <i>No title</i>
                      ) : (
                        modifiedCollection.title
                      )}
                    </div>
                  )}
                  <div className={Styles.errors}>{errors.title}</div>
                </div>
              </div>
              <div className={Styles.rightSubContainer}>
                <div className={Styles.mainImage}>
                  <ImageBlock
                    size="29vh"
                    collId={collection.id}
                    image={modifiedCollection.image}
                    onChange={handleImageChange}
                    locked={!modify}
                    file={imageFile}
                    nocache={true}
                    url
                  />
                </div>
                <div className={Styles.errors}>{errors.image}</div>

                <div className={Styles.rightInputs}></div>
                <div className={Styles.mainLogo}>
                  <ImageBlockLogo
                    // size="15vh"
                    collId={collection.id}
                    image={modifiedCollection.logo}
                    onChange={handleImageChangeLogo}
                    locked={!modify}
                    file={imageFileLogo}
                    nocache={true}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

// function handleCoverflow() {
//   setCoverflow(!coverflow);
//   //setModify(false);
// }
