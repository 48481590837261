import {css} from "@emotion/css";

import {Palette} from "../../../constants";

const filterBtn = css({
  height: "1.5rem",
  textAlign: "center",
  lineHeight: "1.5rem",
  backgroundColor: Palette.col_lightGrey,
  color: Palette.col_white,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: Palette.col_mediumGrey,
  },
});
const Styles = {
  frame: css({
    margin: "1rem 0",
  }),
  subFrame: css({
    margin: "0 2rem",
  }),
  margin: css({
    height: "50px",
  }),
  navigation: css({
    display: "flex",
    alignItems: "center",
  }),
  navItem: css({
    cursor: "pointer",
    textDecoration: "none",
    color: Palette.col_darkGrey,
    "&:hover": {
      color: Palette.col_orange,
    },
  }),
  separator: css({
    color: "blue",
  }),
  menuBar: css({
    display: "flex",
    alignItems: "center",
  }),
  title: css({
    fontSize: "2rem",
    margin: "1rem 0",
  }),
  subTitle: css({
    fontSize: "1.5rem",
    textAlign: "left",
    marginTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderBottom: "1px solid " + Palette.col_xlightGrey,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }),
  subTitleBtn: css({
    cursor: "pointer",
    "&:hover": {
      color: Palette.col_orange,
    },
  }),
  filtersContainer: css({
    marginTop: "0.5rem",
    display: "flex",
    flexWrap: "wrap",
    //backgroundColor: "red",
    alignItems: "center",
  }),
  filterBtnContainer: css({
    display: "flex",
    margin: "1rem 0",
    marginLeft: "1rem",
  }),
  filterBtnLeft: css(filterBtn, {
    width: "3rem",
    borderRight: "1px solid " + Palette.col_xlightGrey,
    borderRadius: "1rem 0 0 1rem",
  }),
  filterBtnMiddle: css(filterBtn, {
    width: "7rem",
    borderRight: "1px solid " + Palette.col_xlightGrey,
  }),
  filterBtnRight: css(filterBtn, {
    width: "7rem",
    borderRadius: "0 1rem 1rem 0",
  }),
  filterBtnLeftOn: css(filterBtn, {
    width: "3rem",
    borderRight: "1px solid " + Palette.col_xlightGrey,
    borderRadius: "1rem 0 0 1rem",
    backgroundColor: Palette.col_darkGrey,
    "&:hover": {
      backgroundColor: Palette.col_darkGrey,
    },
  }),
  filterBtnMiddleOn: css(filterBtn, {
    width: "7rem",
    borderRight: "1px solid " + Palette.col_xlightGrey,
    backgroundColor: Palette.col_darkGrey,
    "&:hover": {
      backgroundColor: Palette.col_darkGrey,
    },
  }),
  filterBtnRightOn: css(filterBtn, {
    width: "7rem",
    borderRadius: "0 1rem 1rem 0",
    backgroundColor: Palette.col_darkGrey,
    "&:hover": {
      backgroundColor: Palette.col_darkGrey,
    },
  }),
  hMargin: css({
    height: "1rem",
  }),
};

export default Styles;
