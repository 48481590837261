import React, {useState} from "react";
import Styles from "./Styles";
import Joi from "joi/dist/joi-browser.min.js";

import {resetPassword} from "../../../services";

export default function ForgotPassword(props) {
  const schemaUser = Joi.object({
    email: Joi.string()
      .email({tlds: {allow: false}})
      .required(),
  }).options({abortEarly: false});

  function validate(datas) {
    const result = schemaUser.validate(datas);
    let err = {};
    if (result.error) {
      // err = {...errors};
      for (const el of result.error.details) {
        err[el.path] = el.message;
      }
      setSubmitEnable(false);
    }
    setError({...err});
    setSubmitEnable(true);
  }
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});
  const [submitEnable, setSubmitEnable] = useState(false);
  const [done, setDone] = useState(false);

  function handleInputChange(e) {
    const val = e.target.value;
    validate({email: val});
    setEmail(val);
  }
  function handleClose() {
    props.onClose();
  }
  async function handleSubmit(params) {
    if (submitEnable) {
      const res = await resetPassword({email: email});
      props.onClose();
    }
  }
  return (
    <div className={Styles.frame}>
      <div className={Styles.inner}>
        <div className={Styles.title}>
          <div>RESET PASSWORD</div>
          <div className={Styles.cross} onClick={handleClose}>
            &#9587;
          </div>
        </div>
        <div className={Styles.container}>
          <div className={Styles.text}>
            PLEASE, PROVIDE YOUR ACCOUNT EMAIL ADDRESS. WE WILL SEND YOU AN
            EMAIL WITH A RESET LINK.
          </div>
          <div className={Styles.label}>email</div>
          <input
            className={Styles.input}
            type="text"
            value={email}
            onChange={handleInputChange}
          />
          <div className={Styles.error}>{error.email}</div>
        </div>
        <div className={Styles.btnContainer}>
          <div className={Styles.btnCancel} onClick={handleClose}>
            CANCEL
          </div>
          <div className={Styles.btnSubmit} onClick={handleSubmit}>
            SUBMIT
          </div>
        </div>
      </div>
    </div>
  );
}
