import React, {useState, useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import isEqual from "lodash/isEqual";
import DatePicker from "react-datepicker/dist/react-datepicker.min.js";
import "react-datepicker/dist/react-datepicker.min.css";

import Styles from "./Styles";

import {Palette} from "../../../../constants";

import {LoaderWin, RegularButton} from "../../../../components";

import {
  getCreators,
  checkCollectionExistsByName,
  checkUserCollectionByLine,
  createCollection,
} from "../../../../services";

export default function CreateCollection(props) {
  const [collection, setCollection] = useState({});
  const [modifiedCollection, setModifiedCollection] = useState({});
  const [loading, setLoading] = useState(false);

  const [modify, setModify] = useState(true);
  const [coverflow, setCoverflow] = useState(true);
  const [modified, setModified] = useState(false);

  const [startDate, setStartDate] = useState(new Date());

  const [errors, setErrors] = useState({});

  const [creators, setCreators] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState(null);

  const [selectedLine, setSelectedLine] = useState("");

  useEffect(() => {
    init();
  }, []);

  async function init() {
    const crea = await getCreators();
    setCreators(crea);
  }

  async function handleSave() {
    //check if name slready exists
    setLoading(true);
    const exists = await checkCollectionExistsByName(modifiedCollection.name);
    if (exists) {
      const tempErrors = {...errors};
      tempErrors.name = "NAME ALREADY EXISTS";

      setErrors(tempErrors);
      setModified(false);
      setLoading(false);
      return <Navigate push to="Admin/Collections" />;
    }
    const hasLine = await checkUserCollectionByLine({
      line: modifiedCollection.line,
      _id: modifiedCollection.creator._id,
    });
    if (hasLine) {
      const ok =
        window.alert(`This user already has a ${modifiedCollection.line} collection.
        Please delete the existing collection`);
      setLoading(false);

      return;
    }

    props.onChange(modifiedCollection);

    const toSave = {...modifiedCollection};
    // toSave.image = key;

    const newCol = await createCollection(toSave);
    setLoading(false);
    window.alert("Collection created");

    props.onClose();
  }

  function handleInput(e) {
    const name = e.target.name;
    const val = e.target.value;
    const tempColl = {...modifiedCollection};
    tempColl[name] = val;
    checkChanged(tempColl);
    setModifiedCollection(tempColl);
    //validation
    validate(tempColl);
  }

  function validate(col) {
    checkChanged(col);

    const tempErrors = {...errors};
    //creator
    if (col.creator._id === "") {
      tempErrors.creator = "Creator is required";
      setModified(false);
    } else {
      tempErrors.creator = null;
    }
    //Name
    if (col.name === "") {
      tempErrors.name = "Name is required";
      setModified(false);
    } else {
      tempErrors.name = null;
    }

    //line
    if (col.line === "") {
      tempErrors.line = "Line is required";
      setModified(false);
    } else {
      tempErrors.line = null;
    }
  }
  function checkChanged(data) {
    const res = isEqual(collection, data);
    if (!res) {
      setModified(true);
    } else {
      setModified(false);
    }
  }

  function handleLineClick(type) {
    const tempCol = {...modifiedCollection};
    tempCol.line = type;
    setModifiedCollection(tempCol);
    setSelectedLine(type);
    validate(tempCol);
  }
  function handleCancel() {
    if (modified) {
      setModifiedCollection(collection);
    }
  }

  function handleStartDate(date) {
    setStartDate(date);
    const tempCol = {...modifiedCollection};
    tempCol.launchDate = date;
    setModifiedCollection(tempCol);
  }

  function handleCreatorSelect(e) {
    const crea = JSON.parse(e.target.value);
    const tempCol = {...modifiedCollection};
    tempCol.creator = {
      _id: crea._id,
      firstName: crea.firstName,
      lastName: crea.lastName,
      company: crea.company,
    };
    setSelectedCreator(crea);

    setModifiedCollection(tempCol);
    validate(tempCol);
  }

  function handleGoBack() {
    props.onClose();
  }
  return (
    <div className={Styles.frame}>
      {loading && <LoaderWin />}
      <div className={Styles.btnContainer}>
        <RegularButton
          name="save"
          white
          backHColor={Palette.col_black}
          isdisabled={!modified}
          onClick={handleSave}
        />
        <RegularButton
          name="cancel"
          white
          backHColor={Palette.col_black}
          isdisabled={!modified}
          ml="0.5rem"
          onClick={handleCancel}
        />
        <RegularButton
          name="back to collection"
          white
          backHColor={Palette.col_black}
          width="15rem"
          ml="auto"
          onClick={handleGoBack}
        />
      </div>
      <div className={Styles.barTitle}>
        <div className={Styles.barTitleText}>
          {modifiedCollection.name !== ""
            ? modifiedCollection.name
            : "New Collection"}
        </div>
      </div>

      <div className={Styles.subContainer}>
        <div className={Styles.leftSubContainer}>
          <div className={Styles.inputBlock}>
            <div className={Styles.inputTitle}>Brand</div>

            <select
              className={Styles.select}
              id="myList"
              onChange={handleCreatorSelect}
              disabled={!modify}
            >
              <option value="" selected disabled hidden>
                "Select creator"
              </option>
              {creators.map((cr) => (
                <option value={JSON.stringify(cr)} label={cr.company} />
              ))}
            </select>

            <div className={Styles.errors}>{errors.creator}</div>
          </div>
          <div className={Styles.inputBlock}>
            <div className={Styles.inputTitle}>Name</div>
            <input
              className={Styles.input}
              value={modifiedCollection.name}
              name="name"
              onChange={handleInput}
              disabled={!modify}
            />
            <div className={Styles.errors}>{errors.name}</div>
          </div>
          <div className={Styles.inputBlock}>
            <div className={Styles.inputTitle}>Line</div>

            <div className={Styles.inputContainer}>
              <div
                className={
                  selectedLine === "menswear"
                    ? Styles.btnLineOn
                    : Styles.btnLineOff
                }
                onClick={() => handleLineClick("menswear")}
              >
                Men
              </div>
              <div
                className={
                  selectedLine === "womenswear"
                    ? Styles.btnLineOn
                    : Styles.btnLineOff
                }
                onClick={() => handleLineClick("womenswear")}
              >
                Women
              </div>
              <div
                className={
                  selectedLine === "accessories"
                    ? Styles.btnLineOn
                    : Styles.btnLineOff
                }
                onClick={() => handleLineClick("accessories")}
              >
                Access
              </div>
            </div>

            <div className={Styles.errors}>{errors.line}</div>
          </div>
          <div className={Styles.inputBlock}>
            <div className={Styles.inputTitle}>Launch date</div>

            <DatePicker
              selected={startDate}
              onChange={(date) => handleStartDate(date)}
            />

            <div className={Styles.errors}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
