import React, {useEffect, useContext, useState} from "react";
import {Link, Navigate} from "react-router-dom";
import Joi from "joi";

import {Navbar} from "../../../components";

import {UserContext} from "../../../contexts";

import Styles from "./Styles";
import {changePassword, getUserById, updatePassword} from "../../../services";

export default function UserAccount() {
  const userCtx = useContext(UserContext);
  const [user, setUser] = useState({});
  const [passwordChange, setPasswordChange] = useState(false);
  const [errors, setErrors] = useState({});
  const [datas, setDatas] = useState({password: "", confirmPassword: ""});
  const [submitEnable, setSubmitEnable] = useState(false);
  const [logout, setLogout] = useState(false);
  const [username, setUsername] = useState("");
  useEffect(() => {
    init();
  }, [userCtx]);

  async function init() {
    setUser(userCtx.user);
    try {
      const tempUser = await getUserById(userCtx.user._id);
      setUsername({firstname: tempUser.firstName, lastname: tempUser.lastName});
    } catch (error) {}
  }
  const schemaUser = Joi.object({
    password: Joi.string()
      .min(5)

      .required(),
    confirmPassword: Joi.string()
      .min(5)
      .valid(Joi.ref("password"))
      .required()
      .label("Confirm password")
      .messages({"any.only": "{{#label}} does not match"}),
  }).options({abortEarly: false});

  function validate(datas) {
    const result = schemaUser.validate(datas);
    let err = {};
    if (result.error) {
      // err = {...errors};
      for (const el of result.error.details) {
        err[el.path] = el.message;
      }
      setSubmitEnable(false);
    } else {
      setSubmitEnable(true);
    }
    setErrors({...err});
  }
  function handlePasswordChange() {
    setPasswordChange(!passwordChange);
  }
  async function handleSubmit(e) {
    e.preventDefault();

    if (submitEnable) {
      //  const datas = {id:user}
      const res = await updatePassword({user, password: datas.password});
      handleBtnLogout();
    }
  }
  function handleLogChange(e) {
    const name = e.target.name;
    const val = e.target.value;

    const tempDatas = {...datas};
    tempDatas[name] = val;

    setDatas({...tempDatas});
    validate(tempDatas);
  }
  function handleBtnLogout() {
    if (localStorage.getItem("token")) localStorage.removeItem("token");
    userCtx.updateUser(null);
    setLogout(true);
  }
  return (
    <div>
      {logout && <Navigate push to={"/Home"} />}
      <Navbar />
      <div className={Styles.margin}></div>

      <div className={Styles.frame} onSubmit={handleSubmit}>
        <div className={Styles.titleContainer}>
          <div className={Styles.title}>
            {"Welcome " + username.firstname + " !"}
          </div>
        </div>

        <div className={Styles.label}>First Name</div>
        <div className={Styles.text}>{username.firstname}</div>
        <div className={Styles.label}>Last Name</div>
        <div className={Styles.text}>{username.lastname}</div>
        {user.company && <div className={Styles.label}>Brand / Company</div>}
        <div className={Styles.text}>{user.company}</div>
        <div className={Styles.inner}>
          {!passwordChange && (
            <div className={Styles.btn} onClick={handleBtnLogout}>
              LOGOUT
            </div>
          )}
          {!passwordChange && (
            <div className={Styles.btn} onClick={handlePasswordChange}>
              CHANGE PASSWORD
            </div>
          )}
          {passwordChange && (
            <form className={Styles.form}>
              <div className={Styles.inputLabel}>Confirm password</div>

              <input
                className={Styles.input}
                onChange={handleLogChange}
                type="password"
                name="password"
                placeholder="password"
                value={datas.password}
              />
              <div className={Styles.error}>{errors.password}</div>

              <div className={Styles.inputLabel}>Confirm password</div>

              <input
                className={Styles.input}
                onChange={handleLogChange}
                type="password"
                name="confirmPassword"
                placeholder="confirmPassword"
                value={datas.confirmPassword}
              />
              <div className={Styles.error}>{errors.confirmPassword}</div>

              <input className={Styles.btn} type="submit" value="SUBMIT" />
              <div className={Styles.btn} onClick={handlePasswordChange}>
                CANCEL
              </div>
            </form>
          )}
        </div>

        <Link to="/Home" className={Styles.link}>
          back to login
        </Link>
      </div>
    </div>
  );
}
