import React, {useState, useEffect, Fragment, useContext} from "react";

import Styles from "../Styles";
import find from "lodash/find";
import {cloneDeep} from "lodash/cloneDeep";
import {Icon} from "../../../../../../components";
import {Palette} from "../../../../../../constants";
import {EditProduct} from "../editProduct";
import findIndex from "lodash/findIndex";
import {ImagesContext} from "../../../../../../contexts";

export default function EditVariant(props) {
  const [coverflow, setCoverflow] = useState(true);
  const [topCoverflow, setTopCoverflow] = useState(true);
  const [coverflowAll, setCoverflowAll] = useState(true);
  const [coverflowState, setCoverflowState] = useState(true);

  const [variant, setVariant] = useState(props.variant);
  const [status, setStatus] = useState([]);
  const [modified, setModified] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [canSend, setCanSend] = useState(true);

  const [productsToSend, setProductsToSend] = useState(null);

  useEffect(() => {
    setVariant({...props.variant});

    //check prod to send from variant
    const prods = props.variant.products;
    const tpStat = [];
    prods.forEach((prod) => {
      const res = find(props.status, {id: prod, send: true});
      if (res) tpStat.push(res);
    });
    if (tpStat.length > 0) setProductsToSend(tpStat);
    else setProductsToSend(null);
  }, [props.collection, props.stageType, props.variant]);

  function handleCoverflowAll() {
    const tempCov = [];
    for (let i = 0; i < coverflow.length; i++) {
      tempCov.push({id: coverflow[i].id, display: !coverflowAll});
    }
    setCoverflowAll(!coverflowAll);
    setCoverflow(tempCov);
  }
  function handleCoverflow(id) {
    setCoverflowState(!coverflowState);
  }
  function getCoverflow(id) {
    const index = findIndex(coverflow, {id: id});
    if (index === -1) {
      return false;
    }
    return coverflow[index].display;
  }
  function handleSendOnline() {
    const conf = window.confirm("Send all variants online ?");
    if (!conf) return;
    props.onAction(
      {
        products: productsToSend,
        origin: "validProducts",
        target: "onlineProducts",
      },
      "PRODUCT-MOVE-SAVE-MULTIPLE"
    );
  }

  return (
    <div>
      <div
        className={
          //props.topCoverflow ? Styles.productFrame : Styles.productFrameOff
          Styles.productFrame
        }
      >
        <div className={Styles.productTitle}>
          <div className={Styles.productTitleText}>{variant.name}</div>
          <div className={Styles.actionContainer}>
            {/* <Icon
              icon={coverflowAll ? "list" : "coverflow"}
              size="1rem"
              color={Palette.col_white}
              hcolor={Palette.col_darkGrey}
              onClick={handleCoverflowAll}
            ></Icon>

            <Icon
              icon={props.topCoverflow ? "eye_alt" : "eye"}
              size="1rem"
              color={Palette.col_white}
              hcolor={Palette.col_darkGrey}
              onClick={handleCoverflow}
            ></Icon> */}
          </div>
        </div>
        {variant &&
          variant.products.map((prodId, index) => (
            <EditProduct
              key={index}
              productId={prodId}
              stageType={props.stageType}
              onAction={props.onAction}
              onStatusChange={props.onStatusChange}
              topCoverflow={getCoverflow(prodId)}
              coverflow={coverflowState}
              collection={props.collection}
            />
          ))}
      </div>
    </div>
  );
}
