import React, {useState, Suspense, lazy, useContext} from "react";
import {Link} from "react-router-dom";

import Styles from "../Styles";
import Joi from "joi";
//import {ForgotPassword} from "../forgotPassword";
import {UserContext} from "../../../contexts";
import {logUser} from "../../../services";

const ForgotPassword = lazy(() => import("../forgotPassword/ForgotPassword"));

export default function Login(props) {
  const [errors, setErrors] = useState({email: "", password: ""});
  const [forgotPasswordActive, setForgotPasswordActive] = useState(false);
  const userContext = useContext(UserContext);
  const [user, setUser] = useState({email: "", password: ""});
  const [logInvalid, setLogInvalid] = useState("");
  const [submitEnable, setSubmitEnable] = useState(false);

  const schemaUser = Joi.object({
    email: Joi.string()
      .min(5)
      .max(255)
      .email({tlds: {allow: false}})
      .required(),
    password: Joi.string().required(),
  }).options({abortEarly: true});

  function validateUser(user) {
    const result = schemaUser.validate(user);
    let err = {};
    if (result.error) {
      // err = {...errors};
      for (const el of result.error.details) {
        err[el.path] = el.message;
      }
      setSubmitEnable(false);
    } else setSubmitEnable(true);
    setErrors({...err});
  }

  async function handleLogin(e) {
    e.preventDefault();
    if (submitEnable) {
      const res = await logUser(user);
      if (res) {
        if (localStorage.getItem("token")) localStorage.removeItem("token");
        localStorage.setItem("token", res.data.token); //Replaced by user
        userContext.updateUser(res.data);
        props.onlogin();
      } else {
        setLogInvalid("Invalid username or password");
      }
    }
  }
  function handleLogChange(e) {
    setLogInvalid("");

    const index = e.target.name;
    const tempUser = {...user};
    tempUser[index] = e.target.value;
    validateUser(tempUser);

    setUser(tempUser);
  }
  function handelCLoseForgotPassword() {
    setForgotPasswordActive(false);
  }
  function handelOpenForgotPassword() {
    setForgotPasswordActive(true);
  }
  return (
    <div>
      <div className={Styles.logInvalid}>{logInvalid}</div>

      <div className={Styles.loginContainer}>
        <form onSubmit={handleLogin}>
          <div className={Styles.inputContainer}>
            <div className={Styles.inputLabel}>email</div>
            <input
              className={Styles.input}
              onChange={handleLogChange}
              placeholder="example@example.com"
              type="text"
              name="email"
            />
            <div className={Styles.error}>{errors.email}</div>
          </div>

          <div className={Styles.inputContainer}>
            <div className={Styles.inputLabel}>password</div>

            <input
              className={Styles.input}
              onChange={handleLogChange}
              placeholder="password"
              type="password"
              name="password"
            />
            <div className={Styles.error}>{errors.password}</div>
            <div className={Styles.link} onClick={handelOpenForgotPassword}>
              Forgotten password ?
            </div>
          </div>

          <input className={Styles.submit} type="submit" value="login" />
          <div className={Styles.text}>or</div>
          <div className={Styles.btn} onClick={props.onRegister}>
            Register
          </div>
        </form>
      </div>
      {forgotPasswordActive && (
        <Suspense fallback={<div>Loading...</div>}>
          <ForgotPassword onClose={handelCLoseForgotPassword} />
        </Suspense>
      )}
    </div>
  );
}
