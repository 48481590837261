import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import Styles from "./Styles";
import {Icon, Loader, Navbar, RegularButton} from "../../../components";
import {Palette} from "../../../constants";
import {initial} from "lodash";
import {getPage, savePage} from "../../../services/api/pages";
import addFile from "../../../assetsIcons/icons/add_file.png";
import {
  getCollectionBckImage,
  postCompressSingleImage,
  setCollectionBckImage,
} from "../../../services";
export default function Pages() {
  const [page, setPage] = useState({});
  const [refPage, setRefPage] = useState({});
  const [errors, setErrors] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [image, setImage] = useState(addFile);
  const [imageStyle, setImageStyle] = useState({
    width: "20rem",
    height: "20rem",
    backgroundColor: Palette.col_lightMediumGrey,
    margin: "0 auto",
    backgroundImage: "url(" + addFile + ")",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [imHasChanged, setImHasChanged] = useState(false);
  useEffect(() => {
    init();
  }, []);
  async function init() {
    const res = await getPage();
    const titles = res[0];
    if (res.length !== 0) {
      setPage({
        menswear: titles.menswear,
        womenswear: titles.womenswear,
        accessories: titles.accessories,
      });
      setRefPage({
        menswear: titles.menswear,
        womenswear: titles.womenswear,
        accessories: titles.accessories,
      });
    }
    //image
    setIsLoading(true);
    const file = await getCollectionBckImage();
    const im = URL.createObjectURL(file);
    setImage(im);
    refreshImageStyle(im);
    setIsLoading(false);
  }
  function refreshImageStyle(im) {
    console.log("im-----", im);
    setImageStyle({
      width: "20rem",
      height: "20rem",
      backgroundColor: Palette.col_lightMediumGrey,
      margin: "0 auto",
      backgroundImage: "url(" + im + ")",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50% 50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    });
  }
  function handleTitleChange(e) {
    const name = e.currentTarget.name;
    const val = e.currentTarget.value;
    const tempPage = {...page};
    tempPage[name] = val;
    validate(tempPage);
    setPage(tempPage);
  }
  function validate(datas) {
    setSubmit(false);

    //has changed
    if (
      datas.menswear !== refPage.menswear ||
      datas.womenswear !== refPage.womenswear ||
      datas.accessories !== refPage.accessories
    )
      setSubmit(true);
    const errs = {
      menswear: null,
      womenswear: null,
      accessories: null,
    };
    if (datas.menswear === "" || datas.menswear == null) {
      errs.menswear = "no empty title";
      setSubmit(false);
    }
    if (datas.womenswear === "" || datas.womenswear == null) {
      errs.womenswear = "no empty title";
      setSubmit(false);
    }
    if (datas.accessories === "" || datas.accessories == null) {
      errs.accessories = "no empty title";
      setSubmit(false);
    }
    setErrors(errs);
  }
  async function submitPage() {
    setRefPage(page);
    await savePage({
      menswear: page.menswear,
      womenswear: page.womenswear,
      accessories: page.accessories,
    });
    setSubmit(false);
  }
  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  function handleImageDrop(e) {
    console.log("drop");
    e.preventDefault();
    e.stopPropagation();
    let file = [...e.dataTransfer.files];

    if (file[0]) {
      readUrlFromFile(file[0]);
    }
  }
  async function readUrlFromFile(file) {
    setIsLoading(true);
    //change file name
    setImage(null);
    setErrors(null);
    const name = file.name.split(".");
    const ext = name[1];
    const filename = "collection";
    const type = "image/" + ext;
    const blob = file.slice(0, file.size, type);
    let newFile = new File([blob], filename, {type: type});

    //check file type
    const fileType = newFile.type.split("/")[1];

    if (
      !(
        fileType === "webp" ||
        fileType === "png" ||
        fileType === "jpg" ||
        fileType === "jpeg"
      )
    ) {
      setIsLoading(false);
      setImage(image);
      window.alert("wrong file format");
      setIsLoading(false);
      return;
    }
    try {
      newFile = await postCompressSingleImage({
        image: file,
        filename: filename,
      });
    } catch (error) {
      window.alert("error");
    }

    if (newFile != null) {
      const im = URL.createObjectURL(newFile);
      setImage(newFile);
      console.log("nf", newFile);
      console.log("im", im.toString());
      const res = await setCollectionBckImage(newFile);
      refreshImageStyle(im);
      setImHasChanged(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      // setImage(image);
    }
  }

  function submitImage() {
    setImHasChanged(false);
  }
  return (
    <div className={Styles.frame}>
      <Navbar />
      <div className={Styles.margin}></div>
      <div className={Styles.innerFrame}>
        <div className={Styles.navigation}>
          <Link className={Styles.navItem} to="/Home">
            Home
          </Link>
          <Icon
            icon="arrowheadright"
            color={Palette.col_mediumGrey}
            hcolor={Palette.col_mediumGrey}
            size="1.3rem"
          />

          <Link className={Styles.navItem} to="/Admin">
            Administration
          </Link>

          <Icon
            icon="arrowheadright"
            color={Palette.col_mediumGrey}
            hcolor={Palette.col_mediumGrey}
            size="1.3rem"
          />
          <div className={Styles.navItem}>Pages</div>
        </div>
        <div className={Styles.maintitle}>Welcome page</div>
        <div className={Styles.mainContainer}>
          <div className={Styles.container}>
            <div className={Styles.title}>Titles</div>
            <div className={Styles.subTitle}>Title menswear</div>
            <div className={Styles.inputTitle}>
              <input
                type="text"
                className={Styles.inputTitle}
                name="menswear"
                value={page.menswear ? page.menswear : ""}
                onChange={handleTitleChange}
              />
            </div>
            <div className={Styles.errors}>{errors ? errors.menswear : ""}</div>
            <div className={Styles.subTitle}>Title womenswear</div>
            <div className={Styles.inputTitle}>
              <input
                type="text"
                className={Styles.inputTitle}
                name="womenswear"
                value={page.womenswear ? page.womenswear : ""}
                onChange={handleTitleChange}
              />
            </div>
            <div className={Styles.errors}>
              {errors ? errors.womenswear : ""}
            </div>
            <div className={Styles.subTitle}>Title accessories</div>
            <div className={Styles.inputTitle}>
              <input
                type="text"
                className={Styles.inputTitle}
                name="accessories"
                value={page.accessories ? page.accessories : ""}
                onChange={handleTitleChange}
              />
            </div>
            <div className={Styles.errors}>
              {errors ? errors.accessories : ""}
            </div>
            <RegularButton
              name="save"
              white
              mt="1rem"
              isdisabled={!submit}
              onClick={submitPage}
            />
          </div>
          <div className={Styles.container}>
            <div className={Styles.title}>Background image</div>
            <div
              style={imageStyle}
              onDrop={handleImageDrop}
              onDragOver={handleDragOver}
              //className={Styles.imageContainer}
            >
              {isLoading && <Loader />}
            </div>
            <RegularButton
              name="save"
              white
              mt="1rem"
              isdisabled={!imHasChanged}
              onClick={submitImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
