import {css, keyframes} from "@emotion/css";

import {Palette} from "../../constants";

const animate = keyframes`
from {transform: rotate(0deg)}
to {transform: rotate(-360deg)}`;

const animateColor = keyframes`
from {color: black}
to {color: darkgrey}`;

const Styles = {
  frame: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  loader: css({
    height: "50px",
    width: "50px",
    borderRadius: "100%",
    background: "conic-gradient( orange, " + Palette.col_lightMediumGrey + ")",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animation: animate + " 1s linear infinite ",
  }),
  inner: css({
    height: "40px",
    width: "40px",
    borderRadius: "100%",
    backgroundColor: Palette.col_lightMediumGrey,
  }),
  message: css({
    marginTop: "5px",

    animation: animateColor + " 2s ease infinite alternate-reverse",
  }),
};

export default Styles;
