import {css} from "@emotion/css";
import {Palette} from "../../../../../../constants";

const image = css({
  height: "7rem",
  width: "7rem",
  marginRight: "1rem",
  backgroundColor: Palette.col_lightMediumGrey,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const btnAction = css({
  cursor: "pointer",
  userSelect: "none",
  width: "7rem",
  height: "1.5rem",
  lineHeight: "1.5rem",
  //border: "1px solid " + Palette.col_mediumGrey,
  textAlign: "center",
  backgroundColor: Palette.col_white,
});
const btnLine = css({
  fontSize: "0.8rem",
  textAlign: "center",
  width: "4rem",
  height: "1rem",
  lineHeight: "1rem",
  marginRight: "0.3rem",
  border: "1px solid " + Palette.col_mediumGrey,
  userSelect: "none",
  cursor: "pointer",
});

const Styles = {
  btnView: css({
    marginLeft: "auto",
    paddingRight: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
    width: "2.5rem",
  }),
  productFrameOff: css({
    display: "none",
  }),
  productFrame: css({
    width: "100%",
    // height: "20rem",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    border: "2px solid " + Palette.col_lightGrey,
    borderBottom: "none",
  }),

  productTitle: css({
    backgroundColor: Palette.col_lightGrey,
    color: Palette.col_white,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "0.3rem 0",
    //height: "2rem",
    borderBottom: "1px solid " + Palette.col_lightMediumGrey,
  }),
  actionContainer: css({
    display: "flex",
    marginLeft: "auto",
    paddingRight: "0.4rem",
    justifyContent: "flex-end",
    flexWrap: "wrap",

    alignItems: "center",
  }),
  btnLineOff: css(btnLine, {
    color: Palette.col_mediumGrey,
    backgroundColor: Palette.col_lightGrey,
    cursor: "initial",

    "&:hover": {
      backgroundColor: Palette.col_lightGrey,

      color: Palette.col_mediumGrey,
    },
  }),
  btnLineOn: css(btnLine, {
    backgroundColor: Palette.col_lightGrey,
    color: Palette.col_white,
    "&:hover": {
      backgroundColor: Palette.col_mediumGrey,
      color: Palette.col_white,
    },
  }),
  btnOnlineOff: css(btnLine, {
    width: "6rem",
    color: Palette.col_mediumGrey,
    backgroundColor: Palette.col_lightGrey,
    cursor: "initial",

    "&:hover": {
      backgroundColor: Palette.col_lightGrey,

      color: Palette.col_mediumGrey,
    },
  }),
  btnOnlineOn: css(btnLine, {
    width: "6rem",
    marginRight: "1rem",

    backgroundColor: Palette.col_lightGrey,
    color: Palette.col_white,
    "&:hover": {
      backgroundColor: Palette.col_mediumGrey,
      color: Palette.col_white,
    },
  }),
  productTitleText: css({
    paddingLeft: "0.5rem",
  }),
  productContainer: css({
    display: "flex",
    flexDirection: "column",
  }),
  productContainerOff: css({
    display: "none",
  }),
  dataContainer: css({
    display: "flex",
    // display: "grid",
    // gridTemplateColumns: "1fr 1fr",
    //height: "7rem",
    // width: "100%",
    flexWrap: "wrap",
    backgroundColor: Palette.col_white,
    justifyContent: "center",
  }),
  leftContainer: css({
    minWidth: "10rem",

    flexBasis: "10rem",
    flexGrow: 1,
    flexShrink: 1,
    // gridColumn: 1,
    display: "flex",
    flexWrap: "wrap",
  }),
  inputContainer: css({
    minWidth: "10rem",
    // maxWidth: "50%",
    padding: "0.5rem 1rem",
    flexBasis: "10rem",
    flexGrow: 1,
    flexShrink: 1,
  }),
  editWrapper: css({
    display: "flex",
    gap: "1rem",
    alignItem: "center",
  }),
  inputTitle: css({
    color: Palette.col_lightMediumGrey,
    paddingBottom: "0.2rem",
    marginBottom: "0.3rem",
    borderBottom: "1px solid " + Palette.col_lightGrey,
  }),
  input: css({}),
  inputDescription: css({
    width: "98%",
  }),
  inputError: css({
    fontSize: "0.8rem",
    color: "red",
  }),

  rightContainer: css({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    backgroundColor: Palette.col_xxlightGrey,
    marginBottom: "0.5rem",
  }),

  imageSecondary: css({
    minWidth: "15rem",
    flexBasis: "15rem",
    flexGrow: 1,
    flexShrink: 1,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  }),
  image1: css(image, {
    marginTop: "1rem",
  }),
  image2: css(image, {
    marginTop: "1rem",
  }),
  image3: css(image, {
    marginTop: "1rem",
  }),
  image4: css(image, {
    marginTop: "1rem",
  }),

  btnContainer: css({
    display: "flex",
    alignItems: "center",
    height: "1.7rem",
    backgroundColor: Palette.col_lightGrey,
  }),
  btnActionOn: css(btnAction, {}),
  pricesInputContainer: css({
    display: "flex",
    height: "1rem",
    alignItems: "center",
    paddingBottom: "0.5rem",
  }),
  currency: css({
    margin: "0 0.5rem",
  }),
};

export default Styles;
