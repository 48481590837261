import {css} from "@emotion/css";
import {Palette} from "../../../constants";

const Styles = {
  frame: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  form: css({
    display: "flex",
    flexDirection: "column",
  }),
  margin: css({
    height: "50px",
  }),
  titleContainer: css({
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    margin: "2rem 0",
  }),
  title: css({
    width: "90vw",
    fontSize: "2rem",
    textAlign: "center",
    paddingBottom: "1rem",
    borderBottom: "1px solid " + Palette.col_lightGrey,
  }),
  label: css({
    width: "20%",
    fontSize: "1.3rem",
    color: Palette.col_darkGrey,
    marginTop: "1rem",
    paddingBottom: "0.5rem",
    borderBottom: "1px solid " + Palette.col_lightGrey,
  }),
  text: css({
    width: "20%",

    fontSize: "1.3rem",
    color: Palette.col_mediumGrey,
  }),
  input: css({
    border: "none",
    outline: "none",
    width: "12rem",
    height: "2rem",
    borderRadius: "0",
  }),
  inputContainer: css({
    marginBottom: "0.4rem",
  }),
  inputLabel: css({
    userSelect: "none",
    marginBottom: "0.2rem",
    fontFamily: "Helvetica Neue",
    color: Palette.col_lightMediumGrey,
    // "@media(max-width: 500px)": {
    //   color: Palette.col_black,
    // },
  }),
  inner: css({
    marginTop: "1rem",
  }),
  error: css({
    color: "red",
    margin: "0.2rem 0",
    fontSize: "0.8rem",
    height: "0.8rem",
    lineHeight: "1rem",
    fontFamily: "Helvetica Neue",
    width: "10rem",
    height: "auto",
  }),
  btn: css({
    userSelect: "none",
    width: "12rem",
    lineHeight: "2rem",
    textAlign: "center",
    fontSize: "0.9rem",
    marginTop: "0.5rem",
    border: "none",
    outline: "none",
    height: "2rem",
    cursor: "pointer",
    fontFamily: "Helvetica Neue",
    color: Palette.col_white,
    backgroundColor: "black",
    mBottom: "0.5rem",

    "&:hover": {
      // backgroundColor: Palette.col_xlightGrey,
      textDecoration: "underline",
      // boxShadow: "inset 0 0 10px red",
    },
  }),
  link: css({
    marginTop: "1rem",
    color: Palette.col_darkGrey,
  }),
};

export default Styles;
