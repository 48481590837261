import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import Joi from "joi/dist/joi-browser.min.js";

import {getProductById, updateProduct} from "../services";

class Product {
  constructor() {
    this.id = "";
    this.reference = {
      line: "",
      category: "",
      model: "",
      name: "",
      color: "",
      reference: "",
      description: "",
      prices: {
        priceEUR: "",
        priceUSD: "",
        priceJPY: "",
        priceGPB: "",
        priceCNY: "",
      },
      images: [{image: null}, {image: null}, {image: null}, {image: null}],
    };
    this.modified = {
      line: "",
      category: "",
      model: "",
      name: "",
      color: "",
      reference: "",
      description: "",
      prices: {
        priceEUR: "",
        priceUSD: "",
        priceJPY: "",
        priceGPB: "",
        priceCNY: "",
      },
      images: [{image: null}, {image: null}, {image: null}, {image: null}],
    };
    this.imagesToSave = [
      {image: null},
      {image: null},
      {image: null},
      {image: null},
    ];
    this.imagesToDelete = [
      {image: null},
      {image: null},
      {image: null},
      {image: null},
    ];
    this.status = {
      modified: false,
      canSave: false,
      canSend: true,
      coverflow: true,
      display: false,
      errors: {},
    };
  }
  async init(id) {
    return new Promise(async (resolve, rejected) => {
      const prod = await getProductById(id);
      this.id = id;
      this.reference.line = prod.line;
      this.reference.category = prod.category;
      this.reference.model = prod.model;
      this.reference.name = prod.name;
      this.reference.color = prod.color;
      this.reference.reference = prod.reference;
      this.reference.description = prod.description;
      this.reference.prices = {
        priceEUR: prod.prices.priceEUR,
        priceUSD: prod.prices.priceUSD,
        priceJPY: prod.prices.priceJPY,
        priceGPB: prod.prices.priceGPB,
        priceCNY: prod.prices.priceCNY,
      };
      this.reference.images = [
        {image: prod.images[0].image},
        {image: prod.images[1].image},
        {image: prod.images[2].image},
        {image: prod.images[3].image},
      ];

      this.modified = cloneDeep(this.reference);

      resolve(true);
    });
  }
  imageChange(datas) {
    this.modified.images[datas.nb].image = datas.imageName;
    this.imagesToSave[datas.nb].image = datas.file;
    this.validate(this.modified);
  }
  inputChange(datas) {
    this.modified[datas.name] = datas.val;
    this.status.errors = {};
    this.validate(this.modified);
  }
  inputPriceChange(datas) {
    this.modified.prices.priceEUR = datas.val;
    this.status.errors = {};
    this.validate(this.modified);
  }
  cancelChanges() {
    this.modified = cloneDeep(this.reference);
    this.status.errors = {};
    this.validate(this.modified);
  }
  validate(data) {
    this.status.modified = true;
    this.status.canSave = true;
    this.status.canSend = true;
    const res = isEqual(this.reference, this.modified);

    if (!res) {
      this.status.modified = true;
      this.status.canSave = true;
      this.status.canSend = true;
    } else {
      this.status.modified = false;
      this.status.canSave = false;
      this.status.canSend = true;
    }

    //images

    for (let i = 0; i < this.imagesToSave.length; i++) {
      if (this.imagesToSave[i].image !== "null") {
        this.status.modified = true;
        this.status.canSave = true;
        this.status.canSend = true;
      }
    }

    const err = validate(data);
    if (err) {
      this.status.modified = true;
      this.status.canSave = false;
      this.status.canSend = false;
    }
    if (err) this.status.errors = err;
  }

  //--------------TODO
  save() {
    return new Promise(async (resolve, reject) => {
      //renameimages
      const tempImages = [];
      for (let i = 0; i < this.modified.images.length; i++) {
        if (this.modified.images[i].image) {
          tempImages.push(this.modified.images[i].image.slice(3));
        }
      }

      const datasProd = {
        id: this.id,
        category: this.modified.category,
        model: this.modified.model,
        name: this.modified.name,
        color: this.modified.color,
        reference: this.modified.reference,
        description: this.modified.description,
        prices: {...this.modified.prices},
        images: {...this.modified.images},
      };
      const data = new FormData();
      for (let i = 0; i < this.imagesToSave.length; i++) {
        const tempFile = this.imagesToSave[i].image;
        if (tempFile) {
          let filename = tempFile.name;
          filename = filename.slice(3);
          const type = tempFile.type;
          const file = tempFile.slice(0, tempFile.size, type);
          const newFile = new File([file], filename, {type: type});
          data.append("file", newFile);
        }
      }
      const tempProd = JSON.stringify(datasProd);
      data.append("product", tempProd);
      const res = await updateProduct(data);
      resolve(this);
    });
  }
  isModified(status) {
    this.status.isModified = status;
  }
  canSave(status) {
    this.status.canSave = status;
  }
  canSend(status) {
    this.status.canSend = status;
  }
  coverflow(status) {
    this.status.coverflow = status;
  }
  display(status) {
    this.status.display = status;
  }
  errors(err) {
    this.status.errors = err;
  }
}

function validate(data) {
  const priceSchema = Joi.object().keys({
    priceEUR: Joi.number().greater(0).required(),
    priceUSD: Joi.number().greater(0).allow(null),
    priceJPY: Joi.number().greater(0).allow(null),
    priceGPB: Joi.number().greater(0).allow(null),
    priceCNY: Joi.number().greater(0).allow(null),
  });
  const productSchema = Joi.object({
    line: Joi.string().required(),
    category: Joi.string().required(),
    model: Joi.string().required(),
    name: Joi.string().required(),
    color: Joi.string().required(),
    reference: Joi.string().required(),
    description: Joi.string().required(),
    prices: priceSchema,
    images: Joi.array(),
  });

  const result = productSchema.validate(data, {abortEarly: false});
  if (!result.error) return null;
  const errors = {};
  for (let item of result.error.details) errors[item.path[0]] = item.message;

  return errors;
}

export default Product;
