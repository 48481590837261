import {css} from "@emotion/css";
import * as icons from "../../assetsIcons";

const logos = {...icons.default};

const getStyles = (props) => {
  const {icon, color, hcolor, size, cursor} = {...props};
  const image = logos[icon ? icon : {}];

  const bgColor = color ? color : "black";
  const hoverColor = hcolor ? (hcolor === "none" ? bgColor : hcolor) : "red";
  const iconSize = size ? size : "50px";
  const cursorType = cursor ? cursor : "pointer";

  const Styles = {
    back: css({
      height: "100%",
      width: "100%",
      backgroundColor: bgColor,
      cursor: cursorType,

      "&:hover": {
        backgroundColor: hoverColor,
      },
    }),
    icon: css({
      height: iconSize,
      width: iconSize,
      mask: "url(" + image + ")",
      maskRepeat: "no-repeat",
      maskSize: "cover",
    }),
  };

  return Styles;
};

export default getStyles;
