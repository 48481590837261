import {css} from "@emotion/css";
import {Palette} from "../../constants";

const Styles = {
  frame: css({
    position: "fixed",
    top: "0",
    width: "100vw",
    zIndex: 9,
  }),
  barFrame: css({
    width: "100vw",
    backgroundColor: Palette.col_darkGrey,
    height: "50px",
    display: "flex",
    alignItems: "center",
  }),
  logo: css({
    marginLeft: "calc(50% - 60px - 1rem)",
    height: "24px",
  }),
  menuBtn: css({
    margin: "0 0.5rem 0 0.5rem",
  }),
  menuFrame: css({
    backgroundColor: "rgba(255, 255, 255, 0)",
    display: "flex",
    width: "100vw",
    zIndex: 9,
    //height: "calc(100vh - 50px)",
  }),
  menu: css({
    backgroundColor: Palette.col_mediumGrey,

    display: "flex",
    flexDirection: "column",
    //height: "20rem",
    width: "15rem",
  }),
  btn: css({
    height: "3rem",
    backgroundColor: Palette.col_darkGrey,
    lineHeight: "3rem",
    //margin: "1px 0",
    paddingLeft: "1rem",
    cursor: "pointer",
    userSelect: "none",
    color: Palette.col_white,
    textDecoration: "none",
    zIndex: "10",
    "&:hover": {
      backgroundColor: Palette.col_white,
      color: Palette.col_darkGrey,
    },
  }),
  btnAcc: css({
    borderTop: "1px solid " + Palette.col_mediumGrey,
    height: "3rem",
    backgroundColor: Palette.col_darkGrey,
    lineHeight: "3rem",
    //margin: "1px 0",
    paddingLeft: "1rem",
    cursor: "pointer",
    userSelect: "none",
    color: Palette.col_white,
    textDecoration: "none",
    zIndex: "10",
    "&:hover": {
      backgroundColor: Palette.col_white,
      color: Palette.col_darkGrey,
    },
  }),
  btnAlt: css({
    height: "3rem",
    backgroundColor: Palette.col_mediumGrey,
    lineHeight: "3rem",
    //margin: "1px 0",
    paddingLeft: "1rem",
    cursor: "pointer",
    userSelect: "none",
    color: Palette.col_white,
    textDecoration: "none",
    zIndex: "10",
    "&:hover": {
      backgroundColor: Palette.col_white,
      color: Palette.col_darkGrey,
    },
  }),
};

export default Styles;
