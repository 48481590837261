import React, {useState, useEffect, useContext} from "react";
import {getStyles} from "./Styles";

import {Loader} from "../Loader";

//import {CollectionsContext, CollectionsImagesContext} from "../../contexts";

import addFile from "../../assetsIcons/icons/add_file.png";
import {
  getCollectionImageById,
  postCompressSingleImagePng,
} from "../../services";

export default function ImageBlockLogo(props) {
  const Styles = getStyles(props);

  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    init();
  }, [props.image]);

  async function init() {
    let imSource = null;
    if (props.image) {
      imSource = props.image;
    }
    if (imSource == null) {
      setImage(addFile);

      setErrors("Need a logo");

      return;
    }

    if (typeof imSource === "string") {
      let cache = true;
      if (props.nocache === true) cache = false;

      const im = await getCollectionImageById(imSource, cache);
      const final = URL.createObjectURL(im);
      setErrors(null);
      setImage(final);
      return;
    }
    if (typeof imSource === "object") {
      const final = URL.createObjectURL(imSource);
      setErrors(null);
      setImage(final);
      return;
    }
    setImage(addFile);
    if (props.imageNb === 0) {
      setErrors("Need a main image");
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    //if (props.locked) return;
    let file = [...e.dataTransfer.files];

    if (file[0]) {
      setIsLoading(true);
      readUrlFromFile(file[0]);
    }
  }
  async function readUrlFromFile(file) {
    //change file name
    setImage(null);
    setErrors(null);
    const filename = props.collId + "-LOGO";
    const type = "image/*";
    const blob = file.slice(0, file.size, type);
    let newFile = new File([blob], filename, {type: type});

    //check file type
    const fileType = file.name.split(".").pop();

    if (
      !(
        fileType === "webp" ||
        fileType === "png" ||
        fileType === "jpg" ||
        fileType === "jpeg"
      )
    ) {
      setIsLoading(false);
      setImage(image);
      window.alert("wrong file format");

      return;
    }

    newFile = await postCompressSingleImagePng({
      image: file,
      filename: filename,
    });

    const im = URL.createObjectURL(newFile);
    setIsLoading(false);
    setImage(im);
    props.onChange(newFile, filename);
  }

  function handleDelete() {
    setImage(addFile);
    props.onChange(props.imageNb, null);
    if (props.imageNb === 0) {
      setErrors("Need a main image");
    }
  }

  return (
    <div
      className={Styles.frame}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {errors && <div className={Styles.error}>{errors}</div>}
      {!image && isLoading && <Loader />}
      {image && <img src={image} alt="" className={Styles.image} />}
    </div>
  );
}
