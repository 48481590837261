import {css} from "@emotion/css";
import {Palette} from "../../../../../constants";
const catFrame = css({
  width: "100%",
  boxSizing: "border-box",
  border: "2px solid red",
});
const btnLine = css({
  fontSize: "0.8rem",
  textAlign: "center",
  width: "4rem",
  height: "1rem",
  lineHeight: "1rem",
  marginRight: "0.3rem",
  border: "1px solid " + Palette.col_mediumGrey,
  userSelect: "none",
  cursor: "pointer",
});

const Styles = {
  frame: css({
    backgroundColor: "lightgreen",
    //height: "20vh",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }),
  categoryFrame: {
    validProducts: css({
      width: "100%",
      boxSizing: "border-box",
      border: "2px solid " + Palette.col_orange,
    }),
    invalidProducts: css({
      width: "100%",
      boxSizing: "border-box",
      border: "2px solid red",
    }),
    onlineProducts: css({
      width: "100%",
      boxSizing: "border-box",
      border: "2px solid " + Palette.col_darkGrey,
    }),
  },

  productFrameOff: css({
    display: "none",
  }),
  productFrame: css({
    width: "100%",
    boxSizing: "border-box",
    border: "2px solid " + Palette.col_lightMediumGrey,
  }),
  productTitle: css({
    backgroundColor: Palette.col_lightMediumGrey,
    color: Palette.col_white,
    display: "flex",
    alignItems: "center",
    height: "2rem",
  }),
  productTitleText: css({
    paddingLeft: "0.5rem",
  }),
  actionContainer: css({
    display: "flex",
    marginLeft: "auto",
    paddingRight: "0.4rem",
    justifyContent: "space-between",
    width: "2.5rem",

    flexWrap: "wrap",

    alignItems: "center",
  }),
  btnOnlineOn: css(btnLine, {
    width: "6rem",
    marginRight: "1rem",

    backgroundColor: Palette.col_lightMediumGrey,
    color: Palette.col_white,
    "&:hover": {
      backgroundColor: Palette.col_mediumGrey,
      color: Palette.col_white,
    },
  }),
  btnOnlineOff: css(btnLine, {
    width: "6rem",
    color: Palette.col_mediumGrey,
    backgroundColor: Palette.col_lightMediumGrey,
    cursor: "initial",

    "&:hover": {
      backgroundColor: Palette.col_lightGrey,

      color: Palette.col_mediumGrey,
    },
  }),
  btnLineOff: css(btnLine, {
    color: Palette.col_mediumGrey,
    backgroundColor: Palette.col_lightMediumGrey,
    cursor: "initial",

    "&:hover": {
      backgroundColor: Palette.col_lightMediumGrey,

      color: Palette.col_mediumGrey,
    },
  }),
  btnLineOn: css(btnLine, {
    backgroundColor: Palette.col_lightMediumGrey,
    color: Palette.col_white,
    "&:hover": {
      backgroundColor: Palette.col_mediumGrey,
      color: Palette.col_white,
    },
  }),
};

export default Styles;
