import {css} from "@emotion/css";
import {Palette} from "../../../constants";
import addFile from "../../../assetsIcons/icons/add_file.png";

const Styles = {
  frame: css({
    minHeight: "100vh",
    width: "100vw",
    //overflowY: "hidden",
  }),
  margin: css({
    height: "50px",
  }),
  innerFrame: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  navigation: css({
    display: "flex",
    width: "100vw",
    alignItems: "center",

    margin: "1rem 0 1rem 4rem",
  }),
  navItem: css({
    cursor: "pointer",
    textDecoration: "none",
    color: Palette.col_darkGrey,
    "&:hover": {
      color: Palette.col_orange,
    },
  }),
  separator: css({
    color: "blue",
  }),
  maintitle: css({
    borderBottom: "1px solid " + Palette.col_darkGrey,
    fontSize: "2rem",
    padding: "1rem 0",
    //marginBottom: "1rem",
    width: "90%",
    //textAlign: "center",
  }),
  title: css({
    borderBottom: "1px solid " + Palette.col_darkGrey,
    fontSize: "1.8rem",
    paddingBottom: "1rem",
    marginBottom: "1rem",
    width: "90%",
  }),
  subTitle: css({
    fontSize: "1.3rem",
    marginTop: "1rem",
  }),
  mainContainer: css({
    marginTop: "3rem",
    width: "30rem",
    display: "flex",
    // backgroundColor: Palette.col_xxlightGrey,
    padding: "1rem",

    justifyContent: "space-around",
  }),
  container: css({
    margin: "3rem 1rem 1rem 1rem",
    width: "30rem",
    minWidth: "30rem",
    display: "flex",
    backgroundColor: Palette.col_xxlightGrey,
    padding: "1rem",
    flexDirection: "column",
    //justifyContent: "center",
  }),
  inputTitle: css({
    width: "20rem",
    marginTop: "0.3rem",
  }),
  errors: css({
    color: "red",
    marginTop: "0.1rem",
  }),
  imageContainer: css({
    width: "20rem",
    height: "20rem",
    backgroundColor: Palette.col_lightMediumGrey,
    margin: "0 auto",
    backgroundImage: "url(" + addFile + ")",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  loader: css({
    margin: "auto",
  }),
};

export default Styles;
