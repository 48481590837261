import {css} from "@emotion/css";
import {Palette} from "../../../constants";

import deleteImage from "../../../assets/images/delete.png";
const Styles = {
  frame: css({
    minHeight: "100vh",
    width: "100vw",
  }),
  margin: css({
    height: "50px",
  }),
  innerFrame: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  navigation: css({
    display: "flex",
    width: "100vw",
    alignItems: "center",

    margin: "1rem 0 1rem 4rem",
  }),
  navItem: css({
    cursor: "pointer",
    textDecoration: "none",
    color: Palette.col_darkGrey,
    "&:hover": {
      color: Palette.col_orange,
    },
  }),
  subTitle: css({
    borderBottom: "1px solid " + Palette.col_darkGrey,
    lineHeight: "0.9rem",
    height: "0.9rem",
    fontSize: "0.9rem",
    padding: "0.9rem 0",
    marginBottom: "1rem",
    width: "90%",
    textAlign: "center",
    marginTop: "-1rem",
  }),
  title: css({
    fontSize: "1.5rem",
    padding: "1rem 0",
    lineHeight: "1.5rem",
    width: "90%",
    textAlign: "center",
  }),
  menuBar: css({
    display: "flex",
    width: "90%",
    alignItems: "center",
    marginBottom: "1rem",
  }),
  select: css({
    height: "2rem",
    marginLeft: "auto",
    marginRight: "0.5rem",
  }),

  message: css({
    fontSize: "2rem",
    marginTop: "2rem",
  }),
  imagesList: css({
    display: "flex",
    flexWrap: "wrap",
    width: "90%",
  }),
  dropCard: css({
    height: "10rem",
    width: "10rem",
    boxSizing: "border-box",
    border: "5px dashed grey",
    color: "gray",
    fontSize: "2rem",
    lineHeight: "3rem",
    textAlign: "center",
    margin: "0.5rem",
  }),
  imageCard: css({
    height: "10rem",
    width: "10rem",
    backgroundColor: "dodgerblue",
    margin: "0.5rem",
  }),
  imageFrame: css({}),
  imageLink: css({
    width: "100%",
    backgroundColor: "rgba(250,250,250,0.7)",
    borderTop: "1px solid grey",
    borderRight: "1px solid grey",
    borderLeft: "1px solid grey",
  }),
  imageMenu: css({
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 0.5rem",
    height: "2rem",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    bottom: "0",
    backgroundColor: "rgba(250,250,250,0.7)",
    borderBottom: "1px solid grey",
    borderRight: "1px solid grey",
    borderLeft: "1px solid grey",
  }),
  off: css({
    display: "none",
  }),
  loadLink: css({
    border: "1px solid grey",
    backgroundColor: "white",
    padding: "0.2rem",

    cursor: "pointer",
    "&:hover": {
      backgroundColor: Palette.col_lightOrange,
      color: "white",
    },
  }),
};

export default Styles;
