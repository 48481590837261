import httpService from "../httpService";

import {UserContext} from "../../contexts";
import {getCacheStatus} from "../../utils/getCacheStatus";
const apiUrl = "collections";

export async function getAllCollections() {
  try {
    const res = await httpService.get(apiUrl + "/all");

    return res.data;
  } catch (error) {
    return null;
  }
}
export async function checkCollectionExistsByName(name) {
  try {
    const res = await httpService.get(apiUrl + "/exists/" + name);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function createCollection(data) {
  try {
    const res = await httpService.post(apiUrl + "/create", data);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function setCollectionOnline(data) {
  try {
    const res = await httpService.post(apiUrl + "/setOnline", data);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function updateCollection(data, id) {
  const datas = new FormData();
  if (typeof data.lookbook !== "string") datas.append("file", data.lookbook);
  if (typeof data.linesheet !== "string") datas.append("file", data.linesheet);
  if (typeof data.image !== "string") datas.append("file", data.image);
  if (typeof data.logo !== "string") datas.append("file", data.logo);
  datas.append("collection", JSON.stringify(data));
  datas.append("collId", id);
  try {
    const res = await httpService.post(apiUrl + "/update", datas);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function saveCollectionImage(data) {
  try {
    const res = await httpService.post(apiUrl + "/saveImage", data);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function saveLookbook(data) {
  try {
    const res = await httpService.post(apiUrl + "/lookbook", data);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function saveLinesheet(data) {
  try {
    const res = await httpService.post(apiUrl + "/linesheet", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function getCollectionById(id, cache = true) {
  try {
    let cacheOption = "default";
    if (cache === false) cacheOption = "no-store";
    const url = process.env.REACT_APP_API_URL + apiUrl + "/" + id;
    let response = await fetch(url, {cache: cacheOption});
    let data = await response.json();
    return data;
  } catch (error) {
    return null;
  }
}
export async function getCollectionImageById(id, cache = true) {
  let cacheOption = "default";
  if (cache === false) cacheOption = "no-store";

  if (id === undefined) return null;
  try {
    const url = process.env.REACT_APP_API_URL + apiUrl + "/image/" + id;
    let response = await fetch(url, {cache: cacheOption});

    let data = await response.blob();
    let metadata = {
      type: "image/" + id.split("-")[0],
    };
    let file = new File([data], id, metadata);

    return file;
  } catch (error) {
    return null;
  }
}

export async function getCacheDate() {
  try {
    const res = await httpService.get(apiUrl + "/getCacheDate");
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function deleteCollection(id) {
  try {
    const res = await httpService.delete(apiUrl + "/" + id);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function saveCollectionProducts(datas) {
  try {
    const res = await httpService.post(apiUrl + "/importvariant", datas);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function updateCollectionDatabase(datas) {
  try {
    const res = await httpService.post(apiUrl + "/updateDatabase", datas);
    return res.data;
  } catch (error) {
    return null;
  }
}

//__________________________HOME
export async function getCollectionsByLine(line, cache = true) {
  try {
    // const res = await httpService.get(apiUrl + "/collectionsByLine/" + line);
    // return res.data;
    let cacheOption = "default";
    if (cache === false) cacheOption = "no-store";
    const url =
      process.env.REACT_APP_API_URL + apiUrl + "/collectionsByLine/" + line;
    let response = await fetch(url, {cache: cacheOption});
    const data = await response.json();
    return data;
  } catch (error) {
    return null;
  }
}
