import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "userTableColumn",
  initialState: [null],
  reducers: {
    setUserTableColumn: (tabCol, action) => {
      tabCol[0] = action.payload;
    },
  },
});

export const {setUserTableColumn} = slice.actions;
export default slice.reducer;

//selector
export const getUserTableColumn = (state) => state.userTableColumn;
