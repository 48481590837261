import React, {useState, useEffect} from "react";
import Styles from "./Styles";
import orderBy from "lodash/orderBy";
import {Icon} from "../../../../components";
import {Palette} from "../../../../constants";

export default function UsersTable(props) {
  const defaultSortOrder = {
    firstName: false,
    lastName: false,
    email: false,
    role: false,
    isConfirmed: false,
    emailConfirmed: false,
    isLocked: false,
    division: false,
  };
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);
  const [users, setUsers] = useState(props.datas);

  useEffect(() => {
    setUsers(props.datas);
    setSortOrder(defaultSortOrder);
  }, [props.datas]);

  function handleHeaderClick(name) {
    const so = sortOrder[name];
    const tempSo = {...defaultSortOrder};
    tempSo[name] = !so;
    setSortOrder(tempSo);
    const tempUsers = orderBy(users, name, so ? "desc" : "asc");
    setUsers(tempUsers);
  }

  function handleSelect(id) {
    props.onModifyUser(id);
  }
  function handleConfirm(id) {
    props.onConfirm(id);
  }

  function getRoleName(name) {
    switch (name) {
      case "admin":
        return "admin";
      case "creat":
        return "brand";
      case "custo":
        return "visitor";

      default:
        break;
    }
  }
  function getDivision(usr) {
    let divs = "";
    divs += usr.divisionmenswear ? "M " : "";
    divs += usr.divisionwomenswear ? "W " : "";
    divs += usr.divisionaccessories ? "A " : "";

    return divs;
  }
  function handleFilter(e) {
    e.stopPropagation();
  }
  return (
    <div className={Styles.frame}>
      <div className={Styles.table}>
        <div className={Styles.headerContainer}>
          {props.columns.firstname && (
            <div
              className={Styles.headerCellLarge}
              onClick={() => handleHeaderClick("firstName")}
            >
              First Name
            </div>
          )}
          {props.columns.lastname && (
            <div
              className={Styles.headerCellLarge}
              onClick={() => handleHeaderClick("lastName")}
            >
              Last Name
            </div>
          )}
          {props.columns.company && (
            <div
              className={Styles.headerCellLarge}
              onClick={() => handleHeaderClick("company")}
            >
              Brand
            </div>
          )}
          {props.columns.country && (
            <div
              className={Styles.headerCellLarge}
              onClick={() => handleHeaderClick("country")}
            >
              country
            </div>
          )}
          {props.columns.address && (
            <div
              className={Styles.headerCellLarge}
              onClick={() => handleHeaderClick("address")}
            >
              address
            </div>
          )}
          {props.columns.zipcode && (
            <div
              className={Styles.headerCellLarge}
              onClick={() => handleHeaderClick("zipcode")}
            >
              zipcode
            </div>
          )}
          {props.columns.phonenumber && (
            <div
              className={Styles.headerCellLarge}
              onClick={() => handleHeaderClick("phonenumber")}
            >
              phone number
            </div>
          )}

          {props.columns.email && (
            <div
              className={Styles.headerCellLarge}
              onClick={() => handleHeaderClick("email")}
            >
              email
            </div>
          )}
          {props.columns.role && (
            <div
              className={Styles.headerCellLarge}
              onClick={() => handleHeaderClick("role")}
            >
              Role
            </div>
          )}
          {props.columns.division && (
            <div
              className={Styles.headerCellLarge}
              onClick={() => handleHeaderClick("division")}
            >
              Division
            </div>
          )}
          {props.columns.emailconfirmed && (
            <div
              className={Styles.headerCellMedium}
              onClick={() => handleHeaderClick("emailConfirmed")}
            >
              email confirmed
            </div>
          )}
          {props.columns.confirmed && (
            <div
              className={Styles.headerCellMedium}
              onClick={() => handleHeaderClick("isConfirmed")}
            >
              Confirmed
            </div>
          )}
          {props.columns.locked && (
            <div
              className={Styles.headerCellMedium}
              onClick={() => handleHeaderClick("isLocked")}
            >
              Locked
            </div>
          )}
          <div className={Styles.headerCellSmall}></div>
        </div>

        {users.map((usr, index) => (
          <div className={Styles.cellContainer} key={index}>
            {props.columns.firstname && (
              <div className={Styles.cellLarge}>{usr.firstName}</div>
            )}
            {props.columns.lastname && (
              <div className={Styles.cellLarge}>{usr.lastName}</div>
            )}
            {props.columns.company && (
              <div className={Styles.cellLarge}>{usr.company}</div>
            )}
            {props.columns.country && (
              <div className={Styles.cellLarge}>{usr.country}</div>
            )}
            {props.columns.address && (
              <div className={Styles.cellLarge}>{usr.address}</div>
            )}
            {props.columns.zipcode && (
              <div className={Styles.cellLarge}>{usr.zipcode}</div>
            )}
            {props.columns.phonenumber && (
              <div className={Styles.cellLarge}>{usr.phonenumber}</div>
            )}
            {props.columns.email && (
              <div className={Styles.cellLarge}>{usr.email}</div>
            )}
            {props.columns.role && (
              <div className={Styles.cellLarge}>{getRoleName(usr.role)}</div>
            )}
            {props.columns.division && (
              <div className={Styles.cellLarge}>{getDivision(usr)}</div>
            )}
            {props.columns.emailconfirmed && (
              <div className={Styles.cellMedium}>
                {usr.emailConfirmed ? "x" : ""}
              </div>
            )}
            {props.columns.confirmed && (
              <div
                className={
                  usr.isConfirmed ? Styles.cellMedium : Styles.cellMediumAlert
                }
              >
                {usr.isConfirmed ? (
                  "x"
                ) : (
                  <div
                    className={Styles.btnPending}
                    onClick={() => handleConfirm(usr._id)}
                  >
                    pending
                  </div>
                )}
              </div>
            )}
            {props.columns.locked && (
              <div className={Styles.cellMedium}>
                {usr.isUnlocked ? "" : "x"}
              </div>
            )}
            <div
              className={Styles.cellSmallIcon}
              onClick={() => handleSelect(usr._id)}
            >
              <Icon
                icon="eye"
                size="1rem"
                color={Palette.col_mediumGrey}
                hcolor={Palette.col_darkGrey}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
