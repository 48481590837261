import {css} from "@emotion/css";
import {Palette} from "../../../../constants";

const btnLine = css({
  fontSize: "0.8rem",
  textAlign: "center",
  width: "4rem",
  height: "1rem",
  lineHeight: "1rem",
  border: "1px solid " + Palette.col_mediumGrey,
  userSelect: "none",
  cursor: "pointer",
});

const tabs = css({
  height: "2rem",
  lineHeight: "2rem",
  textAlign: "center",
  flexGrow: 1,
  flexShrink: 1,
  marginTop: "0.5rem",
  border: "1px solid " + Palette.col_mediumGrey,
  borderBottom: "none",
  borderRadius: "0.5rem 0.5rem 0 0",
  cursor: "pointer",
  userSelect: "none",
});

const headBtn = css({
  width: "8rem",
  height: "1.8rem",
  lineHeight: "1.8rem",
  backgroundColor: Palette.col_white,
  border: "1px solid " + Palette.col_lightMediumGrey,
  color: Palette.col_darkGrey,
  textAlign: "center",
  cursor: "pointer",
  userSelect: "none",
  "&:hover": {
    backgroundColor: Palette.col_black,
    color: Palette.col_white,
  },
});

const Styles = {
  margin: css({
    height: "50px",
  }),
  innerFrame: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  navigation: css({
    display: "flex",
    width: "100vw",
    alignItems: "center",

    margin: "1rem 0 1rem 4rem",
  }),
  navItem: css({
    cursor: "pointer",
    textDecoration: "none",
    color: Palette.col_darkGrey,
    "&:hover": {
      color: Palette.col_orange,
    },
  }),
  bntHeadContainer: css({
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
  }),

  btnVariant: css({
    width: "8rem",
    height: "1.8rem",
    lineHeight: "1.8rem",
    backgroundColor: Palette.col_white,
    border: "1px solid " + Palette.col_lightMediumGrey,
    color: Palette.col_darkGrey,
    textAlign: "center",
    cursor: "pointer",
    userSelect: "none",
    "&:hover": {
      backgroundColor: Palette.col_black,
      color: Palette.col_white,
    },
  }),
  btnView: css({
    marginLeft: "auto",
    paddingRight: "0.5rem",
    display: "flex",
    justifyContent: "flex-end",
    gap: 5,
    //width: "2.5rem",
  }),
  headBtnLeft: css(headBtn, {
    marginLeft: "auto",
  }),
  headBtnDelete: css(headBtn, {
    marginLeft: "auto",

    "&:hover": {
      backgroundColor: "red",
      color: Palette.col_white,
    },
  }),

  barTitle: css({
    backgroundColor: Palette.col_darkGrey,
    color: Palette.col_white,
    display: "flex",
    alignItems: "center",
    height: "2rem",
  }),
  barTitleText: css({
    paddingLeft: "0.5rem",
  }),
  title: css({
    borderBottom: "1px solid " + Palette.col_darkGrey,
    fontSize: "1.5rem",
    padding: "1rem 0",
    marginBottom: "1rem",
    width: "90%",
    textAlign: "center",
  }),
  frame: css({
    marginTop: "2rem",
    width: "90vw",
    //height: "80vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }),
  topSubContainer: css({
    display: "flex",
  }),
  subContainer: css({
    width: "90vw",
    //display: "grid",
    display: "flex",
    border: "1px solid " + Palette.col_mediumGrey,
  }),

  leftSubContainer: css({
    display: "flex",
    width: "50vw",
    flexWrap: "wrap",
    //flexDirection: "column",
    //justifyContent: "center",
  }),
  inputBlock: css({
    //width: "95%",
    minWidth: "10rem",
    flexBasis: "40%",
    flexGrow: 1,
    flexShrink: 1,
    margin: "0 0.5rem",
  }),
  inputBlockText: css({
    //width: "95%",
    height: "7rem",
    minWidth: "10rem",
    flexBasis: "40%",
    flexGrow: 1,
    flexShrink: 1,
    margin: "0 0.5rem",
  }),
  inputTitle: css({
    color: Palette.col_mediumGrey,
    width: "90%",
    paddingBottom: "0.1rem",
    margin: "0.5rem 0 0.2rem 0",
    borderBottom: "1px solid " + Palette.col_xlightGrey,
  }),
  text: css({
    color: Palette.col_darkGrey,
    height: "1rem",
    margin: "0 0 0.3rem 0",
  }),
  input: css({
    color: Palette.col_darkGrey,
    margin: "0 0 0.3rem 0",
  }),
  inputContainer: css({
    display: "flex",
    justifyContent: "space-between",
  }),
  btnLineOff: css(btnLine, {
    color: Palette.col_mediumGrey,
    "&:hover": {
      backgroundColor: Palette.col_darkGrey,
      color: Palette.col_white,
    },
  }),
  btnLineOn: css(btnLine, {
    backgroundColor: Palette.col_darkGrey,
    color: Palette.col_white,
    "&:hover": {
      backgroundColor: Palette.col_mediumGrey,
      color: Palette.col_white,
    },
  }),
  errors: css({
    color: "red",
    fontSize: "0.8rem",
    lineHeight: "0.8rem",
    height: "0.8rem",
  }),
  rightSubContainer: css({
    display: "flex",
    flexDirection: "column",
    width: "50vw",

    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Palette.col_xxlightGrey,
  }),
  mainImage: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
    height: "30vh",
    width: "30vh",
    backgroundColor: Palette.col_mediumGrey,
  }),
  rightInputs: css({
    // width: "20rem",
    width: "100%",
    marginLeft: "2rem",
    display: "flex",
    flexDirection: "column",
  }),
  actionContainer: css({
    width: "50vw",
    display: "flex",
    backgroundColor: "blue",
    height: "2rem",
  }),

  tabsSelected: css(tabs, {
    backgroundColor: Palette.col_mediumGrey,
    color: Palette.col_white,
    "&:hover": {
      backgroundColor: Palette.col_mediumGrey,
      color: Palette.col_white,
    },
  }),
  onlineContainer: css({
    width: "100%",
    height: "2rem",
    display: "flex",
    justifyContent: "center",
  }),
  online: css({
    marginRight: "1rem",
    color: "green",
  }),
  offline: css({
    marginRight: "1rem",

    color: "red",
  }),
  sendBtn: css({
    cursor: "pointer",
    textDecoration: "underline",
  }),
};

export default Styles;
