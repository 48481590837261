import httpService from "../httpService";

const apiUrl = "staticImages";
const b64toBlob = require("b64-to-blob");

export async function saveStaticImages(imagesToSave) {
  const data = new FormData();
  for (let i = 0; i < imagesToSave.length; i++) {
    data.append("file", imagesToSave[i]);
  }
  //data.append("id", id);

  try {
    const res = await httpService.post(apiUrl + "/save", data);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function getStaticImages(id) {
  try {
    const res = await httpService.get(apiUrl + "/list/" + id);
    return res.data;
  } catch (error) {
    return null;
  }
}

export async function getStaticImage(name) {
  if (name === undefined) return null;
  try {
    const url = process.env.REACT_APP_API_URL + apiUrl + "/image/" + name;
    let response = await fetch(url);

    let data = await response.blob();
    let metadata = {
      type: "image/*",
    };
    let file = new File([data], name, metadata);

    return file;
  } catch (error) {
    return null;
  }
}

export async function deleteImage(datas) {
  try {
    const res = await httpService.post(apiUrl + "/delete", datas);
    return res.data;
  } catch (error) {
    return null;
  }
}
