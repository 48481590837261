import React from "react";
import Styles from "./Styles";

export default function MiniCards(props) {
  function handleImageClick() {
    props.onClick(props.index);
  }

  return (
    <div className={Styles.frame}>
      <img
        className={Styles.image}
        src={props.image}
        alt=""
        onClick={handleImageClick}
      />
    </div>
  );
}
