import React, {useContext} from "react";
import {Routes, Route, Navigate, Link} from "react-router-dom";

import {authAdmin, authAdminOrCreator} from "../../../auth";
import {UserContext} from "../../../contexts";
import CollectionsHome from "./CollectionsHome";

export default function Collections() {
  const userCtx = useContext(UserContext);

  return (
    <Routes>
      <Route
        path="/"
        element={
          authAdminOrCreator(userCtx.user) ? (
            <CollectionsHome />
          ) : (
            <Navigate to="/Home" />
          )
        }
      ></Route>
    </Routes>
  );
}
