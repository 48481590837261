import axios from "axios";
import wrapper from "axios-cache-plugin";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    alert("an error has occured");
  }

  return Promise.reject(error);
});

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("token");
axios.defaults.headers = {
  "Cache-Control": "default",
};
// let cache = wrapper(axios, {
//   maxCacheSize: 15, // cached items amounts. if the number of cached items exceeds, the earliest cached item will be deleted. default number is 15.
//   ttl: 60000, // time to live. if you set this option the cached item will be auto deleted after ttl(ms).
//   excludeHeaders: true, // should headers be ignored in cache key, helpful for ignoring tracking headers
// });

// cache._addFilter(/image/);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
