import {css} from "@emotion/css";
import {Palette} from "../../../constants";

const btnGroup = css({
  height: "1.5rem",
  lineHeight: "1.5rem",
  color: Palette.col_white,
  textAlign: "center",
  userSelect: "none",
  cursor: "pointer",
  borderLeft: "1px solid " + Palette.col_xxlightGrey,
  "&:hover": {
    backgroundColor: Palette.col_darkGrey,
  },
});

const Styles = {
  frame: css({
    minHeight: "100vh",
    width: "100vw",
  }),
  margin: css({
    height: "50px",
  }),
  innerFrame: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  title: css({
    borderBottom: "1px solid " + Palette.col_darkGrey,
    fontSize: "1.5rem",
    padding: "1rem 0",
    marginBottom: "1rem",
    width: "90%",
    textAlign: "center",
  }),
  container: css({
    marginTop: "3rem",
    width: "95vw",
    display: "flex",
    justifyContent: "center",
  }),
  btn: css({
    width: "9rem",
    height: "9rem",
    boxSizing: "border-box",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    color: Palette.col_darkGrey,
    fontSize: "1.3rem",
    borderRadius: "20%",
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid " + Palette.col_mediumGrey,
    },
  }),
  navigation: css({
    display: "flex",
    width: "100vw",
    alignItems: "center",

    margin: "1rem 0 1rem 4rem",
  }),
  navItem: css({
    cursor: "pointer",
    textDecoration: "none",
    color: Palette.col_darkGrey,
    "&:hover": {
      color: Palette.col_orange,
    },
  }),

  separator: css({
    color: "blue",
  }),
};

export default Styles;
