import Joi from "joi";

const schemaUser = Joi.object({
  firstName: Joi.string().min(3).max(20).required(),
  lastName: Joi.string().min(3).max(20).allow(""),
  email: Joi.string()
    .min(5)
    .max(255)
    .email({tlds: {allow: false}})
    .required(),
  address: Joi.string().allow(""),
  zipcode: Joi.string().allow(""),
  city: Joi.string().allow(""),
  country: Joi.string().allow(""),
  phonenumber: Joi.string().allow(""),
  mobilenumber: Joi.string().allow(""),
  isConfirmed: Joi.boolean(),
  emailConfirmed: Joi.boolean(),
  company: Joi.string(),

  divisionmenswear: Joi.bool(),
  divisionwomenswear: Joi.bool(),
  divisionaccessories: Joi.bool(),
}).options({abortEarly: false});
export async function readUserFile(file) {
  const users = [];
  file.forEach((user) => {
    const tempUser = {
      firstName: user.FIRSTNAME === undefined ? "" : user.FIRSTNAME,
      lastName: user.LASTNAME === undefined ? "" : user.LASTNAME,
      company: user.COMPANY === undefined ? "" : user.COMPANY,
      email: user.EMAIL === undefined ? "" : user.EMAIL,
      address: user.STREET === undefined ? "" : user.STREET,
      zipcode: user.ZIPCODE === undefined ? "" : user.ZIPCODE,
      city: user.CITY === undefined ? "" : user.CITY,
      country: user.COUNTRY === undefined ? "" : user.COUNTRY,
      divisionmenswear: user.DIVMENSWEAR === undefined ? false : true,
      divisionwomenswear: user.DIVWOMENSWEAR === undefined ? false : true,
      divisionaccessories: user.DIVACCESSORIES === undefined ? false : true,
      phonenumber: user.PHONENUMBER === undefined ? "" : user.PHONENUMBER,
      mobilenumber: user.MOBILENUMBER === undefined ? "" : user.MOBILENUMBER,
    };

    const err = schemaUser.validate(tempUser);
    console.log("err------------", err.error);
    if (!err.error) users.push(tempUser);
  });
  console.log("users", users);
  return users;
  //console.log("users", users);
}
