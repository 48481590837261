import React, {useState, useContext, useEffect} from "react";
import {Link} from "react-router-dom";
import Styles from "./Styles";

//import {Menu} from "./menu";
import {UserContext} from "../../contexts";

import {Icon} from "..";
import {getLogoImage} from "../../services";

export default function Navbar() {
  const [menuDisplay, setSetMenuDisplay] = useState(false);
  const [logo, setLogo] = useState("");

  useEffect(() => {
    init();
  }, []);

  async function init() {
    const res = await getLogoImage();
    const log = URL.createObjectURL(res);
    setLogo(log);
  }
  function handleMenuClick(params) {
    setSetMenuDisplay(!menuDisplay);
  }
  function handleFrameClick(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setSetMenuDisplay(false);
  }
  const userContext = useContext(UserContext);
  function handleBtnLogout() {
    if (localStorage.getItem("token")) localStorage.removeItem("token");
    userContext.updateUser(null);
  }
  return (
    <div className={Styles.frame}>
      <div className={Styles.barFrame}>
        <div className={Styles.menuBtn}>
          <Icon
            icon="hamburger"
            color="white"
            hcolor="grey"
            size="24px"
            onClick={handleMenuClick}
          />
        </div>
        <img className={Styles.logo} src={logo} alt="" />
      </div>
      {menuDisplay && (
        <div className={Styles.menuFrame} onClick={handleFrameClick}>
          <div className={Styles.menu}>
            <Link to="/Home" className={Styles.btn}>
              HOME
            </Link>

            <Link to="/Home/Seasons/menswear" className={Styles.btn}>
              MENS
            </Link>
            <Link to="/Home/Seasons/womenswear" className={Styles.btn}>
              WOMENS
            </Link>
            <Link to="/Home/Seasons/accessories" className={Styles.btn}>
              ACCESSORIES
            </Link>
            <Link to="/Account" className={Styles.btnAcc}>
              MY ACCOUNT
            </Link>
            {userContext.user.role === "creat" && (
              <Link to="/Creator" className={Styles.btn}>
                MY COLLECTIONS
              </Link>
            )}
            {userContext.user.role === "creat" && (
              <Link to="/Images" className={Styles.btn}>
                MY IMAGES
              </Link>
            )}
            {userContext.user.role === "admin" && (
              <Link to="/Admin" className={Styles.btnAlt}>
                ADMINISTRATION
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
