//getFiltersDatas

import findIndex from "lodash/findIndex";
import {concat, filter} from "lodash";
import {
  postAllCollectionsToUsers,
  postCollectionsToUsers,
  postRemoveCollectionsToUsers,
} from "../services/api/user";

const filterCategories = [
  "country",
  "role",
  "firstName",
  "lastName",
  "isUnlocked",
  "isConfirmed",
];
export function filterUsers(users, countries, lock, confirmed, role, division) {
  let tempSelUsers = [];
  //countries
  if (countries.length === 0) tempSelUsers = [...users];
  else {
    for (const opt of countries) {
      const temp = filter(users, {country: opt.value});
      tempSelUsers = concat(tempSelUsers, temp);
    }
  }
  //lock
  if (lock === "locked") {
    tempSelUsers = filter(tempSelUsers, {isUnlocked: false});
  }
  if (lock === "unlocked") {
    tempSelUsers = filter(tempSelUsers, {isUnlocked: true});
  }
  //confirmed
  if (confirmed === "confirmed") {
    tempSelUsers = filter(tempSelUsers, {isConfirmed: true});
  }
  if (confirmed === "notconfirmed") {
    tempSelUsers = filter(tempSelUsers, {isConfirmed: false});
  }
  //role
  if (role === "admin" || role === "creat" || role === "custo") {
    tempSelUsers = filter(tempSelUsers, {role: role});
  }
  //division
  if (division === "menswear") {
    tempSelUsers = filter(tempSelUsers, {divisionmenswear: true});
  }
  if (division === "womenswear") {
    tempSelUsers = filter(tempSelUsers, {divisionwomenswear: true});
  }
  if (division === "accessories") {
    tempSelUsers = filter(tempSelUsers, {divisionaccessories: true});
  }
  //return
  return tempSelUsers;
}
export function getFilterByCountries(users) {
  const filters = [];

  users.forEach((user) => {
    const index = findIndex(filters, {value: user.country});
    if (index === -1) {
      filters.push({value: user.country, label: user.country});
    }
  });
  return filters;
}
export function getFiltersDatas(users) {
  const filters = {};

  users.forEach((user) => {
    for (const cat of filterCategories) {
      if (filters[cat] == null) filters[cat] = [];
      const index = filters[cat].indexOf(user[cat]);
      if (index === -1) filters[cat].push(user[cat]);
    }
  });

  return filters;
}

export function getFiltersCollections(coll) {
  const filter = [];
  for (const el of coll) {
    filter.push({value: el.name, label: el.name, id: el._id, name: el.name});
  }
  return filter;
}
export async function assignCollectionToUsers(collections, users, assign) {
  await postCollectionsToUsers({collections, users, assign});
}
export async function assignAllCollectionToUsers(collections, users, assign) {
  await postAllCollectionsToUsers({collections, users, assign});
}

export async function removeAllCollectionToUsers(collections, users, assign) {
  await postRemoveCollectionsToUsers({collections, users, assign});
}
