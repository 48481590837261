import httpService from "../httpService";

const apiUrl = "static";

export async function getCollectionBckImage() {
  try {
    const url = process.env.REACT_APP_API_URL + apiUrl + "/collection";
    let response = await fetch(url, {cache: "default"});

    let data = await response.blob();
    let metadata = {
      type: "image/*",
    };
    let file = new File([data], "bckCollection", metadata);

    return file;
  } catch (error) {
    return null;
  }
}
export async function setCollectionBckImage(fl) {
  const data = new FormData();

  data.append("file", fl);

  try {
    const res = await httpService.post(apiUrl + "/collection", data);
    return res.data;
  } catch (error) {
    return null;
  }
}
export async function getHomeBckImage() {
  try {
    const url = process.env.REACT_APP_API_URL + apiUrl + "/home";
    let response = await fetch(url, {cache: "default"});

    let data = await response.blob();
    let metadata = {
      type: "image/*",
    };
    let file = new File([data], "home", metadata);

    return file;
  } catch (error) {
    return null;
  }
}
export async function getLogoImage() {
  try {
    const url = process.env.REACT_APP_API_URL + apiUrl + "/logo";
    let response = await fetch(url, {cache: "default"});

    let data = await response.blob();
    let metadata = {
      type: "image/*",
    };
    let file = new File([data], "logo", metadata);

    return file;
  } catch (error) {
    return null;
  }
}
