import XLSX from "xlsx";

export function exportUsersToExcel(users) {
  //set array
  const datas = [];
  //set headers
  datas.push([
    "firstName",
    "lastName",
    "email",
    "address",
    "zipcode",
    "country",
    "phonenumber",
    "role",
  ]);
  //set lines
  users.forEach((user) => {
    const line = [];
    for (const el of datas[0]) {
      line.push(user[el]);
    }
    datas.push(line);
  });
  //to excel
  var workbook = XLSX.utils.book_new();
  var worksheet = XLSX.utils.aoa_to_sheet(datas);
  XLSX.utils.book_append_sheet(workbook, worksheet, "test");
  XLSX.writeFile(workbook, "noseason-users.xlsx");
}
